<!-- 
    З планів:
        2. Планую зробити блочки, як готові патерни блоків різних (не просто окремо додавати h2, умовний, та за ним список, а одразу готову комбінацію)
        3. Зробити зміст з якорями
        4. Зробити другий режим (Rows), котрий буде всі елементи давати список, а не графічно
        5. Копіювати елемент
        6. Змінення елементів місцями
        7. Видалення елементів
        8. Дока в пдф
-->

<template>
    <div class="container_custom">
        <div class="blocksMenu">
            <div class="blocksMenu__chooseMode">
                <span style="font-size: 24px;">Mode</span>
                <div 
                    class="blocksMenu__chooseMode__switcher"
                    style="margin-top: 10px;"
                >
                    <span>Rows</span>
                    <div 
                        class="form-check form-switch"
                        style="padding-left: 50px"
                    >
                        <input 
                            disabled
                            v-model="this.mode"
                            class="form-check-input" 
                            type="checkbox" 
                            id="flexSwitchCheckDefault"
                        >
                        <!-- <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> -->
                    </div>
                    <span>Blocks</span>
                </div>  
            </div>
            <hr />
            <div class="blocksMenu__blockMenu">
                <ul v-if="this.mode === false">
                    <li
                        v-for="item in sourceItems"
                        :key="item.id"
                        class="item"
                        draggable="true"
                        @dragstart="onDragStart(item)"
                        @dragend="onDragEnd"
                    >
                        {{ item.name }}
                    </li>
                </ul>
                <ul class="list_blockMode" v-else>
                    <li
                        v-for="item in sourceItems"
                        :key="item.id"
                        class="item blockMode"
                        draggable="true"
                        @dragstart="onDragStart(item)"
                        @dragend="onDragEnd"
                    >
                        <!-- <template v-for="items in item"> -->
                            <div class="item-block">
                                <div class="item-block__icon">
                                    <i :class="item.icon"></i>
                                </div>
                            </div>
                        <!-- </template> -->
                    </li>
                </ul>
            </div>
        </div>
        <div class="workspace">
            <div class="workspace__editor">
                <h1 
                    class="doc_title"
                    style="word-break: break-all;" 
                    contenteditable="true"
                    @input="this.saveInBuffer('title', $event)"
                    @keydown.enter.prevent="this.saveTitle($event)"
                >
                    {{ this.form.title }}
                </h1>
                
                <hr />

                <div 
                    class="workspace__editor__dropArea"
                >
                    <ul style="padding: 0;">
                        <template v-for="(item, index) in withDropZones" :key="item.id || `dropzone-${index}`">
                            <li
                                v-if="!item.isDropZone"
                                class="drop-item"
                            >
                                <!-- Якщо у нас h -->
                                <template 
                                    v-if="!item.isDropZone && (
                                            (
                                                (item.tag.split('')[0] === 'h' && item.tag != 'hr') || item.tag === 'p'
                                            )
                                        )
                                    "
                                >
                                    <span 
                                        contenteditable="true" 
                                        :style="(this.searchStyle(item))"
                                        @input="this.saveInBuffer(item, $event)"
                                        @blur="this.saveChanges(item, index)"
                                    >
                                        {{ item.text }}
                                    </span>
                                </template>

                                <!-- Якщо у нас hr -->
                                <template 
                                    v-if="!item.isDropZone && (
                                            item.tag === 'hr'
                                        )
                                    "
                                >   
                                    <hr />
                                </template>

                                <!-- Якщо у нас ul or ol -->
                                <template 
                                    v-if="!item.isDropZone && (
                                            item.tag === 'ul' || item.tag === 'ol'
                                        )
                                    "
                                >
                                    <template v-if="item.tag === 'ul'">
                                        <ul :style="this.searchStyle(item)">
                                            <li 
                                                v-for="text, idx in item.text"
                                                :key="idx"
                                                class="li_focusVisisble"
                                                @input="this.saveInBuffer(item, $event)"
                                                @keyup.enter="addPoint($event, item, index)"
                                                @blur="this.saveChanges(item, index, idx)"
                                                contenteditable="true"
                                                :style="this.content.length === 0 && item.text.length === 0 ? 'min-width: 100%; height: 100%; display: block; background-color: #efefef' : this.searchStyle(item)"
                                            >
                                                {{ text }}
                                            </li>
                                        </ul>
                                    </template>
                                    <template v-if="item.tag === 'ol'">
                                        <ol :style="this.searchStyle(item)">
                                            <li 
                                                v-for="text, idx in item.text"
                                                :key="idx"
                                                class="li_focusVisisble"
                                                @input="this.saveInBuffer(item, $event)"
                                                @keyup.enter="addPoint($event, item, index)"
                                                @blur="this.saveChanges(item, index, idx)"
                                                contenteditable="true"
                                                :style="this.content.length === 0 && item.text.length === 0 ? 'min-width: 100%; height: 100%; display: block; background-color: #efefef' : this.searchStyle(item)"
                                            >
                                                {{ text }}
                                            </li>
                                        </ol>
                                    </template>
                                </template>

                                <template
                                    v-if="!item.isDropZone && (
                                            item.tag === 'code' &&
                                            item.name === 'code'
                                        )
                                    "
                                >
                                    <code 
                                        :style="this.searchStyle(item)"
                                        contenteditable="true"
                                        @focus="this.content = item.text"
                                        @input="this.saveInBuffer(item, $event)"
                                        @blur="this.saveChanges(item, index)"
                                        @keydown="this.handleInput($event, 'code')"
                                        v-html="item.text"
                                    >
                                    </code>
                                </template>

                                <template
                                    v-if="!item.isDropZone && (
                                            item.tag === 'code' &&
                                            item.name === 'terminal'
                                        )
                                    "
                                >
                                    <code 
                                        :style="this.searchStyle(item)"
                                        contenteditable="true"
                                        @focus="this.content = item.text"
                                        @input="this.saveInBuffer(item, $event)"
                                        @blur="this.saveChanges(item, index)"
                                        @keydown="this.handleInput($event, 'terminal')"
                                        v-html="item.text"
                                    >
                                    </code>
                                </template>

                                <template
                                    v-if="!item.isDropZone && (
                                            item.tag === 'article'
                                        )
                                    "
                                >
                                    <div 
                                        :style="this.searchStyle(item)"
                                        class="note"
                                    >
                                        <p class="note__title">
                                            <i class="ri-sticky-note-2-line"></i>
                                            Нотатка
                                        </p>
                                        <p
                                            class="note__text"
                                            contenteditable="true"
                                            @input="this.saveInBuffer(item, $event)"
                                            @blur="this.saveChanges(item, index)"
                                        >

                                        </p>
                                    </div>
                                </template>

                                <template 
                                    v-if="!item.isDropZone && (
                                        item.tag === 'a'
                                    )"
                                >
                                    <a
                                        style="display: block;"
                                        :style="this.searchStyle(item)"
                                        contenteditable="true"
                                        @click="this.showLinkMenu(item, $event)"
                                        @input="this.saveInBuffer(item, $event)"
                                        @blur="this.saveChanges(item, index)"
                                    >
                                        {{ item.text.text }}
                                    </a>
                                </template>

                                <template 
                                    v-if="!item.isDropZone && (
                                        item.tag === 'table'
                                    )"
                                >

                                    <div class="table_block" style="position: relative;">
                                        <table
                                            :style="this.searchStyle(item)"
                                            style="margin-bottom: 50px; table-layout: fixed;"
                                        >
                                            <thead>
                                                <tr>
                                                    <th 
                                                        v-for="(header, idx) in item.text.headers" 
                                                        :key="idx" 
                                                        style="border: 1px solid black; word-break: break-all;"
                                                        contenteditable="true"
                                                        @input="this.saveInBuffer(item, $event)"
                                                        @blur="this.saveChangesForTable(item, 'header', idx)"
                                                    >
                                                        {{ header }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr 
                                                    v-for="(row, idx) in item.text.rows" 
                                                    :key="idx"
                                                >
                                                    <td 
                                                        v-for="(cell, k) in row" 
                                                        :key="k" 
                                                        style="border: 1px solid black; word-break: break-all;"
                                                        contenteditable="true"
                                                        @input="this.saveInBuffer(item, $event)"
                                                        @blur="this.saveChangesForTable(item, idx, k)"
                                                    >
                                                        {{ cell }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button 
                                            class="tableButton column"
                                            @click="addColumn(item)"
                                        >
                                            +
                                        </button>
                                        <button 
                                            class="tableButton row"
                                            @click="addRow(item)"
                                        >
                                            +
                                        </button>
                                    </div>
                                </template>
                            </li>

                            <li 
                                v-if="item.isDropZone && isDragging"
                                @dragover.prevent="onDragOver(index)"
                                @drop="onDrop(index)"
                                :class="{ 'drop-zone': item.isDropZone, hidden: item.isDropZone && !isDragging }"
                            >
                            </li>
                        </template>
                    </ul>
                </div>  
            </div>
        </div>
        <!-- <div class="content">
            On this page
        </div> -->
    </div>


    <div 
        v-show="this.linkMenu === true" 
        :style="{left: this.contextMenu.x + 'px', top: this.contextMenu.y + 'px'}" 
        class="link_menu"
    >
        <div class="link_menu__header">
            <button 
                class="btn-close"
                @click="this.linkMenus[index] === false"
            >

            </button>
        </div>
        <div class="link_menu__body">
            <div class="input-group mb-3">
                <input  
                    type="text" 
                    class="form-control" 
                    placeholder="Link" 
                    aria-label="Link" 
                    aria-describedby="basic-addon2"
                    style="height: 38px;"
                    v-model="this.linkBuffer"
                >
                <div class="input-group-append" style="height: 38px;">
                    <span 
                        class="input-group-text" 
                        id="basic-addon2"
                        style="height: 38px; cursor: pointer"
                        @click="this.saveUrl(index)"
                    >
                        <i class='bx bx-subdirectory-left'></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            form: {
                title: 'Title',
                documentation: [],
                projectId: []
            },
            sourceItems: [
                { 
                    name: "h1", 
                    tag: "h1",
                    text: "",
                    icon: "ri-h-1"
                },
                { 
                    name: "h2", 
                    tag: "h2",
                    text: "",
                    icon: "ri-h-2"
                },
                { 
                    name: "h3", 
                    tag: "h3",
                    text: "",
                    icon: "ri-h-3"
                },
                { 
                    name: "h4", 
                    tag: "h4",
                    text: "",
                    icon: "ri-h-4"
                },
                { 
                    name: "h5", 
                    tag: "h5",
                    text: "",
                    icon: "ri-h-5"
                },
                { 
                    name: "h6", 
                    tag: "h6",
                    text: "",
                    icon: "ri-h-6"
                },
                { 
                    name: "p", 
                    tag: "p",
                    text: "",
                    icon: "ri-paragraph"
                },  
                { 
                    name: "hr", 
                    tag: "hr",
                    icon: "ri-git-commit-fill"
                },  
                {
                    name: "list-unordered", 
                    tag: "ul",
                    icon: "ri-list-unordered",
                    text: [''],
                },  
                {
                    name: "list-ordered", 
                    tag: "ol",
                    icon: "ri-list-ordered",
                    text: [''],
                },  
                {
                    name: "code", 
                    tag: "code",
                    icon: "ri-code-line",
                    text: '',
                },  
                {
                    name: "note", 
                    tag: "article",
                    icon: "ri-sticky-note-2-line",
                    text: '',
                },  
                {
                    name: "terminal", 
                    tag: "code",
                    icon: "ri-terminal-box-line",
                    text: '$\u00A0',
                },  
                {
                    name: "link", 
                    tag: "a",
                    icon: "ri-link",
                    text: {
                        text: '',
                        url: '',
                    },
                },  
                {
                    name: "table", 
                    tag: "table",
                    icon: "ri-table-line",
                    text: {
                        headers: ['head1', 'head2'],
                        rows: [
                            ['1', '2'],
                            ['1', '2']
                        ]
                    },
                }
            ],
            targetItems: [], // Елементи в зоні цілі
            draggedItem: null, // Перетягуваний елемент
            isDragging: false,
            content: '',
            mode: true,
            lastItemIdx: null,
            linkMenu: false,
            contextMenu: {
                x: 0,
                y: 0
            },
            linkBuffer: ''
        }
    },
    created() {

    },
    mounted() {  
        // Створюємо MutationObserver
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
            if (mutation.type === "childList") {
                // Виявлено додавання нового елемента
                if (mutation.addedNodes.length > 0) {
                mutation.addedNodes.forEach((node) => {
                    if (node.tagName === "LI") {
                        // console.log("Додано новий елемент:", node);
                        node.focus()
                    }
                });
                }
            }
            }
        });

        const workSpace = document.querySelector('.workspace__editor__dropArea')

        // Налаштовуємо спостереження
        observer.observe(workSpace, {
            childList: true, // Спостерігаємо за додаванням або видаленням дочірніх елементів
            subtree: true,  // Не спостерігаємо за піддеревами
        });
    },
    computed: {
        withDropZones() {
            // Додаємо зони скидання між елементами
            const zones = [];
            this.linkMenus = []
            this.targetItems.forEach((item, index) => {
                // додаємо "прапорці" для менюшок лінків
                if(item.tag === 'a') {
                    this.linkMenus.push(false)
                }
                zones.push({ isDropZone: true, id: `dropzone-${this.generateId()}` });
                item["id"] = this.generateId()
                zones.push(item);
            });
            zones.push({ isDropZone: true, id: `dropzone-end` });
            return zones;
        },
    },
    methods: {
        saveTitle(event) {
            event.target.blur()
            this.form.title = this.content
        },
        addColumn(item) {
            const idx = this.targetItems.findIndex(el => el.id === item.id)
            this.targetItems[idx].text['headers'].push(('head' + (this.targetItems[idx].text['headers'].length + 1)))
            this.targetItems[idx].text['rows'].forEach(el => {
                el.push(this.targetItems[idx].text['rows'].length)
            })
        },
        addRow(item) {
            const idx = this.targetItems.findIndex(el => el.id === item.id)
            let newRow = []
            this.targetItems[idx].text['rows'][0].forEach((el, index) => {
                newRow.push(index + 1)
            })

            this.targetItems[idx].text['rows'].push(newRow)  
        },
        saveUrl(event) {
            console.log('SAVEURL', this.targetItems, this.lastItemIdx, event)
            this.targetItems[this.lastItemIdx].text.url = this.linkBuffer
            this.linkBuffer = ''
            this.linkMenu = false
        },
        showLinkMenu(item, event) {
            this.lastItemIdx = this.targetItems.findIndex(el => el === item)
            console.log(this.targetItems.findIndex(el => el === item), this.targetItems)
            this.contextMenu.x = event.x
            this.contextMenu.y = event.y
            this.linkMenu = true
            console.log('showLinkMenu', ...arguments)
            // console.log(this.linkMenus)
        },
        insertBr(element, type) {
            const selection = window.getSelection(); // Отримуємо виділення
            if (!selection.rangeCount) return; // Якщо немає виділення, виходимо

            const range = selection.getRangeAt(0); // Поточний діапазон виділення
            if (!element.contains(range.commonAncestorContainer)) return; // Перевіряємо, чи курсор у вказаному елементі

            // Створюємо новий HTML-елемент
            const newElement = document.createElement('br');
            let textNode;
            if(type === 'code') {
                textNode = document.createTextNode('\u200B')
            } else if(type === 'terminal') {
                textNode = document.createTextNode('$\u00A0')
            }

            // Вставляємо елемент у місце курсору
            range.insertNode(textNode)
            range.insertNode(newElement);

            // Переміщуємо курсор після вставленого елемента
            range.setStartAfter(textNode);
            range.setEndAfter(textNode);
            selection.removeAllRanges();
            selection.addRange(range);
        },
        // insertTagAtCursor(element, tagName, attributes = {}) {
        //     const selection = window.getSelection(); // Отримуємо виділення
        //     if (!selection.rangeCount) return; // Якщо немає виділення, виходимо

        //     const range = selection.getRangeAt(0); // Поточний діапазон виділення
        //     if (!element.contains(range.commonAncestorContainer)) return; // Перевіряємо, чи курсор у вказаному елементі

        //     // Створюємо новий HTML-елемент
        //     const newElement = document.createElement(tagName);

        //     // Додаємо атрибути до нового елемента
        //     for (const [key, value] of Object.entries(attributes)) {
        //         newElement.setAttribute(key, value);
        //     }

        //     // Додаємо вміст до нового елемента (може бути пустий)
        //     newElement.innerHTML = attributes.content || "";

        //     // Вставляємо елемент у місце курсору
        //     range.insertNode(textNode)
        //     range.insertNode(newElement);

        //     // Переміщуємо курсор після вставленого елемента
        //     range.setStartAfter(textNode);
        //     range.setEndAfter(textNode);
        //     selection.removeAllRanges();
        //     selection.addRange(range);
        // }, 
        insertSpacesAtCursor(element, spaceCount = 4) {
            const selection = window.getSelection(); // Отримуємо виділення
            if (!selection.rangeCount) return; // Якщо немає виділення, виходимо

            const range = selection.getRangeAt(0); // Поточний діапазон виділення
            if (!element.contains(range.commonAncestorContainer)) return; // Перевіряємо, чи курсор у потрібному елементі

            // Генеруємо пробіли
            const spaces = "\u00A0".repeat(spaceCount); // Використовуємо `&nbsp;` (незламний пробіл)
            const spaceNode = document.createTextNode(spaces);

            // Вставляємо пробіли у поточну позицію курсору
            range.insertNode(spaceNode);

            // Переміщуємо курсор за пробіли
            range.setStartAfter(spaceNode);
            range.setEndAfter(spaceNode);
            selection.removeAllRanges();
            selection.addRange(range);
            },
        handleInput(event, type) {
            // console.log(event)
            
            if(event.key === "Tab" || event.key === "Enter") {
                event.preventDefault()

                switch (event.key) {
                    case "Tab":
                        this.insertSpacesAtCursor(
                            event.srcElement
                        )
                    break;
                    case "Enter":
                        this.insertBr(
                            event.srcElement, 
                            type
                        )
                    break;
                }
            }
        },
        addPoint(event, item, index) {
            event.preventDefault()
            this.targetItems.find(el => el.id === item.id).text[index] = this.content
            this.targetItems.find(el => el.id === item.id).text.push('')
            this.content = ''
        },
        saveChanges(item, index, listidx) {
            console.log('saveChanges', item, index)
            if(this.content != '') {
                if(item.tag === 'ol' || item.tag === 'ul') {
                    if(index) {
                        this.targetItems.find(el => el.id === item.id).text[listidx] = this.content
                    }
                } else if (item.tag === 'a') {
                    this.targetItems.find(el => el.id === item.id).text.text = this.content
                } else {
                    this.targetItems.find(el => el.id === item.id).text = this.content
                }
                this.content = ''
            }
        },
        saveChangesForTable(item, sth, idx) {
            console.log(...arguments)
            // sth може бути строкою або індексом
            // Строка - значить "голова" таблиці
            // Індекс - рядок
                console.log(this.targetItems.find(el => el.id === item.id))
            if(sth === 'header') {
                this.targetItems.find(el => el.id === item.id).text["headers"][idx] = this.content
            } else {
                this.targetItems.find(el => el.id === item.id).text['rows'][sth][idx] = this.content
            }
        },
        saveInBuffer(item, event) {
            console.log(item, event)
            if(item === 'title') {
                this.content = event.target.textContent
                return
            }
            // Перший випадок каже, що це ul або ol
            if(item.tag === 'ul' || item.tag === 'ol') {
                this.content = event.target.textContent
            } else if(item.tag === 'code') {
                this.content = event.target.innerHTML
            } else {
                this.content = event.target.textContent ?
                event.target.textContent : event
            }
        },
        searchStyle(item) {
            console.log(item)
            if(
                (item.text === '' || item.text.text === '') && 
                this.content === '' &&
                (item.tag !== 'code') &&
                (item.tag !== 'article')
            ) {
                return 'min-width: 100%; height: 100%; display: block; background-color: #efefef'
            }

            switch (item.tag) {
                case "h1":
                    return 'font-size: 32px; font-weight: bold; margin-bottom: 10px'
                case "h2":
                    return 'font-size: 28px; font-weight: bold; margin-bottom: 10px'
                case "h3":
                    return 'font-size: 24px; font-weight: bold; margin-bottom: 10px'
                case "h4":
                    return 'font-size: 18px; font-weight: bold; margin-bottom: 10px'
                case "h5":
                    return 'font-size: 16px; font-weight: bold; margin-bottom: 10px'
                case "h6":
                    return 'font-size: 14px; font-weight: bold; margin-bottom: 10px'
                case "p":
                    return 'font-size: 14px; margin-bottom: 10px'
                case 'ol':
                    return 'list-style-type: decimal; margin-left: 20px; margin-bottom: 10px'
                case 'ul':
                    return 'list-style-type: disc; margin-left: 20px; margin-bottom: 10px'
                case 'code':
                    return 'font-family: monospace; font-size: 14px; background-color: #292d3f; padding: 10px 20px; width: 100%; display: block; margin-bottom: 10px'
                case 'table':
                    return 'border-collapse: collapse; width: 100%; margin-bottom: 10px'
            }
        },
        generateId() {
            return uuidv4()
        },
        onDragStart(item) {
            this.draggedItem = { ...item };
            this.isDragging = true; // Активуємо стан перетягування
            // console.log("Перетягування почалося:", item);
        },
        onDragEnd() {
            this.isDragging = false; // Деактивуємо стан перетягування
            // this.draggedItem = null;
            // console.log("Перетягування завершено");
        },
        onDrop(index) {
            // console.log(index)
            if (this.withDropZones[index].isDropZone) {
                this.targetItems.splice(index / 2, 0, { ...this.draggedItem });
                console.log(this.targetItems)
                this.isDragging = false; // Скидання завершує перетягування
            }
        },
        onDragOver(index) {
            if (this.withDropZones[index].isDropZone) {
                // console.log("Наведення на зону скидання:", index);
            }
        }
    }
}
</script>

<style>
.row {
    top: 100%;
    left: calc(50% - 50px);
    width: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.column {
    top: 37%;
    left: 100%;
    height: 50%;
    max-height: 100px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.tableButton {   
    position: absolute;
    border: none;
    color: rgb(95, 95, 95);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: visibility .3s ease;
}
.link_menu {
    position: absolute;
    z-index: 2;
    border: 1px solid gray;
    background-color: whitesmoke;
    border-radius: 10px;
}

.link_menu__header  {
    padding: 10px 20px;
    display: flex;
    align-items: end;
    justify-content: end;
    border-bottom: #000;
}

.link_menu__body{
    padding: 10px 20px;
}

.dropzoneCustom.dragover {
  border-color: #000;
  background-color: #eef;
}

.dropzoneCustom {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: 2px dashed #e9ebec;
    color: #7e7e7e
}

.upMenu{
    margin-bottom: 20px;
}

.container_custom {
    min-height: 80vh;
    width: 100% !important;
    height: 100%;
    background: white;
    display: grid;
    grid-template-columns: 3fr 9fr;
    grid-template-rows: 1fr;
}

.doc_title:focus-visible, 
.drop-item > *:focus-visible, 
.li_focusVisisble,
.note__text:focus-visible {
    outline: none;
}

:is(.blocksMenu, .workspace) {
    padding: 32px;
}

.blocksMenu {
    overflow-y: scroll;
}

.blocksMenu::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.blocksMenu::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.blocksMenu::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}

.blocksMenu__chooseMode {
    position: sticky;
}

.blocksMenu__chooseMode__switcher {
    display: flex;
    gap: 5px;
    font-size: 16px;
    text-align: center;
}

.content {
    position: sticky;
    padding: 32px 12px 32px 24px;
}

.drag-drop-container {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 20px;
}
  
.zones {
    display: flex;
    gap: 20px;
    justify-content: center;
}
  
.source-zone,
.dropzone {
    width: 200px;
    min-height: 200px;
    border: 2px dashed #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
}
  
.source-zone h3,
.dropzone h3 {
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
}
  
/* .drop-item {
    margin: 5px 0;
    background-color: #ddd;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    user-select: none;
} */
  
.drop-zone {
    background-color: #eef;
    border: 1px dashed #00f;
    min-height: 20px;
    margin: 5px 0;
    transition: opacity 0.3s ease;
}
  
.hidden {
    opacity: 0; /* Зони приховані, якщо перетягування не активне */
    pointer-events: none;
}

ul {
    list-style-type: none;
}

.list_blockMode {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    gap: 10px
}

.item.blockMode {
    width: 50px;
    height: 50px;
    text-align: center;
}

.item-block{
    display: flex;
    flex-direction: column;
    width: 50px;
    height: 50px;
    border: 2px dashed #cbcbcb;
    border-radius: 10px;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
}

.item-block__icon {
    font-size: 24px;
    color: #cbcbcb
}
.note {
    margin-bottom: 10px;
    font-size: 16px;
}

.note__title {
    color: white;
    margin: 0;
    background-color: rgb(236 205 55);
    padding: 0 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.note__text {
    color: rgb(30, 30, 30);
    margin: 0;
    background-color: rgb(236 205 55 / 38%);
    font-size: 16px;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
</style>