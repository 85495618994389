<template>
    <!-- 
        Ліва частина тіла модального вікна
    -->

    <b-card>
        <b-row>
            <b-col>
                <!-- Секція зображення, імені та посади працівника -->
                <div class="card-body p-4 text-center border-bottom pb-3 mb-2">
                    <div class="mx-auto mb-3">
                        <div class="avatar-xl" style="margin: 0 auto; position: relative;">
                            <!-- Input для виклику контекстного меню завантаження файлу -->
                            <input 
                                type="file"
                                accept=".jpeg, .png, .jpg"
                                @change="processingAvatar($event)" 
                                id="fileInput" 
                                style="display: none;"
                            >
                            <div 
                                v-if="this.form.avatar === ''"
                                class="avatar-title rounded bg-soft-danger text-danger" 
                                @mouseover="this.showUploadButton = true" 
                                @mouseout="this.showUploadButton = false" 
                            >
                                {{getInitials(form.workerName)}}
                                <!-- Кнопка завантаження аватару -->
                                <button 
                                    v-show="this.showUploadButton === true" 
                                    style="color: white; position: absolute; right: 5px; bottom: 5px; cursor: pointer" 
                                    class="btn rounded bg-soft-success upload-avatar"
                                    @click="uploadAvatar"
                                >
                                    <i style="font-size: 12px;" class='bx bx-upload'></i>
                                </button>
                            </div>
                            <div 
                                v-else
                                class="avatar-title rounded text-danger" 
                                @mouseover="this.showUploadButton = true" 
                                @mouseout="this.showUploadButton = false" 
                                style="margin-bottom: 10px; background-color: rgba(0, 0, 0, 0);"
                            >
                                <img style="border: 1px solid black; border-radius: 20px; height: 120px; max-width: 120px;" alt="worker_avatar" :src="form.avatar">
                                
                                <!-- Кнопка заміни аватару -->
                                <button 
                                    v-show="this.showUploadButton === true" 
                                    style="color: white; position: absolute; right: 5px; bottom: 5px; cursor: pointer" 
                                    class="btn rounded btn-warning upload-avatar"
                                    @click="uploadAvatar"
                                >
                                    <i style="font-size: 12px;" class='bx bx-refresh'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <h5 class="card-title mb-1 fw-semibold">{{form.workerName}}</h5> 
                    <p class="text-muted mb-0">{{this.mutateWorkerJob(form.workerJob)}}</p>
                </div>

                <!-- Секція інформації про працівника -->
                <div class="table-responsive">
                    <table class="table table-borderless align-middle mb-0">
                        <tbody>
                            <!-- Рядок з іменем працівника -->
                            <tr>
                                <td class="fw-semibold">{{$t('name')}}</td>
                                <td v-if="statusEdit == false">
                                    {{form.workerName}}
                                </td>
                                <td v-else>
                                    <input type="text" class="form-control" v-model="form.workerName" :placeholder="$t('name')" />
                                </td>
                            </tr>
                             <!-- Рядок з номером телефону працівника -->
                            <tr>
                                <td class="fw-semibold">{{ $t('phone_number') }}</td>
                                <td v-if="statusEdit == false">{{form.workerPhone}}</td>
                                <td v-else><input type="text" class="form-control" v-model="form.workerPhone" :placeholder="$t('name')"></td>
                            </tr>
                            <!-- Рядок з поштою працівника -->
                            <tr>
                                <td class="fw-semibold">{{$t('E-mail')}}</td>
                                <td v-if="statusEdit == false">{{form.workerEmail ? form.workerEmail : '-'}}</td>
                                <td v-else><input type="text" class="form-control" v-model="form.workerEmail" :placeholder="$t('name')"></td>
                            </tr>
                            <!-- Рядок з днем народження -->
                            <tr class="bdayDiv">
                                <td class="fw-semibold">{{$t('bDay')}}</td>
                                <td v-if="statusEdit == false">
                                    {{ form.dateOfBirth ? formatDateTime(form.dateOfBirth) : '-' }}
                                </td>
                                <td v-else>
                                    <flatpickr 
                                        v-model="form.dateOfBirth" 
                                        :config="config" 
                                        class="form-control border-1 dash-filter-picker shadow"
                                        :placeholder="$t('bDay')"
                                    ></flatpickr>
                                </td>
                            </tr>
                            <!-- Рядок з телеграмом працівника -->
                            <tr>
                                <td class="fw-semibold">{{$t('Telegram')}}</td>
                                <td v-if="statusEdit == false">
                                    <a v-if="form.telegramUsername != ''" class="copy link-secondary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover" :href="`https://t.me/${form.telegramUsername}`" target="_blank">
                                        {{ $t('Open') }}
                                    </a>
                                    <span v-else>-</span>
                                </td>
                                <td v-else><input type="text" class="form-control" v-model="form.telegramUsername"></td>
                            </tr>
                            <!-- Рядок з посадою працівника -->
                            <tr>
                                <td class="fw-semibold">{{ $t('Position') }}</td>
                                <td v-if="statusEdit == false">{{this.mutateWorkerJob(form.workerJob)}}</td>
                                <td v-else>
                                    <select v-model="form.workerJob" class="form-control">
                                        <option :value="form.workerJob" selected> {{this.mutateWorkerJob(form.workerJob)}} </option>
                                        <option v-if="form.workerJob !== 'PM'" value="PM"> {{this.mutateWorkerJob('PM')}} </option>
                                        <option v-if="form.workerJob !== 'developer'" value="developer"> {{this.mutateWorkerJob('developer')}} </option>
                                        <option v-if="form.workerJob !== 'managercallcenter'" value="managercallcenter"> {{this.mutateWorkerJob('managercallcenter')}} </option>
                                        <option v-if="form.workerJob !== 'salesmanager'" value="salesmanager"> {{this.mutateWorkerJob('salesmanager')}} </option>
                                        <option v-if="form.workerJob !== 'ceo'" value="ceo"> {{this.mutateWorkerJob('ceo')}} </option>
                                        <option v-if="form.workerJob !== 'tester'" value="tester"> {{this.mutateWorkerJob('tester')}} </option>
                                        <option v-if="form.workerJob !== 'supportmanager'" value="supportmanager"> {{this.mutateWorkerJob('supportmanager')}} </option>
                                        <option v-if="form.workerJob !== 'smm'" value="smm"> {{this.mutateWorkerJob('smm')}} </option>
                                        <option v-if="form.workerJob !== 'marketer'" value="marketer"> {{this.mutateWorkerJob('marketer')}} </option>
                                    </select>
                                </td>
                            </tr>
                            <!-- Рядок зі статусом працівника -->
                            <tr>
                                <td class="fw-semibold">{{ $t('Status') }}</td>
                                <td :class="`badge `+this.mutateWorkerStatus(form.workerStatus).bgcolor+``">{{this.mutateWorkerStatus(form.workerStatus).name}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--
                    Кнопка редагування картки
                    - йде перевірка на права доступу під номером 703 (редагування співробітника) та 2000 (режим бога)
                    - @click - обробник події кліку, встановлює статус редагування
                -->
                <b-button 
                    v-if="!statusEdit && (perms[703] || perms[2000])"
                    @click="statusEdit = true" 
                    variant="info" 
                    class="waves-effect waves-light mt-2 mb-2"
                >
                    <i class="ri-edit-bin-line align-bottom me-1"></i>
                    {{ $t('toEditAp') }}
                </b-button>

                <!-- Кнопка для збереження змін у випадку редагування -->
                <b-button v-if="statusEdit == true" @click="statusEdit = false;this.$emit('editUser', this.form);" variant="success" class="waves-effect waves-light mt-2 mb-2"><i class="ri-edit-bin-line align-bottom me-1"></i>{{ $t('save') }}</b-button>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { mutateWorkersAccount, mutateWorkerJob } from '@/usabilityScripts/globalMutate.js'
import { copy } from '@/usabilityScripts/copy.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { Users } from "@/API"
import { storeS } from '@/store'


import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";

let apiServe = new Users()

export default ({
    props: ['obj', 'statusEdit'],
    components: {
        flatpickr: Vueflatpickr
    },
    data(){
        return {
            cropImage: {
                src: '',
                width: 0,
                height: 0,
            },
            form: {},
            showUploadButton: false,
            actualFile: "",
            config: { // параметри налаштування Flatpickr
                mode: "single", // параметр, який дозволяє вибрати період
                // minDate: "today", // параметр, від якої дати можна обрати період
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false, // вимкнення вказування часу
                dateFormat: "Y-m-d", // формат дати
                locale: "" // мова 
            },
        }
    },
    created() {
        this.form = this.obj

        // Налаштування мови Flatpickr в залежності від мови користувача
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
    },
    methods: {
        // Ініціюємо дію, щоб відкрити вибір файлу
        uploadAvatar() {
            document.getElementById('fileInput').click();
        },
        // Конвертуємо в base64
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            })
        },
        // Перевірки та завантаження файлу
        processingAvatar(event) {
            console.log(event.target.files[0])

            if(
                event.target.files[0].size <= 2000000 &&
                (event.target.files[0].type === "image/jpeg" ||
                event.target.files[0].type === "image/png")
            ) {

                let img = new Image()
                img.src = URL.createObjectURL(event.target.files[0]) 
                
                img.onload = async () => {   
                    this.cropImage.width = img.width
                    this.cropImage.height = img.height
                }   
                
                this.convertToBase64(event.target.files[0]).then(base64 => {
                    this.cropImage.src = base64

                    // this.form.avatar = this.cropImage.src
                    this.changAvatar(this.cropImage.src)
                    // this.$emit("editUser", this.form)
                })
            } else if(event.target.files[0].size > 2000000){
                this.$toast.error(this.$t("FileMustBeLessThan2MB"))
                return
            } else if(
                event.target.files[0].type != "image/jpeg" &&
                event.target.files[0].type != "image/png"
            ){
                this.$toast.error(this.$t("fileIsNotJPEGOrPNG"))
                return
            }

            // this.eventBus.emit('cropImage', {
            //     img: this.cropImage
            // })


        },
        changAvatar(base){
            let obj = {
                avatar: base
            }
            apiServe.uploadAvatar(this.form.workerId, obj).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('updateCard')
                } else {
                    this.$toast.error(this.$t('err'))   // Вивід повідомлення про помилку

                    var errorText = 'Помилка збереження прав доступу співробітника'; 
                    var statusRequest = result.status;
                    var api = 'editPermsUser';
                    var fileRequest = 'src/views/users/view/index.vue';
                    var params = JSON.stringify({
                        id: this.form.workerId, 
                        form: obj
                    });
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        getInitials(string) {
            // Метод для отримання ініціалів працівника

            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }

            return initials;
        }, 
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        formatDateTime(dateTimeString){
            const months = [
                "січня", "лютого", "березня", "квітня", "травня", "червня",
                "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
            ];
            const date = new Date(dateTimeString);
            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();

            return `${day} ${month}, ${year}`;
        },
        mutateWorkerStatus(e) {
            /*
                Функція для застосування перекладу до статусу співробітника
            */

            return mutateWorkersAccount(e)
        },
        mutateWorkerJob(e){
            /*
                Функція для застосування перекладу до посади співробітника
            */

            return mutateWorkerJob(e)
        },
    },
    computed: {
        user(){
            /*
                Властивість, яка повертає дані за користувача зі збережених даних у глобальному стору.
            */
            return storeS.userbase
        },
        perms(){
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        },
        workers() {
            /*
                Властивість, яка повертає список співробітників зі збережених даних у глобальному стору.
            */
            return storeS.workers
        }
    }
})
</script>

<style scoped>
.avatar-title {
    font-size: 30px;
    font-weight: 600;
}

.table-responsive {
    overflow: auto;
}

.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 5px;
    height: 5px;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
</style>