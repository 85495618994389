<!--  Вікно з підтвердженням зміни статусу рішення тікету -->

<template>

    <dialogModal>
        
        <template v-slot:title>
            {{ this.$t('changeDecidedStatus') }}
        </template>

        <template v-slot:body>  
            
            <!-- Текст попередження -->
            <div class="mt-4 text-center">
                <h3><b>{{ $t('ticketsisRelease') }}?</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('confirmDecidedStatus') }}</p>
            </div>

        </template>

        <template v-slot:footer-bottom>
            
            <button class="btn btn-success mt-3" @click="$emit('confirmDecidedTicket')">{{this.$t('Confirm')}}</button>
        </template>

    </dialogModal>
    
</template>

<script>
import dialogModal from "@/components/modal/dialog.vue"

export default {
    components: {
        dialogModal
    },
    data() {
        return {
            form: '',
        }
    },
}
</script>