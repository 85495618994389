<!-- Перегляд завдання -->

<template>
    
    <modal 
        @close="$emit('close')"
        :title="`${added ? this.$t('AddTask') : '#'+form.taskId}`" 
        :bgColorBody="'white'"
    >
    <!-- <modal > -->

        <template v-slot:body>

            <b-row>

                <b-col lg="8">

                    <!-- <div class="card" > -->
                    <div >
                        <!-- <div class="card-body" > -->
                        <div class="" >
                            <div class="text-muted" >
                                <div v-if="isEditingTaskName" class="editable-field">
                                    <h2
                                        class="mb-3 fw-semibold p-2"
                                    >
                                        <textarea 
                                            v-model="editFormTaskName" 
                                            class="textInput"
                                            rows="1"
                                            ref="taskNameInput"
                                            @blur="saveTaskName"
                                        >
                                            {{ editFormTaskName }}
                                        </textarea>
                                    </h2>
                                </div>
                                <div 
                                    v-else
                                    class="textVHover"
                                >
                                    <h2
                                        @click="editTaskName"
                                        class="mb-3 fw-semibold p-2"
                                    >
                                        {{ form.taskName == "" ? $t('enterThemeTask') : form.taskName }}
                                    </h2>
                                </div>
                                
                                <h6 class="mb-2 mt-2 fw-semibold text-muted">{{ $t('desc') }}</h6>

                                <div v-if="isEditingDesc" class="editable-field">
                                    <h5
                                        class="mb-3 fw-semibold text-uppercase"
                                    >
                                        <QuillEditor 
                                            v-model:content="editFormDesc" 
                                            ref="quill" 
                                            :modules="modules" 
                                            theme="snow" 
                                            contentType="html" 
                                            toolbar="full"
                                            @blur="saveTaskDesc"
                                        />
                                    </h5>
                                </div>
                                <div 
                                    v-else
                                >
                                    <div 
                                        v-if="form.description != ''"
                                        @click="editTaskDesc"
                                        class="desc_ql textVHover p-2 mb-3" 
                                        v-html="form.description"
                                        style="min-height: 100px;"
                                    >
                                    </div>
                                    <div 
                                        v-else
                                    >
                                        <h6
                                            @click="editTaskDesc"
                                            class="mb-3 textVHover p-2"
                                            :style="`${added ? 'height:200px;font-size:16px;' : 'height:200px'}`"
                                        >
                                            {{ $t('AddDescriptionTotheTask') }}
                                        </h6>
                                    </div>
                                </div>

                                <!-- Дочірні завдання -->
                                <div v-if="!added" class="align-items-center d-flex" >
                                    <h4 class="card-title mb-0 flex-grow-1 fw-semibold">{{ $t('subTasks') }}</h4>
                                    <div class="d-flex gap-1">

                                        <!-- Сортування сабтасок -->
                                        <div class="dropdown card-header-dropdown" >
                                            <a class="btn btn-soft-primary btn-sm" role="button" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {{ $t('Sorting') }} {{ sortSubTasks != '' ? ': ' + sortSubTasks : '' }}
                                                <i class="mdi mdi-chevron-down align-middle ms-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <a class="dropdown-item" @click="sortSubTasks = 'пріоритет'">{{ $t('priority') }}</a>
                                                <a class="dropdown-item" @click="sortSubTasks = 'статус'">{{ $t('Status') }}</a>
                                                <a class="dropdown-item" @click="sortSubTasks = 'виконавець'">{{ $t('Performer') }}</a>
                                                <div class="dropdown-divider" ></div>
                                                <a class="dropdown-item" @click="sortSubTasks = ''">{{ $t('Default') }}</a>
                                            </div>
                                        </div>

                                        <!-- Додавання сабтаски -->
                                        <button 
                                            type="button" 
                                            class="btn btn-soft-primary btn-sm"
                                            @click="toggleNewTaskInput"
                                        >
                                            +
                                        </button>

                                    </div>
                                </div>

                                <!-- Поле вводу нової задачі -->
                                <transition name="fade">
                                    <div v-if="showNewTaskInput" class="mt-3">
                                        <input
                                            type="text"
                                            ref="taskInput"
                                            class="form-control mb-2"
                                            placeholder="Що необхідно зробити?"
                                            v-model="newTaskTitle"
                                            @keydown.enter="addSubTask"
                                        />
                                        <div class="d-flex gap-2">
                                            <button
                                                class="btn btn-soft-success btn-md"
                                                @click="addSubTask"
                                            >
                                                +
                                            </button>
                                            <button
                                                class="btn btn-soft-danger btn-md"
                                                @click="toggleNewTaskInput"
                                            >
                                                {{ $t('cancel') }}
                                            </button>
                                        </div>
                                    </div>
                                </transition>

                                <!-- Список сабтасок -->
                                <template
                                    v-for="item in form.subTasks"
                                    :key="item"
                                >
                                    <div 
                                        class="card mb-1 card-animate copy subtask"
                                        @click="getTask(item)"
                                    >
                                        <div class="card-body">

                                            <div class="d-flex" style="align-items: center;">
                                                <div class="flex-grow-1" >

                                                    <!-- id саб-таски -->
                                                    <span class="text-muted">#{{ item.taskId }}</span>
                                                    <span class="ms-2 text-black fs-14">{{ item.taskName }}</span>

                                                </div>

                                                <div class="flex-shrink-0" >
                                                    <div class="d-flex gap-1 align-items-center" >

                                                        <span
                                                            :class="`${mutatepriority(item.priority).color} copy`"
                                                        >
                                                            {{ mutatepriority(item.priority).name }}
                                                        </span>

                                                        <!-- Статус сабтаски -->
                                                        <span
                                                            :class="`badge badge-soft-info text-white`"
                                                            :style="`background-color: ${getColor()}!important`"
                                                        >
                                                            {{ mutateKanbanColumn(item.status).name }}
                                                        </span>

                                                        <!-- Хто виконує сабтаску -->
                                                        <div class="avatar-xs ms-1">
                                                            <div class="avatar-title bg-info rounded-circle">
                                                                НВ
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </template>

                            </div>
                        </div>
                    </div>

                    <!-- Коментарі, історія змін -->

                    <!-- <div class="card" > -->
                    <div class="mt-5" v-if="!added">
                        <div class="" >
                            <h5 class="mb-3 fw-semibold text-uppercase">{{ $t('Activity') }}</h5>
                            <ul class="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist" style="overflow-y: hidden;">

                                <!-- Всі -->
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#arrow-all-activity" role="tab" aria-selected="true">
                                        <span class="d-block d-sm-none"><i class="mdi mdi-home-variant"></i></span>
                                        <span class="d-none d-sm-block">{{ $t('all') }}</span>
                                    </a>
                                </li>

                                <!-- Коментарі -->
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-bs-toggle="tab" href="#arrow-comments-activity" role="tab" aria-selected="false" tabindex="-1">
                                        <span class="d-block d-sm-none"><i class="mdi mdi-account"></i></span>
                                        <span class="d-none d-sm-block">{{ $t('Comments') }}</span>
                                    </a>
                                </li>

                                <!-- Історія -->
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-bs-toggle="tab" href="#arrow-history-activity" role="tab" aria-selected="false" tabindex="-1">
                                        <span class="d-block d-sm-none"><i class="mdi mdi-email"></i></span>
                                        <span class="d-none d-sm-block">{{ $t('Story') }}</span>
                                    </a>
                                </li>
                            </ul>
                            <!-- Tab panes -->
                            <div class="tab-content text-muted" >
                                <div class="tab-pane active show" id="arrow-all-activity" role="tabpanel" >
                                    <!-- Все по тасці (коментарі та історія) -->
                                    
                                    <!-- <template v-for="item in form.activities_all" :key="item">

                                        <div class="content mb-4 mt-2" >
                                            <div class="d-flex align-items-center" >
                                                <div class="flex-shrink-0" >
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title bg-info rounded-circle">
                                                            {{ this.getInitials(item.user) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 ms-3" >
                                                    <h5 class="fs-15">{{ item.user }} {{ item.action }} <small class="text-muted fs-13 fw-normal">- {{ formatDateTime(item.date) }}</small> <span class="badge bg-soft-primary text-primary btn-sm"> тип запису</span></h5> 

                                                    <div class="d-flex gap-2" v-if="item.history">
                                                        <span>{{ item.history.from }}</span>
                                                        <span><i class="ri-arrow-right-line"></i></span>
                                                        <span>{{ item.history.to }}</span>
                                                    </div>

                                                    <span v-if="item.comment">
                                                        {{ item.comment }}
                                                    </span>

                                                </div>
                                            </div>
                                        </div>

                                    </template> -->

                                </div>
                                <div class="tab-pane" id="arrow-comments-activity" role="tabpanel" >
                                    <!-- Коментарі до таски -->
                                    
                                    <b class="d-block m-auto bg-danger text-white fs-18 p-4 mb-2">Тут має бути форма повідомлення todo</b>

<!-- 
                                    <template v-for="item in form.activities_comments" :key="item">

                                        <div class="content mb-4 mt-2" >
                                            <div class="d-flex align-items-center" >
                                                <div class="flex-shrink-0" >
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title bg-info rounded-circle">
                                                            {{ this.getInitials(item.user) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 ms-3" >
                                                    <h5 class="fs-15">{{ item.user }} <small class="text-muted fs-13 fw-normal">- {{ formatDateTime(item.date) }}</small></h5>
                                                    <span>
                                                        {{ item.text }}
                                                    </span>
                                                    
                                                    <div class="reactions mt-2 d-flex gap-2 align-items-center">
                                                        <span 
                                                            v-for="(count, key) in item.reactions" 
                                                            :key="key" 
                                                            class="reaction btn-sm btn-soft-info copy "
                                                            @click="addReaction(item, key)"
                                                        >
                                                            <span class="fs-14">{{ mutateEmoji(key) }}</span> <span style="font-weight: 500;">{{ count }}</span>
                                                        </span>
                                                        <button 
                                                            @click="toggleEmojiPicker(item)"
                                                            class="reaction btn-sm btn-soft-info fs-14"
                                                        >
                                                            <i class="bx bx-smile align-middle"></i>
                                                        </button>
                                                    </div>

                                                    <emoji-picker
                                                        v-if="item.showEmojiPicker"
                                                        @select="(emoji) => selectEmoji(item, emoji)"
                                                        @close="toggleEmojiPicker(item, false)"
                                                    />

                                                </div>
                                            </div>
                                        </div>

                                    </template> -->

                                </div>
                                <div class="tab-pane" id="arrow-history-activity" role="tabpanel" >
                                    <!-- Історія таски -->
<!-- 
                                    <template v-for="item in form.activities_history" :key="item">

                                        <div class="content mb-4 mt-2" >
                                            <div class="d-flex align-items-center" >
                                                <div class="flex-shrink-0" >
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title bg-info rounded-circle">
                                                            {{ this.getInitials(item.user) }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 ms-3" >
                                                    <h5 class="fs-15">{{ item.user }} {{ item.action }} <small class="text-muted fs-13 fw-normal">- {{ formatDateTime(item.date) }}</small></h5>
                                                    <div class="d-flex gap-2" v-if="item.newValue != null">
                                                        <span>{{ item.oldValue }}</span>
                                                        <span><i class="ri-arrow-right-line"></i></span>
                                                        <span>{{ item.newValue }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </template> -->

                                </div>
                            </div>

                        
                        </div>
                    </div>

                </b-col>

                <b-col lg="4">

                    <div class="accordion custom-accordionwithicon accordion-fill-info" id="accordionFill" >
                        <div class="accordion-item" >
                            <h2 class="accordion-header" id="accordionFillExample1">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accor_fill1" aria-expanded="true" aria-controls="accor_fill1">
                                    {{ $t('General') }}
                                </button>
                            </h2>
                            <div id="accor_fill1" class="accordion-collapse collapse show" aria-labelledby="accordionFillExample1" data-bs-parent="#accordionFill"  style="">
                                <div class="accordion-body" >
                                    
                                    <div class="mb-4" >
                                        <Multiselect 
                                            v-model="editableStatus" 
                                            :close-on-select="true" 
                                            :searchable="true"
                                            :create-option="false" 
                                            :options="columnArr" 
                                            @focusout="saveStatus"
                                        />
                                    </div>
                                    <div class="table-card" >
                                        <table class="table mb-0 fs-14">
                                            <tbody>

                                                <!-- Виконавець -->
                                                <tr v-if="!added">
                                                    <td class="fw-medium">{{ $t('Performer') }}</td>
                                                    <td>
                                                        <div class="editable-container1">
                                                            <div v-if="isEditingPerformer" class="editable-field">
                                                                <Multiselect 
                                                                    v-model="edittablePerformer"
                                                                    :close-on-select="true"
                                                                    :searchable="true"
                                                                    :create-option="false"
                                                                    :options="this.listWorkers"
                                                                    ref="checkTaskWorker"
                                                                    @close="savePerformer"
                                                                />
                                                            </div>
                                                            <template v-else >

                                                                <div class="d-flex align-items-center gap-2" @click="editPerformer">
                                                                    <div class="avatar-xs">
                                                                        <div class="avatar-title fs-12 bg-info rounded-circle">
                                                                            <img
                                                                                v-if="getImgWorker(form.performerWorkerId) != ''"
                                                                                :src="getImgWorker(form.performerWorkerId)" 
                                                                                :alt="form.performerWorkerName" 
                                                                                class="avatar-xs rounded-circle"
                                                                            > 
                                                                            <template v-else>
                                                                                {{ this.getInitials(form.performerWorkerName == "" ? $t('NotDefined') : form.performerWorkerName) }}
                                                                            </template>
                                                                        </div>
                                                                    </div> 
                                                                    <div class="flex-grow-1" >
                                                                        <h5 class="fs-13 mb-0">
                                                                            <a class="text-body d-block">{{ form.performerWorkerName == "" ? $t('NotDefined') : form.performerWorkerName }}</a>
                                                                        </h5>
                                                                    </div>
                                                                </div>

                                                               
                                                            </template>
                                                        </div>
                                                        <span
                                                            @click="chooseYourself()"
                                                            class="text-info fs-13 d-block mt-1 copy"
                                                        >
                                                            {{ $t('ChooseYourself') }}
                                                        </span>
                                                    </td>
                                                </tr>

                                                <!-- Автор (не змінюється) -->
                                                <tr>
                                                    <td class="fw-medium">{{ $t('Author') }}</td>
                                                    <td>
                                                        <div class="noEdittable-container">
                                                            <div class="d-flex align-items-center gap-2">
                                                                <div class="avatar-xs">
                                                                    <div class="avatar-title fs-12 bg-warning rounded-circle">
                                                                        {{ this.getInitials(form.createWorkerName) }} 
                                                                    </div>
                                                                </div> 
                                                                <div class="flex-grow-1" >
                                                                    <h5 class="fs-13 mb-0"><a class="text-body d-block">{{ form.createWorkerName }}</a></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <!-- Пріоритет -->
                                                <tr>
                                                    <td class="fw-medium">{{ $t('priority') }}</td>
                                                    <td>
                                                        <div class="editable-container1">
                                                            <div v-if="isEditingPriority" class="editable-field">
                                                                <Multiselect 
                                                                    v-model="editablePriority"
                                                                    :close-on-select="true"
                                                                    :searchable="true"
                                                                    :create-option="false"
                                                                    :options="this.listPriority"
                                                                    :placeholder="$t('priority')"
                                                                    ref="checkTaskPriority"
                                                                    @close="savePriority"
                                                                />
                                                            </div>
                                                            <div
                                                                @click="editPriority"
                                                                v-else
                                                            >
                                                                <span
                                                                    :class="`${mutatepriority(form.priority).color} copy`"
                                                                >
                                                                    {{ mutatepriority(form.priority).name }}
                                                                    <!-- {{ form }} -->
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <!-- Статус -->
                                                <tr v-if="form.columnName">
                                                    <td class="fw-medium">{{ $t('Status') }}</td>
                                                    <td>
                                                        <div class="noEdittable-container">
                                                            <span
                                                                :class="`badge badge-soft-info text-white`"
                                                                :style="`background-color: ${getColor()}!important`"
                                                            >
                                                                {{ mutateKanbanColumn(form.columnName).name }}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="fw-medium">{{ $t('Deadline') }}</td>
                                                    <!-- <td>{{ formatDateTime(form.deadlineDatetime) }}</td> -->
                                                    <td>
                                                        <div class="editable-container1">
                                                            <div v-if="isEditingDeadLineDate" class="editable-field">
                                                                <flatpickr 
                                                                    v-model="editableDeadLineDate" 
                                                                    :config="config" 
                                                                    class="form-control border-0 dash-filter-picker shadow"
                                                                    @change="saveDeadLine"
                                                                    ref="checkTaskDeadLineDate"
                                                                    :placeholder="$t('enterDeadline')"
                                                                ></flatpickr>
                                                            </div>
                                                            <div
                                                                v-else
                                                                @click="editDeadLine"    
                                                            >
                                                                <span 
                                                                >
                                                                    {{ form.deadlineDatetime != null ? formatDateTime(form.deadlineDatetime) : $t('NotDefined') }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="form.createDatetime">
                                                    <td class="fw-medium">{{ $t('createDate') }}</td>
                                                    <td>
                                                        <div class="noEdittable-container">
                                                            {{ formatDateTime(form.createDatetime) }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </b-col>

            </b-row>


        </template>

        <template v-slot:footer-bottom >

            <button 
                v-if="added"
                @click="addTask()"
                type="submit"
                class="btn btn-info"
            >
                {{ $t('Add') }}
            </button>

        </template>

    </modal>

    <!-- 
        Перегляд картки завдання
        - todo закриття модалки
    -->
    <viewTask 
        v-if="showSubTask"
        @close="showSubTask = false"
        :objCard="this.form"
        :columnArr="columnArr"
        :projectId="objCard.projectId"
    />


</template>

<script>
import modal from '@/components/modal/modal-lg'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import EmojiPicker from "./EmojiPicker.vue"; // Компонент для вибору емодзі
import viewTask from './viewTask.vue' // Перегляд картки завдання

import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";

import BlotFormatter from 'quill-blot-formatter'

import { 
    mutateEmoji,
    mutatePriorityTicket,
    mutateKanbanColumn
} from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store'

import { developmentProjects } from '@/API.js';

let apiServe = new developmentProjects();

export default {
    props: ["objCard", "columnArr", "projectId", "added"],
    components: {
        modal,
        Multiselect,
        EmojiPicker,
        flatpickr: Vueflatpickr,
        viewTask
    },
    setup: () => {
        const modules = {
            name: 'blotFormatter',  
            module: BlotFormatter, 
            options: {/* options */}
        }
        return { modules }
    },
    data() {
        return {
            showEmojiPicker: false,
            form: '',
            showSubTask: false, // блок перегляду таски
            // form: {
            //     id: "task-101",
            //     title: "Додати можливість обирати групу клієнтів для окремо обраних клієнтів через меню Дія", // завдання
            //     desc: "<p>Коли відміняєш знижку і натискаєш кнопку Додати то ця сама знижка не відображаються <br>Цей баг є тільки зі знижками в бонусах все працює ок <br>І ще чомусь оцей надпис про додавання не відображається зверху, тобто щоб його побачить треба робити скрол вниз, можливо спробувати його перенести вище<br>Проблема відтворюється як на ios так і на вінді </p>",
            //     status: "in_work", // дошка/статус
            //     performer: "Максим", // виконавець
            //     author: "Danya Latiy", // автор
            //     priority: "3", // пріоритет
            //     deadline: "2024-11-24 11:56:04", // термін виконання 
            //     dateCreated: "2024-10-20 12:11:50", // дата створення 
            //     tasks: [
            //         {
            //             "id": "task-201",
            //             "title": "Не додається товар у пос-терміналі репорт 1",
            //             "description": "",
            //             "priority": "high",
            //             "ticket": "3145",
            //             "status": "До виконання",
            //             "worker": "Maxim"
            //         },
            //         {
            //             "id": "task-202",
            //             "title": "Не додається товар у пос-терміналі репорт 2",
            //             "description": "",
            //             "priority": "high",
            //             "ticket": "3145",
            //             "status": "Тестується",
            //             "worker": "Maxim"
            //         }
            //     ],
            //     activities_all: [
            //         {
            //             "user": "Danya Latiy",
            //             "action": "изменил Статус",
            //             "date": "2024-11-27T15:56:00",
            //             "history": {
            //                 "from": "To Do",
            //                 "to": "Testing"
            //             }
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "action": "изменил Статус",
            //             "date": "2024-11-25T15:56:00",
            //             "history": {
            //                 "from": "Testing",
            //                 "to": "Done"
            //             }
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "action": "обновил Решение",
            //             "date": "2024-11-25T15:56:00",
            //             "history": {
            //                 "from": "Нет",
            //                 "to": "Done"
            //             }
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "action": "обновил Rank",
            //             "date": "2024-11-25T15:56:00",
            //             "history": {
            //                 "from": "Нет",
            //                 "to": "Ranked higher"
            //             }
            //         },
            //         {
            //             "user": "Vadim Atamanyuk",
            //             "action": "добавил комментарий",
            //             "date": "2024-11-29T16:03:00",
            //             "comment": "Я вже все зробив, можна замінити, видалити без заміни і т.д. Але цій модалці займаюсь рефактором карточки лоялки тому заллю вже разом"
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "action": "добавил комментарий",
            //             "date": "2024-11-29T14:40:00",
            //             "comment": "Не зовсім зрозумів питання )"
            //         },
            //         {
            //             "user": "Дмитрий Ланин",
            //             "action": "добавил комментарий",
            //             "date": "2024-11-28T12:38:00",
            //             "comment": "Як бути якщо я хочу прибрати лоялку з групи?"
            //         },
            //         {
            //             "user": "Vadim Atamanyuk",
            //             "action": "добавил комментарий",
            //             "date": "2024-11-28T12:29:00",
            //             "comment": "Да, тепер для видалення обов'язково треба вибрати іншу лоялку"
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "action": "добавил комментарий",
            //             "date": "2024-11-27T16:34:00",
            //             "comment": "Я так зрозумів ви тут змінили логіку і тепер для видалення треба замінити бонусну програму. Якщо це так, тоді все працює ок. Зараз ще поклацаю як воно точно працює. Якщо щось знайду, то сюди відпишу."
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "action": "обновил Описание",
            //             "date": "2024-11-22T16:24:00",
            //             "history": {
            //                 "from": "Нет",
            //                 "to": "!image-20241122-142442.png|width=1184,height=839,alt=\"image-20241122-142442.png\"!"
            //             }
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "action": "создал Задача",
            //             "date": "2024-11-22T16:24:00",
            //             "history": "История создания задачи"
            //         }
            //     ],
            //     activities_comments: [
            //         {
            //             "user": "Vadim Atamanyuk",
            //             "date": "2024-11-29T16:03:00",
            //             "text": "Я вже все зробив, можна замінити, видалити без заміни і т.д. Але цій модалці займаюсь рефактором карточки лоялки тому заллю вже разом",
            //             "reactions": {
            //                 "fire": 3,
            //                 "thumbs_up": 1
            //             },
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "date": "2024-11-29T14:40:00",
            //             "text": "Не зовсім зрозумів питання )",
            //             "reactions": {}
            //         },
            //         {
            //             "user": "Дмитрий Ланин",
            //             "date": "2024-11-28T12:38:00",
            //             "text": "Як бути якщо я хочу прибрати лоялку з групи?",
            //             "reactions": {}
            //         },
            //         {
            //             "user": "Vadim Atamanyuk",
            //             "date": "2024-11-28T12:29:00",
            //             "text": "Да, тепер для видалення обов'язково треба вибрати іншу лоялку",
            //             "reactions": {}
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "date": "2024-11-27T16:34:00",
            //             "text": "Я так зрозумів ви тут змінили логіку і тепер для видалення треба замінити бонусну програму. Якщо це так тоді все працює ок. Зараз ще поклацаю як воно точно працює. Якщо щось знайду то сюди відпишу.",
            //             "reactions": {}
            //         }
            //     ],
            //     activities_history: [
            //         {
            //             "user": "Danya Latiy",
            //             "date": "2024-12-02",
            //             "action": "изменил Статус",
            //             "oldValue": "Testing",
            //             "newValue": "Done",
            //             "daysAgo": 5
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "date": "2024-12-02",
            //             "action": "обновил Решение",
            //             "oldValue": "Нет",
            //             "newValue": "Done",
            //             "daysAgo": 5
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "date": "2024-12-02",
            //             "action": "обновил Rank",
            //             "oldValue": "Нет",
            //             "newValue": "Ranked higher",
            //             "daysAgo": 5
            //         },
            //         {
            //             "user": "Vadim Atamanyuk",
            //             "date": "2024-11-27T15:56:00",
            //             "action": "изменил Статус",
            //             "oldValue": "To Do",
            //             "newValue": "Testing"
            //         },
            //         {
            //             "user": "Vadim Atamanyuk",
            //             "date": "2024-11-27T15:56:00",
            //             "action": "обновил Rank",
            //             "oldValue": "Нет",
            //             "newValue": "Ranked higher"
            //         },
            //         {
            //             "user": "Дмитрий Ланин",
            //             "date": "2024-11-27T13:14:00",
            //             "action": "изменил Исполнитель",
            //             "oldValue": "Не назначено",
            //             "newValue": "Макс"
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "date": "2024-11-22T16:24:00",
            //             "action": "обновил Описание",
            //             "oldValue": "Нет",
            //             "newValue": "!image-20241122-142442.png|width=1184,height=839,alt=\"image-20241122-142442.png\"!"
            //         },
            //         {
            //             "user": "Danya Latiy",
            //             "date": "2024-11-22T16:24:00",
            //             "action": "создал Задача",
            //             "oldValue": null,
            //             "newValue": null
            //         }
            //     ]
            // },
            sortSubTasks: '',
            showNewTaskInput: false, // відображення блоку нового завдання
            newTaskTitle: '', // поле вводу
            isEditingPriority: false,
            editablePriority: null,
            listPriority: [ // список пріоритетів
                {
                    "label": this.$t('Critical'),
                    "value": 3
                },
                {
                    "label": this.$t('priorityHigh'),
                    "value": 2
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": 1
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": 0
                }
            ],
            isEditingStatus: false,
            editableStatus: null,
            isEditingPerformer: false,
            edittablePerformer: null,
            listWorkers: [], // список співробітників
            isEditingDeadLineDate: false,
            editableDeadLineDate: "",
            isEditingTaskName: false,
            editFormTaskName: "",
            isEditingDesc: false,
            editFormDesc: "",
            config: { // параметри налаштування Flatpickr
                mode: "single", // параметр, який дозволяє вибрати період
                minDate: "today", // параметр, від якої дати можна обрати період
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false, // вимкнення вказування часу
                dateFormat: "Y-m-d", // формат дати
                locale: "" // мова 
            },
        }
    },
    created(){
        this.getWorkers();
        this.getdata()

        // Налаштування мови Flatpickr в залежності від мови користувача
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
    },
    methods: {
        getdata(){
            // отримання даних
            this.form = this.objCard
            this.editableStatus = this.form.columnId
            this.edittablePerformer = this.form.performerWorkerId;
        },
        getTask(item){
            // відкриття таски

            apiServe.getTask(item.taskId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    // this.columnArr = this.transformColumns(this.columns);
                    this.showSubTask = true
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        addTask(){
            // Створення таски (якщо виклик вікна з календаря)

            console.log("new", this.form);
            

            apiServe.addTask(this.form.columnId, this.form).then(result => {
                if(result.status == 'done') {
                    this.form = result.data
                    this.$toast.success(this.$t('Saved'))
                    this.$emit('close')
                } else {
                    this.$toast.error(result.error[0])
                }
            })

        },
        editTask(){
            // редагування таски

            apiServe.editTask(this.form.taskId, this.form).then(result => {
                if(result.status == 'done') {
                    this.form = result.data
                    this.$toast.success(this.$t('Saved'))
                } else {
                    this.$toast.error(result.error[0])
                }
            })
            
        },
        toggleNewTaskInput() {
            // Відображаємо блок створення нового завдання

            this.showNewTaskInput = !this.showNewTaskInput;
            this.newTaskTitle = ''; // Очистка поля введення
            this.$nextTick(() => {
                const input = this.$refs.taskInput;
                input.focus();
            });
        },
        addNewTask() {
            // видалити код (був для саб-тасок)
            // if (this.newTaskTitle.trim()) {
            //     this.form.tasks.push({
            //         id: this.form.tasks.length + 1,
            //         title: this.newTaskTitle,
            //         priority: 'Низький',
            //         status: 'До виконання',
            //         worker: 'Не назначено',
            //     });
            //     this.toggleNewTaskInput(); // Приховати поле введення
            // }
        },
        addSubTask(){
            // створення саб-таски
            
            const closestNegativeColumn = this.columnArr
                .filter(col => col.value < 0) // Вибираємо тільки колонки з від'ємними значеннями
                .reduce((closest, current) => {
                    return Math.abs(current.value) < Math.abs(closest.value) ? current : closest;
                });

            console.log("closestNegativeColumn.", closestNegativeColumn);

            var newForm = {
                taskName: this.newTaskTitle,
                priority: 0,
                description: "",
                ticketId: null,
                columnId: closestNegativeColumn.value,
                parentCategoryId: this.form.taskId,
                sort: 0,
                deadlineDatetime: "",
                projectId: this.projectId
            }

            console.log("newForm.", newForm);

            apiServe.addTask(closestNegativeColumn.value, newForm).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Added'))
                    this.toggleNewTaskInput(); // Приховати поле введення
                    // this.getdata()
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        getColor(){
            // Отримання кольору колонки
            var color = "";

            this.columnArr.forEach(element => {
                if(element.value == this.form.columnId) {
                    color = element.color
                }
            });
            
            return color
        },
        formatDateTime(dateTimeString){
            const months = [
                "січня", "лютого", "березня", "квітня", "травня", "червня",
                "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
            ];
            const date = new Date(dateTimeString);
            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            return `${day} ${month}, ${year}`;
        },
        addReaction(item, emoji) {
            console.log("item", item);
            console.log("emoji", emoji);
            
            if (item.reactions[emoji]) {
                item.reactions[emoji]++;
            } else {
                // Просто додайте нове поле до реактивного об'єкта
                item.reactions[emoji] = 1;
            }
        },
        toggleEmojiPicker(item, state = null) {
            item.showEmojiPicker = state !== null ? state : !item.showEmojiPicker;
        },
        selectEmoji(item, emoji) {
            console.log("selectEmoji", emoji);
            console.log("item", item);
            
            this.addReaction(item, emoji);
            this.toggleEmojiPicker(item, false);
        },
        mutateEmoji(e){
            // мутейт емодзі
            return mutateEmoji(e)
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutateKanbanColumn(e){
            // мутейн назви колонки
            return mutateKanbanColumn(e)
        },
        getWorkers(){
            // Отримуємо список 
            this.listWorkers = [];
            for(var item in this.workers){
                this.listWorkers.push({
                    label: this.workers[item].workerName,
                    value: this.workers[item].workerId
                })
            }
        },
        chooseYourself(){
            // Обрати себе виконавцем
            this.form.performerWorkerId = this.user.userid
            this.form.performerWorkerName = this.user.name
            if(!this.added){
                this.editTask();
            }
        },
        editPerformer() {
            // Редагуємо виконавця
            this.isEditingPerformer = true;

            // checkTaskPriority
            this.$nextTick(() => {
                const input = this.$refs.checkTaskWorker;
                input.focus()
            })

            this.edittablePerformer = this.form.performerWorkerId;
        },
        savePerformer() {
            // Оновлення тільки якщо значення змінилося
            if (this.edittablePerformer !== this.form.performerWorkerId) {
                this.form.performerWorkerId = this.edittablePerformer;
                this.editTask(); // Зберігаємо зміни
                this.$nextTick(() => {
                    this.isEditingPerformer = false;
                }); 
            } else {
                // this.isEditingPerformer = false
                this.$nextTick(() => {
                    this.isEditingPerformer = false;
                }); 
            }
        },
        editPriority() {
            // Редагуємо пріоритет
            this.isEditingPriority = true;

            // checkTaskPriority
            this.$nextTick(() => {
                const input = this.$refs.checkTaskPriority;
                input.focus()
            })

            this.editablePriority = this.form.priority;
        },
        savePriority() {
            // Зберігаємо новий пріоритет

            // Оновлення тільки якщо значення змінилося
            if (this.editablePriority !== this.form.priority) {
                this.form.priority = this.editablePriority;
                
                // якщо вікно створення таски
                if(!this.added){
                    this.editTask();
                }

                this.$nextTick(() => {
                    this.isEditingPriority = false;
                }); 
            } else {
                this.$nextTick(() => {
                    this.isEditingPriority = false;
                }); 
            }
            
        },
        saveStatus() {
            // Застосовуємо новий статус

            // якщо статус той самий, то не редагуємо
            if(this.editableStatus == this.form.columnId) {
                return
            }

            this.form.columnId = this.editableStatus
            // якщо вікно створення таски
            if(!this.added){
                this.editTask();
            }
        },
        editDeadLine(){
            // Редагуємо дату дедлайну
            this.isEditingDeadLineDate = true
            this.editableDeadLineDate = this.form.deadlineDatetime;
        },
        saveDeadLine(){
            // Зберігаємо нову дату дедлайну

            console.log("saveDeadLine", this.editableDeadLineDate);

            // якщо статус той самий, то не редагуємо
            if(this.editableDeadLineDate == this.form.deadlineDatetime || this.editableDeadLineDate == "") {
                this.$nextTick(() => {
                    this.isEditingDeadLineDate = false;
                }); 
                return
            }

            this.form.deadlineDatetime = this.editableDeadLineDate;
           
            // якщо вікно створення таски
            if(!this.added){
                this.editTask();
            }
            
            this.$nextTick(() => {
                this.isEditingDeadLineDate = false;
            }); 
        },
        editTaskName(){
            this.isEditingTaskName = true
            this.editFormTaskName = this.form.taskName

            this.$nextTick(() => {
                this.$refs.taskNameInput.focus(); // Фокус на текстовому полі
            });
        },
        saveTaskName(){
            // Оновлення тільки якщо значення змінилося
            if (this.editFormTaskName !== this.form.taskName) {
                this.form.taskName = this.editFormTaskName;
                
                // якщо вікно створення таски
                if(!this.added){
                    this.editTask();
                }

                this.$nextTick(() => {
                    this.isEditingTaskName = false;
                }); 
            } else {
                this.$nextTick(() => {
                    this.isEditingTaskName = false;
                }); 
            }
        },
        editTaskDesc(){
            this.isEditingDesc = true
            this.editFormDesc = this.form.description

            this.$nextTick(() => {
                const quillEditor = this.$refs.quill.getQuill();
                quillEditor.focus(); // Фокус на редакторі Quill
            });
        },
        saveTaskDesc(){
            // Оновлення тільки якщо значення змінилося
            if (this.editFormDesc !== this.form.description) {
                this.form.description = this.editFormDesc;
                
                // якщо вікно створення таски
                if(!this.added){
                    this.editTask();
                }

                this.$nextTick(() => {
                    this.isEditingDesc = false;
                }); 
            } else {
                this.$nextTick(() => {
                    this.isEditingDesc = false;
                }); 
            }
        },
        getImgWorker(workerId){
            // отримання зображення співробітника
            var avatar = ""
            this.workers.forEach(element => {
                if(element.workerId == workerId){
                    avatar = element.avatar;
                }
            });
            return avatar
        }
    },
    computed: {
        workers(){
            // отримуємо список співробітників
            return storeS.workers
        },
        user() {
            // дані акаунту
            return storeS.userbase
        },
    }
}
</script>

<style scoped>
.editable-container {
  min-height: 40px; /* Однакова висота для всіх станів */
  display: flex;
  align-items: center; /* Вирівнювання по вертикалі */
}

.editable-container input {
  width: 100%; /* Інпут розтягується по ширині */
  height: 30px; /* Встановлена висота для інпуту */
  padding: 5px; /* Внутрішні відступи */
  box-sizing: border-box; /* Урахування рамок у розмірі */
}

.editable-container span {
  display: inline-block; /* Гарантія, що span матиме однакову висоту */
  line-height: 30px; /* Вирівнювання тексту по висоті */
}
.editable-container1{
    padding: 5px 10px;
    border-radius: 5px;
}
.editable-container1:hover{
    background-color: #299cdb1f;
    transition: .4s;
    cursor: pointer;
}
.noEdittable-container{
    padding: 5px 10px;
    border-radius: 5px;
}
.noEdittable-container:hover {
    cursor: not-allowed;
}
.textInput {
    background-color: white;
    border-color: #dcdcdc;
    width: 100%; /* Ширина буде як у текстового блоку */
    min-height: 34px;
    padding: 6px 7px;
    border-radius: 5px;
    resize: none; /* Відключаємо ручне розтягування */
    overflow: hidden;
    font-size: inherit; /* Наслідувати розмір шрифту від h5 */
    line-height: inherit; /* Наслідувати висоту рядка */
}
.textVHover {
    border-radius: 5px;
}
.textVHover:hover {
    background: #b2b2b230;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
    cursor: pointer;
}
</style>