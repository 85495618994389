<template>
    

    <!-- 
        todo , поки немає фільтрації
    -->
    <dialogBox 
        :title="'Додати таб'"
        :classhead="'bg-white'"
        @close="this.$emit('close')"
    >
    
        <template v-slot:title>{{ $t('Add') }}</template>

        <template v-slot:body>

            <b-row>
                <b-col lg="12" class="mb-3">
                    <label for="boardName" class="form-label">Введіть назву табу</label>
                    <input type="text" class="form-control" v-model="form.settingTab.nameTab" placeholder="Вкажіть назву">
                </b-col>
                <b-col lg="12">
                    <label for="boardName" class="form-label">Виберіть колір</label>
                        <b-row>
                            <template v-for="item in colors" :key="item">
                                <b-col lg="1" class="mb-2 copy" @click="form.settingTab.color = item.color">
                                    <div class="avatar-xs" >
                                        <div 
                                            class="avatar-title rounded text-white d-flex align-items-center justify-content-center" 
                                            :class="{'selected-color': form.settingTab.color === item.color}" 
                                        >
                                            <div 
                                                class="color-block w-100 h-100 rounded" 
                                                :style="`background-color: ${item.color};`"
                                            ></div>
                                            <span v-if="form.settingTab.color === item.color" class="check-icon">✔</span>
                                        </div>
                                    </div>
                                </b-col>
                            </template>
                        </b-row>
                </b-col>
            </b-row>

            <div class="dropdown-divider mt-3 mb-4" ></div>

            <!-- <div class="my-2">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('selectType') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                        >
                            <option v-for="val in listTypes" :key="val" :value="val.value" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div> -->

            <div class="my-2">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('searc_inp') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <!-- <textarea class="form-control" @input="inputSearch( $event )"></textarea> -->
                        <input class="form-control mb-3" @input="inputSearch( $event )" :placeholder="$t('search_word')" />
                    </div>
                </div>
            </div>

            <!-- <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('date') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                            v-model="form.pr1"
                        >
                            <option v-for="val in listDate" :key="val" :value="val.value" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div> -->

            <div class="dropdown-divider" ></div>


            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('Status') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                            v-model="form.filters.status"
                        >
                            <option v-for="val in listValue" :key="val" :value="val.value" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('decidedStatus') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                            v-model="form.filters.decidedStatus"
                        >
                            <option v-for="val in decidedStatusList" :key="val" :value="val.value" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('priority') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                            v-model="form.filters.priority"
                        >
                            <option v-for="val in listPriority" :key="val" :value="val.value" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('added') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                            v-model="form.filters.workerId"
                        >
                            <option v-for="val in listWorkers" :key="val" :value="val.value" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="dropdown-divider" ></div>

            <div class="mt-4">
                <div class="row align-items-center">
                    <div class="col-lg-4">
                        <h5 class="fs-15">{{ $t('inJobWorker') }}</h5>
                    </div>
                    <div class="col-lg-8">
                        <select
                            class="form-select mb-3"
                            v-model="form.filters.inJobWorkerId"
                        >
                            <option v-for="val in listWorkers" :key="val" :value="val.value" >{{val.title}}</option>
                        </select>
                    </div>
                </div>
            </div>

        </template>

        <template v-slot:footer-bottom>

            <!-- Редагування табу -->
            <button 
                v-if="editPermFilter"
                type="button" 
                class="btn btn-success" 
                @click="saveTab()"
            >
                {{ $t('Edit') }}
            </button>

            <!-- Створення табу -->
            <button 
                v-if="!editPermFilter"
                type="button" 
                class="btn btn-success" 
                @click="saveTab()"
            >
                {{ $t('Add') }}
            </button>

        </template>
    
    </dialogBox>

</template>

<script>
// import dialogBox from '@/components/modal/modal-lg'
import dialogBox from '@/components/modal/dialog'
import { storeS } from '@/store'

export default {
    props: ["objEditTab", "editPermFilter"],
    components: {
        dialogBox
    },
    data() {
        return {
            form: {
                settingTab: {
                    nameTab: "", // назва табу
                    color: '#1ABC9C', // колір табу
                    checked: true, // статус
                },
                filters: {
                    search: '', // пошук
                    priority: '', // пріоритет
                    status: '', // статус
                    decidedStatus: '', // статус рішення
                    workerId: '', // хто додав 
                    inJobWorkerId: '', // в роботі за ким
                }
            },
            listTypes: [
                { title: this.$t('all'), value: '' },
                { title: this.$t('problem_to_develop'), value: 'problem' },
                { title: this.$t('wish_to_develop'), value: 'wishes' },
            ],
            listValue: [
                { title: this.$t('all'), value: '' },
                { title: this.$t('new'), value: 'new' },
                { title: this.$t('statusInWork'), value: 'jobs' },
                { title: this.$t('statusCompleted'), value: 'decided' },
            ],
            listPriority: [
                { title: this.$t('all'), value: '' },
                { title: this.$t('Critical'), value: '3' },
                { title: this.$t('priorityHigh'), value: '2' },
                { title: this.$t('priorityMedium'), value: '1' },
                { title: this.$t('priorityLow'), value: '0' },
            ],
            decidedStatusList: [
                { title: this.$t('all'), value: '' },
                { title: this.$t('waitTesting'), value: 'waittesting' },
                { title: this.$t('waitRelease'), value: 'waitrelease' },
                { title: this.$t('inRelease'), value: 'in_release' },
                { title: this.$t('noProblem'), value: 'noProblem' },
            ],
            listWorkers: [
                { title: this.$t('all'), value: '' },
            ],
            listDate: [
                {title: this.$t('ForallTime'), value: ''},
                {title: this.$t('Today'), value: 'today'},
                {title: this.$t('Yesterday'), value: 'yesterday'}
            ],
            colors: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#0369a1', title: '0369a1' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#34495E', title: '34495E' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
                { color: '#9B59B6', title: '9B59B6' },
            ],
        }
    },
    created(){
        if(this.editPermFilter == true) {
            this.form = this.objEditTab
        }

        for(var item in this.workers){
            this.listWorkers.push({
                title: this.workers[item].workerName,
                value: this.workers[item].workerId
            })
        }

        // this.randomColor();
    },
    methods: {
        saveTab(){
            // Додаємо або редагуємо таб
            this.$emit("saveTab", this.form)
        },
        darkenColor(color, amount) {
            const colorValue = color.replace('#', '');
            const num = parseInt(colorValue, 16);
            let r = (num >> 16) - amount * 255;
            let g = ((num >> 8) & 0x00ff) - amount * 255;
            let b = (num & 0x0000ff) - amount * 255;
            r = r < 0 ? 0 : r > 255 ? 255 : r;
            g = g < 0 ? 0 : g > 255 ? 255 : g;
            b = b < 0 ? 0 : b > 255 ? 255 : b;
            return `rgb(${r}, ${g}, ${b})`;
        },
    },
    computed: {
        workers(){
            return storeS.workers
        }
    }
}
</script>

<style scoped>
.avatar-title {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.color-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.selected-color .color-block {
  transform: scale(1.2);
  filter: brightness(85%);
  box-shadow: 0px 4px 2px 0px rgb(156 156 156 / 28%);
}

.check-icon {
  position: relative;
  font-size: 14px;
  color: white;
  font-weight: bold;
  z-index: 2;
}
</style>