<!-- дошка -->

<template>
    
    <b-card no-body>
        <b-card-body>
            <b-row class="g-2">

                <!-- Пошук по дошці -->
                <b-col lg="3" class="col-auto">
                    <div class="search-box">
                        <input type="text" class="form-control search" placeholder="Пошук по дошці">
                        <i class="ri-search-line search-icon"></i>
                    </div>
                </b-col>
                
                <!-- Фільтр по співробітникам та активація додаткового доступу -->
                <div class="col-auto ms-sm-auto">
                    <div class="avatar-group" id="newMembar">
                        <!-- Співробітники які мають доступ до проекту -->
                        <template v-for="item in objCard.workers" :key="item">
                            <b-link class="avatar-group-item" v-b-tooltip.hover
                                :title="item.workerName"
                            >
                                <div class="avatar-xs">
                                    <div class="avatar-title bg-info rounded-circle">
                                        <!-- {{ this.getInitials(item.workerName) }} -->
                                        <img
                                            v-if="getImgWorker(item.workerId) != ''"
                                            :src="getImgWorker(item.workerId)" 
                                            :alt="item.workerName" 
                                            class="avatar-xs rounded-circle"
                                        > 
                                        <template v-else>
                                            {{ this.getInitials(item.workerName == "" ? $t('NotDefined') : item.workerName) }}
                                        </template>
                                    </div>
                                </div>
                            </b-link>
                        </template>

                        <!-- Надати доступ до проекту, виклик вікна todo -->
                        <b-link class="avatar-group-item"
                            @click="showModal = true">
                            <div class="avatar-xs">
                                <div class="avatar-title rounded-circle">
                                    +
                                </div>
                            </div>
                        </b-link>

                    </div>
                </div>
            </b-row>
        </b-card-body>
    </b-card>

    <!-- Дошка  -->
    <div class="tasks-board mb-3" id="kanbanboard">

        <!-- Порядок колонок може змінювати лише ПМ -->
        <draggable
            v-model="columns" 
            group="columns" 
            @change="onColumnChange" 
            class="columns-container"
            :disabled="this.user.userid != this.objCard.projectManagerId"
        >
            <!-- Колонки kanban -->
            <template v-for="column in columns" :key="column">

                <div class="tasks-list">

                    <!-- Шапка колонки -->
                    <div class="d-flex mb-2" style="align-items: center">
                        <div class="flex-grow-1">
                            <!-- Назва колонки та кількість тасок -->
                            <h6 class="fs-14 text-uppercase fw-semibold mb-0">
                                {{ column.columnName }} 
                                <b-badge tag="small" variant="success" class="align-bottom ms-1 fs-11" :style="`background-color:${column.color}!important`" >{{ column.tasks.length }}</b-badge>
                            </h6>
                        </div>
                        <div class="flex-shrink-0" v-if="column.columnId > 0">
                            <div class="dropdown">
                                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-12" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-more-2-fill align-bottom"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-end">

                                    <!-- 
                                        Редагувати колонку
                                        - можливо редагувати тільки кастомні колонки
                                        - можливо редагувати тільки пм-у проєкта
                                    -->
                                    <a 
                                        v-if="column.columnId > 0"
                                        class="dropdown-item copy"
                                        :class="{ disabled: !canEditColumn(column) }" 
                                        :style="{ cursor: canEditColumn(column) ? 'pointer' : 'not-allowed' }"
                                        @click="canEditColumn(column) && toEditColumn(column)"
                                    >
                                        {{ $t('toEditAp') }}
                                    </a>

                                    <!-- 
                                        Видалити колонку
                                        - можливо видаляти тільки кастомні колонки
                                        - можливо видаляти тільки пм-у проєкта
                                    -->
                                    <a 
                                        v-if="column.columnId > 0"
                                        class="dropdown-item copy" 
                                        :class="{ disabled: !canDeleteColumn(column) }" 
                                        :style="{ cursor: canDeleteColumn(column) ? 'pointer' : 'not-allowed' }"
                                        @click="canDeleteColumn(column) && toDeleteColumn(column)"
                                    >
                                        {{ $t('Delete') }}
                                    </a>

                                    <span 
                                        v-if="this.user.userid != this.objCard.projectManagerId"
                                        class="dropdown-item text-muted" 
                                    >
                                        {{ $t('PleaseContactPMtoEditBoard') }}
                                    </span>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                        <div class="tasks-wrapper px-3 mx-n3" style="height: auto;overflow: hidden scroll;">
                            <div id="task" :class="`tasks ${column.tasks && column.tasks.length ? '' : 'noTask'}`">
                                
                                <draggable 
                                    :list="column.tasks" 
                                    class="dragArea" 
                                    group="tasks"
                                    @change="event => onTaskChange(event, column)"
                                >
                                    <!-- <b-card no-body class="tasks-box card-animate" v-for="task of column.tasks" :key="index" style="margin-top: 10px;" @click="showTask = true"> -->
                                    <b-card no-body class="tasks-box card-animate" v-for="task of column.tasks" :key="index" style="margin-top: 10px;">
                                        <b-card-body>
                                            <div class="d-flex mb-2">
                                                <h6 class="fs-15 mb-0 flex-grow-1 text-truncate title-task copy" @click="getTask(task)">
                                                    <!-- Назва завдання -->
                                                    <span>{{ task.taskName }}</span>
                                                </h6>
                                                <div class="dropdown">

                                                    <!-- Дії з таскою -->
                                                    <b-link 
                                                        class="text-muted" 
                                                        id="dropdownMenuLink1"
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                    >
                                                        <i class="ri-more-fill"></i>
                                                    </b-link>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1">

                                                        <!-- Відкрити завдання todo -->
                                                        <li>
                                                            <b-link class="dropdown-item">
                                                                <i class="ri-eye-fill align-bottom me-2 text-muted"></i> 
                                                                {{ $t('Open') }}
                                                            </b-link>
                                                        </li>

                                                        <!-- Редагувати завдання todo -->
                                                        <!-- <li>
                                                            <b-link class="dropdown-item">
                                                                <i class="ri-edit-2-line align-bottom me-2 text-muted"></i>
                                                                {{ $t('toEditAp') }}
                                                            </b-link>
                                                        </li> -->

                                                        <!-- Видалити завдання todo -->
                                                        <li v-if="this.user.userid == this.objCard.projectManagerId">
                                                            <b-link class="dropdown-item" @click="toDeleteTask(task)">
                                                                <i class="ri-delete-bin-5-line align-bottom me-2 text-muted"></i>
                                                                {{ $t('Delete') }}
                                                            </b-link>
                                                        </li>

                                                        <!-- <li>
                                                            <span class="dropdown-item" >
                                                                {{ $t('PleaseContactPMtoRemoveTask') }}
                                                            </span>
                                                        </li> -->

                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center">

                                                <!-- Пріоритет завдання -->
                                                <div class="flex-grow-1">
                                                    <span  
                                                        :class="`${mutatepriority(task.priority).color} me-1`"
                                                    >
                                                        {{ mutatepriority(task.priority).name }}
                                                    </span>
                                                </div>

                                                <!-- Співробітники які працюють на завданням -->
                                                <div class="flex-shrink-0" v-if="task.users && task.users.length">
                                                    <div class="avatar-group">
                                                        <b-link  
                                                            v-for="(item, index) of task.users"
                                                            :key="index" 
                                                            class="avatar-group-item" 
                                                            v-b-tooltip.hover
                                                            :title="item.workerName"
                                                        >
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title bg-info rounded-circle">
                                                                    {{ this.getInitials(item.workerName) }}
                                                                </div>
                                                            </div>
                                                        </b-link>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Прогрес-бар -->
                                            <div class="mt-auto pt-3" v-if="task.tasksCount">
                                                <div class="d-flex mb-2">
                                                    <div class="flex-grow-1">
                                                        <div>{{ $t('task') }}</div>
                                                    </div>
                                                    <div class="flex-shrink-0">
                                                        <div>
                                                            <i class="ri-list-check align-bottom me-1 text-muted"></i> {{ task.tasksCount.new }}/{{ task.tasksCount.all }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="progress progress-sm animated-progress">
                                                    <div class="progress-bar bg-success" role="progressbar" :aria-valuenow="task.tasksCount.new" aria-valuemin="0" aria-valuemax="100" :style="`width: ${task.tasksCount.progressPercent}%;`"></div>
                                                </div>
                                            </div>

                                            <!-- Саб-таски -->
                                            <div 
                                                v-if="task.subTasks && task.subTasks.length > 0"
                                                class="hstack gap-2 flex-wrap mt-2 mb-2" 
                                            >
                                                <button type="button" class="btn btn-soft-info" @click="showSubTask = !showSubTask" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="true" aria-controls="collapseExample">
                                                    <span>
                                                        <i class="ri-checkbox-multiple-line align-bottom"></i> 
                                                        {{ getCountNegSubTasks(task.subTasks) }}/{{ task.subTasks.length }} 
                                                        <i :class="`ri-arrow-${showSubTask ? 'down' : 'right'}-s-line align-bottom`"></i>
                                                    </span>
                                                </button>
                                            </div>

                                            <!-- Картки саб-тасок -->
                                            <div 
                                                v-if="task.subTasks.length > 0"    
                                                class="collapse" 
                                                id="collapseExample"
                                            >
                                                <div 
                                                    v-for="subtask in task.subTasks"
                                                    :key="subtask"
                                                    class="card mb-1 card-animate copy subtask"
                                                    @click="getTask(subtask)"
                                                >
                                                    <div class="card-body">

                                                        <div class="d-flex" style="align-items: center;">
                                                            <div class="flex-grow-1" >

                                                                <!-- id саб-таски -->
                                                                <span class="text-muted">#{{ subtask.taskId }}</span>

                                                            </div>

                                                            <div class="flex-shrink-0" >
                                                                <div class="d-flex gap-1 align-items-center" >

                                                                    <!-- Статус сабтаски -->
                                                                    <span class="badge badge-soft-info">{{ this.getColumnKanban(subtask.columnId).name }}</span>

                                                                    <!-- Хто виконує сабтаску -->
                                                                    <!-- <div class="avatar-xs">
                                                                        <div class="avatar-title bg-info rounded-circle">
                                                                            {{ this.getInitials(subtask.worker) }}
                                                                        </div>
                                                                    </div> -->
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </b-card-body>

                                        <!-- Футер картки таски -->
                                        <b-card-footer class="border-top-dashed">
                                            <div class="d-flex">
                                                <div class="flex-grow-1">
                                                    <!-- Id таски -->
                                                    <h6 class="text-muted mb-0 fs-12">#{{ task.taskId }}</h6>
                                                </div>

                                                <!-- Відображення того, що завдання створено по тікету -->
                                                <div v-if="task.ticketId != null" class="flex-shrink-0">
                                                    <b-badge 
                                                        variant="soft-info" 
                                                        class="badge-soft-info me-1"
                                                    >
                                                        {{ $t('byTicket') }}
                                                    </b-badge>
                                                </div>

                                            </div>
                                        </b-card-footer>
                                    </b-card>

                                    <!-- Додати нове замовлення -->
                                    <div class="my-3" v-if="showBlockAddedTask == false && column.columnName != selectBlockAdded.columnName">
                                        <b-button 
                                            variant="soft-info" 
                                            class="w-100" 
                                            @click="showTaskInput(column.columnName, column.columnId)"
                                        >
                                            {{ $t('newTask') }}
                                        </b-button>
                                    </div>
                                    <div v-if="selectBlockAdded.columnName == column.columnName">
                                        <div class="card tasks-box card-animate" style="margin-top: 10px;" >
                                            <div class="card-body" >
                                                <span class="fs-14 text-muted d-block mb-2">{{ $t('WhatdoYouNeedtoEarn') }}</span>
                                                <textarea 
                                                    class="form-control"
                                                    ref="taskInput" 
                                                    @keydown.enter="addTask"
                                                    v-model="newTaskContent"
                                                    @blur="handleBlur"
                                                ></textarea>
                                            </div>
                                            <div class="card-footer border-top-dashed" style="padding: 10px 1rem;">
                                                <div class="d-flex" style="flex-direction: row-reverse;">
                                                    <button 
                                                        type="button"
                                                        class="btn btn-soft-success" 
                                                        data-bs-toggle="button" 
                                                        @click="addTask" 
                                                        style="align-items: center;display: flex;gap: 4px;"
                                                    >
                                                        {{ $t('Add') }} <i class="bx bx-subdirectory-left fs-16"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </draggable>
                            </div>
                        </div>
                </div>

            </template>
        </draggable>

        <div class="tasks-list">
            <div>
                <div class="avatar-xs mb-3 copy" @click="showCreateBoard = true" >
                    <div class="avatar-title bg-success text-white fs-17 rounded" ><i class="ri-add-line" ></i></div>
                </div>
            </div>
        </div>

    </div>

    <!-- Створення колонки -->
    <createBoard 
        v-if="showCreateBoard"
        @close="closeModalBoard()"
        :objCard="objCard"
        :editPerm="editPerm"
        :objItemEditColumn="objItemEditColumn"
    />

    <!-- Перегляд картки завдання -->
    <viewTask 
        v-if="showTask"
        @close="showTask = false;getdata()"
        :objCard="this.form"
        :columnArr="columnArr"
        :projectId="objCard.projectId"
    />

    <!-- 
      Модальне вікно підтвердження видалення колонки
      - йде перевірка showDialogRemove
      - @close - подія закриття модалки
      - @close - подія видалення повідомлення
    -->
    <removeModal
      v-if="showDialogRemove" 
      @close="showDialogRemove = false" 
      @remove="this.typeDelete == 'column' ? this.remove() : this.removeTask()"
    >
    </removeModal>

    <!-- 
       Виклик модального вікна з списком співробітників
       - використовується для надання доступу до типів подій 
       - @invite - збереження прав доступу до типу проєкту
       - :projectWorkers - співробітники які додані до проєкту
    -->
    <employeeAccess
        v-if="showModal"
        @close="showModal = false"
        @saveAccess="saveAccess"
        :objCard="objCard"
        :projectWorkers="objCard.workers"
    />

</template>

<script>

import { VueDraggableNext } from 'vue-draggable-next';
import createBoard from './createBoard.vue'; // Створення колонки на дошку
import viewTask from './viewTask.vue' // Перегляд картки завдання
import removeModal from '@/components/modal/modalremove.vue' // Діалогове вікно підтвердження видалення
import employeeAccess from '../../accessWorkers.vue' // компонент додавання співробітників
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store'; // глобальне сховище даних
import {
        mutateStatusTicket,
        mutatePriorityTicket,
    } from '@/usabilityScripts/globalMutate.js'

let apiServe = new developmentProjects();

export default {
    props: ["objCard"],
    components: {
        draggable: VueDraggableNext,
        createBoard,
        viewTask,
        removeModal,
        employeeAccess
    },
    data() {
        return {
            form: '',
            showModal: false, // прапорець відображення вікна списку співробітників
            showCreateBoard: false, // створення колонки
            showBlockAddedTask: false, // блок створення таски
            showTask: false, // блок перегляду таски
            selectBlockAdded: {
                columnId: '',
                columnName: ''
            }, // активна колонка
            newTaskContent: "", // вміст нового завдання
            columns: [], // колонки
            editPerm: false, // статус редагування колонки
            objItemEditColumn: {}, // об'єкт на видалення або редагування
            showDialogRemove: false, // прапорець відображення вікна видалення колонки
            typeDelete: '', // тип видалення
            objItemTask: {}, // таска для видалення
            columnArr: [], // масив колонок
            columnKanban: [],
            showSubTask: false,
            // columns: [
            //     {
            //     "id": "1",
            //     "name": "До виконання",
            //     "priority": "success",
            //     "color": "#95A5A6", 
            //     "tasks": [
            //     {
            //         "id": "task-101",
            //         "title": "Не додається товар у пос-терміналі",
            //         "description": "Якийсь опис",
            //         "priority": "high",
            //         "ticket": "3145",
            //         "tasks": [
            //             {
            //                 "id": "task-201",
            //                 "title": "Не додається товар у пос-терміналі репорт 1",
            //                 "description": "",
            //                 "priority": "high",
            //                 "ticket": "3145",
            //                 "status": "До виконання",
            //                 "worker": "Maxim"
            //             },
            //             {
            //                 "id": "task-202",
            //                 "title": "Не додається товар у пос-терміналі репорт 2",
            //                 "description": "",
            //                 "priority": "high",
            //                 "ticket": "3145",
            //                 "status": "Тестується",
            //                 "worker": "Maxim"
            //             }
            //         ],
            //         "users": [
            //         ],
            //         "key": "101"
            //     },
            //     {
            //         "id": "task-102",
            //         "title": "Не коректні відображення в розділі Клієнти(ios)",
            //         "description": "Скласти прототип для нової сторінки сайту",
            //         "priority": "medium",
            //         "users": [
            //             {
            //                 "workerId": 2,
            //                 "workerName": "Даня"
            //             }
            //         ],
            //         "key": "102"
            //     }
            //     ]
            // },
            // {
            //     "id": "2",
            //     "name": "В роботі",
            //     "priority": "secondary",
            //     "color": "#3498DB",
            //     "tasks": [
            //     {
            //         "id": "task-201",
            //         "title": "Змінити дизайн обраної бонусної програми або знижки",
            //         "description": "Реалізувати ендпоінти для обробки даних",
            //         "priority": "high",
            //         "users": [
                    
            //         ],
            //         "key": "201",
            //         "tasksCount": {
            //         "new": 90,
            //         "all": 100,
            //         "progressPercent": 90
            //         }
            //     },
            //     {
            //         "id": "task-202",
            //         "title": "Не відцентрована кнопка сортування при виборі програми лояльності",
            //         "description": "Реалізувати ендпоінти для обробки даних",
            //         "priority": "high",
            //         "users": [
                    
            //         ],
            //         "key": "201"
            //     }
            //     ]
            // },
            // {
            //     "id": "3",
            //     "name": "Тестування",
            //     "priority": "warning",
            //     "color": "#F39C12",
            //     "tasks": [
            //     {
            //         "id": "task-301",
            //         "title": "Додати можливість обирати групу клієнтів для окремо обраних клієнтів через меню Дія",
            //         "description": "Завершити перевірку продукту перед релізом",
            //         "priority": "low",
            //         "users": [
                    
            //         ],
            //         "key": "401"
            //     }
            //     ]
            // },
            // {
            //     "id": "4",
            //     "name": "В передрелізі",
            //     "priority": "info",
            //     "color": "#F39C12",
            //     "tasks": [
            //     ]
            // },
            // {
            //     "id": "5",
            //     "name": "Завершено",
            //     "priority": "success",
            //     "color": "#1ABC9C",
            //     "tasks": [
            //         {
            //             "id": "task-501",
            //             "title": "Додати поле Групи клієнтів в розділ Клієнти",
            //             "description": "Виконано успішний реліз продукту",
            //             "priority": "done",
            //             "users": [],
            //             "key": "501"
            //         }
            //     ]
            // }
            // ]
        }       
    },
    created(){
        this.getdata();

        this.columnKanban = this.objCard.columns
    },
    methods: {
        getdata(){
            // Отримання даних kanban

            apiServe.getProjectBoard(this.objCard.projectId).then(result => {
                if(result.status == 'done') {
                    this.columns = result.data.columns
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        showTaskInput(columnName, columnId) {
            // Встановлення фокусу на поле вводу при створені завдання
            
            this.selectBlockAdded.columnName = columnName;``
            this.selectBlockAdded.columnId = columnId;``
            this.$nextTick(() => {
                const input = this.$refs.taskInput;
                input[0].focus();
            });
        },
        getTask(item){
            // відкриття таски

            apiServe.getTask(item.taskId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    this.columnArr = this.transformColumns(this.columns);
                    this.showTask = true
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        transformColumns(columns){
            return columns.map(column => ({
                label: column.columnName,  // Назва колонки
                value: column.columnId,    // ID колонки
                color: column.color        // Колір
            }));
        },
        getCountNegSubTasks(subTasks){
            // Рахуємо кількість завершених сабтасок

            const columnIds = this.objCard.columns.map(task => task.columnId);

            const minColumnId = Math.min(...columnIds);

            const count = subTasks.filter(task => task.columnId === minColumnId).length;

            return count
        },
        addTask() {
            // Додавання завдання
            console.log("this.", this);
            

            var newForm = {
                taskName: this.newTaskContent,
                priority: 0,
                description: "",
                ticketId: null,
                columnId: this.selectBlockAdded.columnId,
                parentCategoryId: null,
                sort: 0,
                deadlineDatetime: "",
                projectId: this.objCard.projectId
            }

            apiServe.addTask(this.selectBlockAdded.columnId, newForm).then(result => {
                if(result.status == 'done') {
                    this.newTaskContent = "";
                    this.selectBlockAdded.columnName = null; // Закриваємо поле після додавання
                    this.$toast.success(this.$t('Added'))
                    this.getdata()
                } else {
                    this.$toast.error(result.error[0])
                }
            })

            // console.log("Завдання додано");
            // this.newTaskContent = "";
            // this.selectBlockAdded = null; // Закриваємо поле після додавання
            // this.$toast.success("Завдання додано")
        },
        closeTaskBox() {
            // Закриваємо блок
            this.newTaskContent = "";
            this.selectBlockAdded.columnName = null;
        },
        onTaskChange(event, column) {
            console.log("event >", event);
            console.log("event >", event.added ? 1:2);
            console.log("column.", column);

            if(event.moved) {

                const updateTasks = [...column.tasks]
                console.log("updateTasks", updateTasks);
                
                updateTasks.forEach((task, index) => {
                    task.sort = index;
                })

                const sortTasksIds = updateTasks
                    .sort((a, b) => a.sort - b.sort)
                    .map(task => task.taskId)

                const requestBody = {
                    sortTasksIds
                }

                // Відправляємо запит на сервер
                apiServe.sortTasks(this.objCard.projectId, column.columnId, requestBody).then(result => {
                    if (result.status === 'done') {
                        // this.getdata();
                        this.columns = result.data.columns
                    } else {
                        this.$toast.error(result.error[0]);
                    }
                });

            } else if(event.added) {

                var requestBody = {
                    columnId: column.columnId,
                    taskId: event.added.element.taskId,
                    sort: event.added.newIndex
                }

                // Відправляємо запит на сервер
                apiServe.moveTask(this.objCard.projectId, requestBody).then(result => {
                    if (result.status === 'done') {
                        // this.getdata();
                        this.columns = result.data.columns
                    } else {
                        this.switchError(result.error[0])
                    }
                });

            }

        },
        switchError(error){
            // відображення помилки

            switch (error) {

                case "task data not found":
                    this.$toast.error(this.$t('Переданної задачі немає'))
                    break;
                case "data not found":
                    this.$toast.error(this.$t('Не передані обов\'язкові ключи'))
                    break;

                default:
                    this.$toast.error(error);
                    break;
            }
        },
        onColumnChange(event) {
            
            // копія масиву колонок
            const updatedColumns = [...this.columns];

            updatedColumns.forEach((column, index) => {
                column.sort = index;
            });

            // Сортуємо за полем `sort` і формуємо масив ID
            const sortColumnsIds = updatedColumns
                .sort((a, b) => a.sort - b.sort)
                .map(column => column.columnId);

            const requestBody = {
                sortColumnsIds
            };

            // Відправляємо запит на сервер
            apiServe.sortColumns(this.objCard.projectId, requestBody).then(result => {
                if (result.status === 'done') {
                    // this.getdata();
                    this.columns = result.data.columns
                } else {
                    this.$toast.error(result.error[0]);
                }
            });
            
        },
        // Чи можна редагувати колонку
        canEditColumn(column) {
            // Дозволено тільки для кастомних колонок і для PM проєкту
            return column.columnId > 0 && this.user.userid == this.objCard.projectManagerId
        },
        // Чи можна видалити колонку
        canDeleteColumn(column) {
            // Дозволено тільки для кастомних колонок і для PM проєкту
            return column.columnId > 0 && this.user.userid == this.objCard.projectManagerId
        },
        toEditColumn(item){
            this.objItemEditColumn = item
            this.editPerm = true
            this.showCreateBoard = true
        },
        toDeleteColumn(item){
            this.objItemEditColumn = item;
            this.typeDelete = 'column'
            this.showDialogRemove = true
        },
        remove(){
            // Видаляємо колонку

            apiServe.deleteColumn(this.objItemEditColumn.columnId).then(result => {
                if(result.status == 'done') {
                    this.closeModalBoard()
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        toDeleteTask(item){
            // Видалення таски (вікно підтвердження)
            
            this.objItemTask = item;
            this.typeDelete = 'task';
            this.showDialogRemove = true
        },
        removeTask(){
            // Видаляємо таску

            apiServe.deleteTask(this.objItemTask.taskId).then(result => {
                if(result.status == 'done') {
                    this.objItemTask = {};
                    this.typeDelete = "";
                    this.showDialogRemove = false;
                    this.getdata();
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        closeModalBoard(){
            // Закриття модалки додавання/редагування колонки
            this.objItemEditColumn = {};
            this.editPerm = false
            this.showCreateBoard = false
            this.showDialogRemove = false
            this.getdata()
        },
        addNewBoard(item){
            // Створення колонки 
            // console.log("addNewBoard", item);

            var newBoard = {
                id: 6,
                name: item.title,
                priority: "info",
                color: item.color,
                tasks: []
            }
            
            this.columns.push(newBoard)
            this.showCreateBoard = false;

        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        handleBlur(event) {
            // Перевірка, чи текстове поле порожнє
            if (!this.newTaskContent.trim()) {
                this.closeTaskBox(); // Закриваємо блок, якщо він порожній
            } else {
                this.addTask(); // Додаємо завдання
            }
        },
        getImgWorker(workerId){
            // отримання зображення співробітника
            var avatar = ""
            this.workers.forEach(element => {
                if(element.workerId == workerId){
                    avatar = element.avatar;
                }
            });
            
            return avatar
        },
        getColumnKanban(columnId){
            // отримання назви та кольору статуса таски
            var name = "";
            var color = "";

            this.columnKanban.forEach(element => {
                if(element.columnId == columnId){
                    name = element.columnName;
                    color = element.color
                } 
            });

            // Якщо колонка не знайдена
            if (!name && !color) {
                name = this.$t('NotDefined');
                color = "#878a99"
            }

            return { name, color }
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        workers(){
            // отримуємо список співробітників
            return storeS.workers
        },
    }
}
</script>

<style scoped>
.tasks-box {
    width: 300px;
}
.title-task {
    white-space: normal;
}
.dragArea {
    height: 58vh;
    margin-bottom: 50px;
}
.subtask:hover {
    background: #e1edf55c;
}
/* Додаткові стилі для drag-and-drop */
.columns-container {
  display: flex;
  gap: 16px;
}
.tasks-list {
  width: 300px;
}
.dragArea {
  min-height: 100px;
}
.dropdown-item.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>