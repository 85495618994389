<template>

    
    <modal 
        :title="$t('invoic')" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen" 
        :key="componentKey"
        :stylebody="'var(--vz-body-bg)'" 
        @close="this.$emit('close')"
    >
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <div class="row justify-content-center">
            <div class="col-xxl-9">
                <div class="card" id="demo">
                    <div class="card-header border-bottom-dashed p-4">
                        <div class="d-sm-flex">
                            <div class="flex-grow-1">
                                    <img src="https://skyservice.pro/templates/img/logo.svg" class="card-logo card-logo-dark" alt="logo dark" style="width: 200px;">
                                    <img src="https://skyservice.pro/templates/img/logo.svg" class="card-logo card-logo-light" alt="logo light" style="width: 200px;">
                            </div>
                        </div>
                    </div><!--end card-header-->
                    <div class="card-body p-4" >
                        <div class="row g-3">
                            <div class="col-lg-2 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">{{ $t('invoic') }}</p>
                                <h5 class="fs-14 mb-0" v-if="form.date >= '2023-11-28'"># {{ form.num.id }}</h5>
                                <h5 class="fs-14 mb-0" v-else># {{ this.form.provider == 'TOV' ? this.form.numTOV.id : this.form.numFOP.id }}</h5>
                            </div><!--end col-->
                            <div class="col-lg-2 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">{{ $t('date') }}</p>
                                <h5 class="fs-14 mb-0">{{ this.form.datetime }}</h5>
                            </div><!--end col-->
                            <div class="col-lg-2 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">{{ $t('clients_number') }}</p>
                                <span :class="'fs-12 fw-semibold'">{{ form.sellerPhone }}</span>
                            </div>
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">{{ $t('PaymentStatus') }}</p>
                                <span :class="'badge ' + this.mutatestatus(this.form.payment.status).color + ' fs-11'">{{this.mutatestatus(this.form.payment.status).name}}</span>
                            </div>
                            <!--end col-->
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">{{ $t('Total') }}</p>
                                <h5 class="fs-14 mb-0">₴ {{ this.form.sum.toFixed(2) }}</h5>
                            </div><!--end col-->
                        </div><!--end row-->
                    </div><!--end card-body-->
                    <div class="card-body p-4 border-top border-top-dashed">
                        <b-row>
                            <b-col lg="2">
                                <h4 class="fs-15">{{ $t('Provider') }}</h4>
                            </b-col>
                            <b-col lg="10">
                                <template v-if="form.date >= '2023-11-28'">
                                    {{ provider.businessEntity }} {{ provider.workWithPDV }} {{ (provider.businessEntity == 'FOP' || provider.businessEntity == 'TOV') && provider.workWithPDV == "false" }}
                                    <label v-if="provider.businessEntity == 'TOV' && (provider.workWithPDV === true || provider.workWithPDV === 'true')">
                                        <b>{{ provider.providerTitle }}</b> 
                                        <br />IBAN: {{ provider.iban }} в {{ provider.bank }}, {{ provider.address }} 
                                        <br />Тел. {{ provider.phone }} 
                                        <br />Код ЄДРПОУ {{ provider.erdpu }}, ІПН {{ provider.ipn }} 
                                    </label>
                                    <label v-if="(provider.businessEntity == 'FOP' || provider.businessEntity == 'TOV') && (provider.workWithPDV == false || provider.workWithPDV == 'false')">
                                        <b>{{ provider.providerTitle }}</b> 
                                        <br />Р/р {{ provider.iban }}, {{ provider.bank }}, МФО {{ provider.mfo }} код по ЄРДПОУ {{ provider.erdpu }}
                                    </label>
                                </template>
                                <template v-else>
                                    <label v-if="form.provider == 'TOV' || form.provider == 'tov'">
                                        <b>{{ settingInv.TOV.provider }}</b> 
                                        <br />IBAN: {{ settingInv.TOV.iban }} в {{ settingInv.TOV.bank }}, {{ settingInv.TOV.address }} 
                                        <br />Тел. {{ settingInv.TOV.phone }} 
                                        <br />Код ЄДРПОУ {{ settingInv.TOV.erdpu }}, ІПН {{ settingInv.TOV.ipn }} 
                                    </label>
                                    <label v-if="form.provider == 'FOP' || form.provider == 'fop' ">
                                        <b>{{ settingInv.FOP.provider }}</b> 
                                        <br />Р/р {{ settingInv.FOP.calculatedWallet }}, {{ settingInv.FOP.bank }}, МФО {{ settingInv.FOP.mfi }} код по ЄРДПОУ {{ settingInv.FOP.erdpu }}
                                    </label>
                                </template>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col lg="2">
                                <h4 class="fs-15">{{ $t('Buyer') }}</h4>
                            </b-col>
                            <b-col lg="10">
                                <label >
                                    <b>{{form.sellerName}} </b> 
                                    <br /><span v-if="form.descbuyer != ''">{{form.economicalSociety}}</span>
                                </label>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col lg="2">
                                <h4 class="fs-15">{{ $t('Treaty') }}</h4>
                            </b-col>
                            <b-col lg="10">
                                <label >
                                    {{$t('invoic')}}
                                    <b></b> 
                                </label>
                            </b-col>
                        </b-row>
                    </div><!--end card-body-->
                    <div class="card-body p-4">
                        <div class="table-responsive">
                            <table class="table table-borderless text-center table-nowrap align-middle mb-0">
                                <thead>
                                    <tr class="table-active">
                                        <th scope="col" style="width: 50px;">№</th>
                                        <th scope="col">{{ $t('Goods') }}</th>
                                        <th scope="col">{{ $t('Quantity') }}</th>
                                        <th scope="col">{{ $t('unit') }}</th>
                                        
                                        <template v-if="form.date >= '2023-11-28'">
                                            <th scope="col">{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ? 'Ціна без ПДВ' : 'Ціна' }}</th>
                                            <th scope="col">{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ? 'Сума без ПДВ' : 'Сума'}}</th>
                                            <th scope="col" v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">Сума ПДВ:</th>
                                        </template>
                                        <template v-else>
                                            <th scope="col">{{ form.provider == 'TOV' ? $t('PriceWithoutVAT') : $t('priceprod')  }}</th>
                                            <th scope="col">{{ form.provider == 'TOV' ? $t('AmountWithoutVAT') : $t('Sum') }}</th>
                                            <th scope="col" v-if="form.provider == 'TOV'" class="text-end" >{{ $t('VATamount') }}</th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in this.form.products" :key="index">
                                        <th scope="row">{{index+1}}</th>
                                        <td class="text-start">
                                            <span class="fw-medium">{{item.nomenclatureName}}</span>
                                        </td>
                                        <td>{{item.quantity}}</td>
                                        <td>шт.</td>
                                        <td>{{String((item.priceWithoutVAT).toFixed(2))}}</td>
                                        <td>{{ String((item.priceWithoutVAT * item.quantity).toFixed(2)) }}</td>
                                       
                                        <template v-if="form.date >= '2023-11-28'">
                                            <td class="text-end" v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">{{ item.VATsum }}</td>
                                        </template>
                                        <template v-else>
                                            <td class="text-end" v-if="form.provider == 'TOV'">{{ item.VATsum }}</td>
                                        </template>
                                    </tr>
                                    
                                    <tr class="border-top border-top-dashed mt-2">
                                        <td colspan="5"></td>
                                        <td colspan="2" class="fw-medium p-0">
                                            <table class="table table-borderless text-start table-nowrap align-middle mb-0">
                                                <tbody>

                                                    <template v-if="form.date >= '2023-11-28'">
                                                        <tr v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                                            <td><b>{{ $t('TotalwithoutVAT') }}</b></td>
                                                            <td class="text-end">{{String((Number(form.sum)).toFixed(2))}}</td>
                                                        </tr>
                                                        <tr v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'">
                                                            <td><b>{{ $t('TotalVat') }}</b></td>
                                                            <td class="text-end">{{String((Number(form.sum) * 0.2).toFixed(2))}}</td>
                                                        </tr>
                                                    </template>
                                                    <template v-else>
                                                        <tr v-if="form.provider == 'TOV'">
                                                            <td><b>{{ $t('TotalwithoutVAT') }}</b></td>
                                                            <td class="text-end">{{String((Number(form.sum)).toFixed(2))}}</td>
                                                        </tr>
                                                        <tr v-if="form.provider == 'TOV'">
                                                            <td><b>{{ $t('TotalVat') }}</b></td>
                                                            <td class="text-end">{{String((Number(form.sum) * 0.2).toFixed(2))}}</td>
                                                        </tr>
                                                    </template>

                                                    <!-- <tr v-if="form.provider == 'TOV'">
                                                        <td><b>{{ $t('TotalwithoutVAT') }}</b></td>
                                                        <td class="text-end">{{(Number(form.sum)).toFixed(2)}}</td>
                                                    </tr> -->
                                                    <!-- <tr v-if="form.provider == 'TOV'">
                                                        <td><b>{{ $t('TotalVat') }}</b></td>
                                                        <td class="text-end">{{(Number(form.sum) * 0.2).toFixed(2)}}</td>
                                                    </tr> -->
                                                    <!-- <tr>
                                                        <td><b>{{ form.provider == 'TOV' ? $t('TotalIncludingVAT') : $t('Total')  }}</b></td>
                                                        <td v-if="form.provider == 'TOV'" class="text-end">{{(form.sum+form.sum*0.2).toFixed(2)}}</td>
                                                        <td v-else class="text-end">{{(form.sum).toFixed(2)}}</td>
                                                    </tr> -->
                                                    <template v-if="form.date >= '2023-11-28'">
                                                        <td><b>{{ provider.businessEntity == 'TOV' && provider.workWithPDV == 'true' ? $t('TotalIncludingVAT') : $t('Total')  }}</b></td>
                                                        <td v-if="provider.businessEntity == 'TOV' && provider.workWithPDV == 'true'" class="text-end">{{String((form.sum+form.sum*0.2).toFixed(2))}}</td>
                                                        <td v-else class="text-end">{{(form.sum).toFixed(2)}}</td>
                                                    </template>
                                                    <template v-else>
                                                        <td><b>{{ form.provider == 'TOV' ? $t('TotalIncludingVAT') : $t('Total')  }}</b></td>
                                                        <td v-if="form.provider == 'TOV'" class="text-end">{{String((form.sum+form.sum*0.2).toFixed(2))}}</td>
                                                        <td v-else class="text-end">{{String((form.sum).toFixed(2))}}</td>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="hstack gap-2 justify-content-start d-print-none mt-4">
                            <h5 style="font-weight: 700;">{{ this.$t('Comment') }}: <span style="font-weight: initial;">{{ this.form.comment }}</span></h5>
                        </div>
                        
                    </div>

                    <!-- Коментар до платежу, коментар підтвердження або анулювання -->
                    <div class="p-4 border-top border-top-dashed" v-if="(form.status == 'confirmed' && form.confirmedComment != '' || form.status == 'canceled' && form.cancelComment != '') && form.status != 'new'">
                        <h5 class="fw-semibold text-muted">{{ $t('CommentThePayment') }}</h5> 
                        <span>{{ (form.status == 'confirmed' || form.status == 'canceled') && form.status != 'new' ? this.form.confirmedComment : this.form.cancelComment }}</span>
                    </div>
                </div>
            </div>
        </div>
        <toPrint :obj="this.form"/>
        <toExInvPrint :obj="this.form" />

        </template>
        <template v-slot:footer-bottom>
            <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="exportToPDF"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </a>
            <button 
                class="btn btn-danger" 
                @click="dialogShow = true"
            >
                {{ $t('Delete')}}
            </button>
            <button 
            class="btn btn-warning" 
            v-if="
                form.payment.status == 'pendingpay' &&
                this.perms[1702]==true
            " 
            @click="this.$emit('edit', this.form)"
            >
                <i class="ri-pencil-fill align-bottom me-1"></i> {{$t('Edit')}}
            </button>
            <button 
                class="btn btn-warning" 
                @click="printInvoice()">
                    <i class=" bx bxs-file-pdf me-2 fs-16"></i> {{$t('DownloadAccount')}}
            </button>
            <button 
                class="btn btn-warning" 
                v-if="form.payment.status == 'paid'" 
                @click="printExpenInv()"
            >
                <i class=" bx bxs-file-pdf me-2 fs-16"></i>{{$t('DownloadtheInvoice')}} 
            </button>
            <button 
                type="button" 
                @click="showAnnulBox = true" 
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('cancel') }}
            </button>
            <button 
                type="button"
                v-if="checkButtonVisible()"
                @click="showConfirmBox = true"
                :class="`btn btn-success ss waves-effect waves-light`">
                {{ $t('Confirm') }}
            </button>
            <button 
                type="button"
                v-if="form.payment.status == 'annul'"
                @click="Ах(form)" key=""
                :class="`btn btn-primary ss waves-effect waves-light`">
                {{ $t('Copy') }}
            </button>
            <!-- <a class="btn btn-primary"><i class="ri-download-2-line align-bottom me-1"></i> {{$t('Download')}}</a> -->

            <!-- <button @click="exportToPDFNew">Експорт в PDF</button> -->
       </template>
    </modal>
    
    <editmodal v-if="editmodal == true" @close="editmodal = false" :editform="this.form" />

    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="remove" />

    <!-- Модальне вікно підтвердження рахунку -->
    <confirmBox 
        v-if="showConfirmBox"
        @close="closeConfirmBlock"
        :obj="this.form"
    />

    <!-- Модальне вікно анулювання рахунку -->
    <annulBox 
        v-if="showAnnulBox"
        @close="showAnnulBox = false;this.updateCard()"
        :obj="this.form"
    />

</template>

<script>

// import animationData from "@/components/widgets/kbtmbyzy.json";
import toPrint from './toPrint'
import toExInvPrint from './toExpendInv'
import modal from '@/components/modal'
import editmodal from '@/views/invoices/createinvoices'
import modalremove from '@/components/modal/modalremove'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import confirmBox from './actions/confirm.vue' // Модальне вікно підтвердження рахунку
import annulBox from './actions/annul.vue' // Модальне вікно скасування рахунку
import { Invoices } from '@/API.js';
import { storeS } from '@/store.js'
import { mutateStatusInv } from '@/usabilityScripts/globalMutate.js'
import html2pdf from "html2pdf.js";
import { jsPDF } from 'jspdf';

let apiServe = new Invoices();

export default {
    data() {
        return {   
            data: "",
            print_check: false,
            editmodal: false,
            form: {
                status:"",
            },
            printInv: false,
            printSalInv: false,
            status: [
                { value: 'pendingpay', label: this.$t('pendingpay') },
                { value: 'paidpay', label: this.$t('paidpay') },
                { value: 'annul', label: this.$t('annul') },
            ],
            provider: "",
            dialogShow: false,
            showConfirmBox: false,
            showAnnulBox: false,
            componentKey: ''
        };
    },
    props: ["obj", 'ifPrevopen', 'ifNextopen'],
    components: {
        modal,
        Multiselect,
        toPrint,
        toExInvPrint,
        editmodal,
        modalremove,
        confirmBox,
        annulBox
    },
    created() {
        this.getData();
        this.getDataProvider()
       console.log("provider - > ", this)
    },
    methods: {
        checkButtonVisible() {
            // Перевірка статусу форми та дозволу користувача на редагування
            return (this.form.payment.status === "pendingpay") || (this.form.payment.status!== "pendingpay" && this.perms[2000] === true);
        },
        closeConfirmBlock(){
            this.showConfirmBox = false;
            this.updateCard();
            this.$emit('getdata')
        },
        getData(){
            this.form = this.obj;
            var textitem = 'Поповнення балансу за номером акаунту - ' + this.obj.sellerPhone + " для користування програмою обліку SkyService POS ( Оренда ПЗ )" 
            for(var item in this.form.products){
                if(this.form.products[item].nomenclatureId == 211){
                    this.form.products[item].nomenclatureName = textitem;
                }
            }
        },
        closeModal(){
            this.$emit('close');
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.documentId)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.documentId)
        },
        remove: function(e){
            this.form.status = "Removed"
            this.$emit("remove", e, this.form);
        },
        open(e){
            this.info_report = e;
            this.showModalReport = true
        },
        // toDone(){
        //     apiServe.confirmSale(this.form.documentId).then(result =>{
        //         if(result.status == 'done'){
        //             this.$toast.success(this.$t('alertChanged'));
        //             this.eventBus.emit('saveINV', true)
        //             this.$emit('close')
        //         } else if(result.status == 'error'){
        //             this.$toast.error(this.$t('saleHasAlreadyBeenConfirmed'));
        //         }
        //     })
        // },
        // toAnnul(){
        //     apiServe.cancelSale(this.form.documentId).then(result =>{
        //         if(result.status == 'done'){
        //             // this.$toast.success(this.$t('alertChanged'));
        //             this.eventBus.emit('saveINV', true)
        //             this.$emit('close')
        //         } else {
        //             this.$toast.error(this.$t('error'));
        //         }
        //     })
        // },
        toCopy(e){
            e.status = 'pendingpay'
            e.created_date_time = this.currentDate()
            e.id = Math.floor(Math.random() * (9999 - 10)) + 10;
            var date = new Date();
            e.num = date.getFullYear()+"_"+ e.id;
            ("e >> ", e)
            apiServe.addSale(e).then(res => {
                if(res){
                    this.$toast.success(this.$t('Copy'));
                    this.$emit('close')
                    this.eventBus.emit('saveINV', true)
                }
            })
        },
        mutatestatus(e){
            return mutateStatusInv(e)
        },
        statusprintInv(){
            this.printInv = true
        },
        printInvoice(){
            // Беремо наповнення HTML з блоку 
            const htmlContent = document.querySelector('#print').innerHTML;
            // Відкриваємо вікно
            const printWindow = window.open('', '');
            printWindow.document.write(htmlContent); // вкидуємо весртку
       
            if(this.form.date >= "2023-11-28"){ // перевіряємо дату рахунку, змінюємо назву
                printWindow.document.title  = 'Рахунок № ' + this.form.num.id + " " + this.toDate();
            } else {
                printWindow.document.title  = 'Рахунок № ' + (this.form.provider == 'TOV' ? this.form.numTOV.id : this.form.numFOP.id) + " " + this.toDate();
            }

            // Викликаємо стандартну функцію браузера на роздруківку
            printWindow.print();

            // закриваємо після вибору
            printWindow.close()

            /*

            storeS.preloaderTable = true
            const prtHtml = document.getElementById('print');
            // let stylesHtml = '';
            // for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            //     stylesHtml += node.outerHTML;
            // }
            // Open the print window
            // const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            // WinPrint.document.write("<html><head>"+stylesHtml+"</head><body style='margin:35px'>"+ prtHtml +"</body></html>");
            // WinPrint.document.close();
            if(this.form.date >= "2023-11-28"){
                var name = 'Рахунок № ' + this.form.num.id + " " + this.toDate();
            } else {
                var name = 'Рахунок № ' + (this.form.provider == 'TOV' ? this.form.numTOV.id : this.form.numFOP.id) + " " + this.toDate();
            }
            
            // html2pdf(prtHtml, {
			// 	margin: 10,
            //     html2canvas: {
            //         dpi: 192
            //     },
  			//     filename: name+".pdf",
			// });

            var options = {
                margin: 10,
                // image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                filename: name+".pdf",
            }
            html2pdf().from(prtHtml).set(options).outputPdf().then(function() {
                storeS.preloaderTable = false
            }).save();
            // console.log('html', html2pdf);
            // WinPrint.focus();
            // WinPrint.print();
            // WinPrint.close();
            
            */
            
        },
        printExpenInv(){
            // Беремо наповнення HTML з блоку 
            const prtHtml = document.getElementById('printSalInv').innerHTML;
            // Відкриваємо вікно
            const printWindow = window.open('', '');
            printWindow.document.write(prtHtml);// вкидуємо весртку

            if(this.form.date >= "2023-11-28"){// перевіряємо дату рахунку, змінюємо назву
                printWindow.document.title = 'Видаткова накладна № ' + this.form.num.id + " " + this.toDate();
            } else {
                printWindow.document.title = 'Видаткова накладна № ' + (this.form.provider == 'TOV' ? this.form.numTOV.id : this.form.numFOP.id) + " " + this.toDate();
            }

            // Викликаємо стандартну функцію браузера на роздруківку
            printWindow.print();

            // закриваємо після вибору
            printWindow.close()
            
            // let stylesHtml = '';
            // for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            //     stylesHtml += node.outerHTML;
            // }
            // // Open the print window
            // const WinPrint = document.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            // WinPrint.document.write("<!DOCTYPE html><html><head>"+stylesHtml+"</head><body style='margin:35px'>"+prtHtml+"</body></html>");
            // WinPrint.document.close();
            // WinPrint.focus();
            // WinPrint.print();
            // WinPrint.close();
            // var options = {
            //     margin: 10,
            //     filename: name+".pdf",
            // }
            // html2pdf().from(prtHtml).set(options).outputPdf().then(function() {
            //     storeS.preloaderTable = false
            // }).save();
        },
        toDate(){
            var newFullDate = new Date(this.form.datetime);
            var newYear = newFullDate.getFullYear();
            var newMonth = newFullDate.getMonth();
            var newDate = newFullDate.getDate();
            var result = "від " + newDate + " " + this.getdata(newMonth) + " " + newYear + " року";
            return result
        },
        getdata(m){
            var arr=[
            'січня',
            'лютого',
            'березня',
            'квітня',
            'травня',
            'червня',
            'липня',
            'серпня',
            'вересня',
            'жовтня',
            'листопада',
            'грудня',
            ];
            return arr[m]
        },
        getDataProvider(){
            this.$nextTick(() => {
                this.providers.forEach(element => {
                    if (element.providerId == this.form.providerId) {
                        this.provider = element
                        console.log(this.provider)
                    }
                });
            });
        },
        exportToPDFNew() {
            const content = this.$refs.testHtml;
            const content1 = this.$refs.contentToExport;
            const prtHtml = document.getElementById('print');
            console.log('content', prtHtml);
            console.log('content1', content1);
            // const content = document.getElementById('print').innerHTML;
            // Створюємо новий об'єкт jsPDF
            const doc = new jsPDF();

            // Отримуємо HTML-елемент для експорту
            // const content = this.$refs.testHtml;
            // doc.addFont("~@/assets/fonts/hkgrotesk-regular.woff", "hkgrotesk", "regular");
            // doc.setFont("hkgrotesk");
            // Додаємо HTML-вміст до документа
            doc.html(prtHtml, {
                callback: function (doc) {
                        
                    // Зберігаємо PDF-файл з іменем 'invoice.pdf'
                    doc.save('invoice.pdf');
                },
            });
        },
        updateCard(){
            // Використовується для оновлення картки рахунку

            apiServe.getInv(this.form.documentId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    this.componentKey += 1
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
    },
    mounted() {
        // this.eventBus.on('saveReport', (status) => {
        //     if(status == true) {
        //         this.getReports();
        //     }
        // })
        // this.eventBus.off('updateInvoice');
        // this.eventBus.on('updateInvoice', (status) => {
        //     if(status == true) {
        //         this.updateCard();
        //     }
        // })
    },
    computed:{
        perms(){
            return storeS.perms
        },
        user(){
            return storeS.userbase
        },
        settingInv(){
            return storeS.invoice
        },
        currencyShop(){
            return storeS.currencyShop
        },
        providers() {
            return storeS.providers
        }
    }
}
</script>

<style>
@media print {
  body {
    margin: 0;
    color: #000;
  }

  img {
    max-width: 100%;
  }
}
</style>
