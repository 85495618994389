<template>
    <!-- 
        Модальне вікно відображення картки співробітника 
    -->

    <!-- 
        Виклик компоненту модального вікна
        - :key - ключ для перерендерингу модального вікна при зміні даних
        - :title - заголовок модального вікна
        - :ifPrevopen - стан кнопки "<" 
        - :ifNextopen - стан кнопки ">"
        - @prevOpen - подія відкриття попереднього співробітника
        - @nextOpen - подія відкриття наступного співробітника
        - :stylebody - стиль фону тіла модального вікна
        - @close - подія закриття модального вікна
    -->
    <modal 
        :key="componentKey" 
        :title="$t('Employee')" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen" 
        :stylebody="'background: var(--vz-body-bg)'" 
        @close="this.$emit('close')"
    >
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <!--
                Верхній блок модального вікна
                - @close - подія закриття модального вікна
                - @openfinebox - подія відкриття вікна штрафу
                - @openvacationbox - подія відкриття вікна відпустки
                - :obj - параметр-об'єкт з даними про співробітника
            -->
            <headblock 
                @close="close" 
                @openfinebox="openfinebox" 
                @openvacationbox="openvacationbox" 
                :obj="this.form"  
            />
        </template>
        <!--
            Слот тіло модального вікна з лівим та правим блоками
            - <leftblock> - компонент для відображення та редагування лівого блоку (особисті дані)
                - :obj - об'єкт з даними про співробітника
                - :statusEdit - статус можливості редагування
                - @editUser - подія редагування даних про співробітника
            - <rightblock> - компонент для відображення та редагування правого блоку (проекти, мітки та інше)
                - :obj - об'єкт з даними про співробітника
                - @openprojectbox - подія відкриття вікна проекту
                - @openlabelbox - подія відкриття вікна мітки
                - @editUser - подія редагування даних про співробітника
                - @jobDesc - подія відкриття вікна опису посади
                - @toConf - подія відкриття вікна конфіденційності
                - @toShift - подія відкриття вікна зміни
                - @openfinebox - подія відкриття вікна штрафіу
                - @openvacationbox - подія відкриття вікна відпусток
        -->
        <template v-slot:body>
            <b-row>
                <b-col
                    lg="3">
                    <leftblock 
                        :obj="this.form" 
                        :statusEdit="statusEdit" 
                        @updateCard="updateCard"
                        @editUser="editUser"
                    />
                </b-col>
                <b-col
                    lg="9">
                    <rightblock 
                        :obj="this.form" 
                        @openprojectbox="openprojectbox" 
                        @openlabelbox="openlabelbox" 
                        @editUser="editUser" 
                        @jobDesc="jobDesc" 
                        @toConf="toConf" 
                        @toShift="toShift" 
                        @openfinebox="openfinebox"
                        @openvacationbox="openvacationbox"
                    />
                </b-col>
            </b-row>
        </template>

        <!-- Слот футера модального вікна з кнопкою для збереження даних -->
        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Видалити" 
                - надає змогу видалити співробітника
                - відкривається модальне вікно, для підтвердження видалення співробітника
                - йде перевірка на права доступу під номером 704 (видалення співробітника) та 2000 (режим бога)
            -->
            <b-button 
                v-if="this.perms['704'] || this.perms['2000']" 
                @click="dialogShow = true"
                variant="danger" 
                class="waves-effect waves-light"
            >
                <i class="ri-delete-bin-line align-bottom me-1"></i>
                {{ $t('Delete') }}
            </b-button>
            
            <!-- 
                Кнопка "Заблокувати/розблокувати"
                - надає змогу заблокувати або розблокувати співробітника
                - йде перевірка на права доступу під номером 703 (редагувати співробітника) та 2000 (режим бога)
                - @click - обробник події кліку, викликає функцію блокування чи розблокування користувача
            -->
            <b-button
                v-if="perms[703] || perms[2000]"
                @click="form.workerStatus == 1 ? blockuser(form) : unlockuser(form)" 
                variant="warning" class="waves-effect waves-light"
            >
                <i :class="`${form.workerStatus == 1 ? 'ri-lock-2-fill' : 'ri-lock-unlock-fill'} align-bottom me-1`"></i>
                {{ form.workerStatus == 1 ? $t('block') : $t('Unlock') }}
            </b-button>

            <!--
                Кнопка відправлення запрошення
                - надає змогу надіслати запрошення в кабінет співробітника
                - йде перевірка на права доступу під номером 701 (додати співробітника) та 2000 (режим бога)
                - @click - обробник події кліку, викликає функцію відправлення запрошення
            -->
            <b-button
                v-if="this.perms[701] || this.perms['2000']"
                @click="sendInv()"
                variant="success" class="waves-effect waves-light"
            >
                {{$t('sendInvitation')}} 
            </b-button>

            <!--
                Кнопка редагування картки
                - йде перевірка на права доступу під номером 703 (редагування співробітника) та 2000 (режим бога)
                - @click - обробник події кліку, встановлює статус редагування
            -->
            <b-button 
                v-if="perms[703] == true && statusEdit == false" 
                @click="statusEdit = true" 
                variant="info" 
                class="waves-effect waves-light"
            >
                <i class="ri-edit-bin-line align-bottom me-1"></i>
                {{ $t('toEditAp') }}
            </b-button>

        </template>
    </modal>

    <!-- 
        Модальне вікно створення проекту
    -->
    <projects 
        v-if="modalProjectBox" 
        @close="modalProjectBox = false" 
    ></projects>

    <!-- 
        Модальне вікно введення посадової інструкції
    -->
    <jobDescbox 
        v-if="modaljobDesc" 
        @close="modaljobDesc = false" 
        :obj="this.obj" 
    ></jobDescbox>

    <!-- 
        Модальне вікно створення штрафу
    -->
    <fineBox 
        v-if="modalFineBox" 
        @close="modalFineBox = false" 
        :obj="this.form" 
    ></fineBox>

    <!-- 
        Модальне вікно запиту вихідного/відпустки
    -->
    <vacationBox 
        v-if="modalVacationBox" 
        @close="modalVacationBox = false"
        :obj="this.form"
    />

    <!-- 
        Модальне вікно підтвердження збереження
        - @editUserPerms - виклик події на запит збереження прав доступу
    -->
    <dialogsave 
        v-if="modalDontSave" 
        @editUserPerms="editUserPerms" 
        @close="close(true)"
    />

    <!-- 
        Модальне вікно видалення співробітника
        - @remove - подія підтвердження видалення співробітника
    -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove(form)" 
    />

</template>

<script>
import modal from '@/components/modal' // компонент модального вікна
import headblock from './headmodal' // компонент верхнього блоку модального вікна
import leftblock from './formleft/leftblock' // компонент лівого блоку модального вікна
import rightblock from './formright/rightblock' // компонент правого блоку модального вікна
import projects from "@/views/projects/new.vue" // компонент проектів 
import jobDescbox from "@/views/users/view/formright/main/create.vue" // компонент опису роботи користувача (посадова інструкція)
import fineBox from '@/views/statistics/fines/create.vue' // компонент штрафів
import vacationBox from './formright/vacation/create' // компонент відпусток
import dialogsave from './dialogsave' // компонент діалогового вікна збереження
import modalremove from '@/components/modal/modalremove' // компонент модального вікна для підтвердження видалення 
import { Users } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '@/store' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Users();

export default{
    // властивості, які компонент приймає від батьківського компонента
    props:['objcard', 'form_applic', 'ifPrevopen', 'ifNextopen'],
    components: {
        modal,
        leftblock,
        rightblock,
        projects,
        jobDescbox,
        headblock,
        fineBox,
        dialogsave,
        vacationBox,
        modalremove
    },
    data() {
        return {
            form:"", // об'єкт для зберігання даних користувача
            modalProjectBox: false, // стан модального вікна проектів
            modalLabelBox: false, // стан модального вікна міток
            modaljobDesc: false, // стан модального вікна опису роботи
            modalFineBox: false, // стан модального вікна штрафів
            modalDontSave: false, // стан модального вікна попередження про незбережені дані
            modalVacationBox: false, // стан модального вікна відпусток
            statusEdit: false, // статус редагування картки
            dialogShow: false, // стан модального вікна підтвердження
            componentKey: '' // змінна для ререндера
        }
    },
    created() {
        this.form = this.objcard // ініціалізація даних користувача
    },
    methods:{
        updateCard() {
            apiServe.getUser(this.form.workerId).then(result => {
                if(result.status === 'done') {
                    this.form = result.data
                    this.componentKey++
                }
            })
        },
        close(status){
            /*
                Функція на перевірку на не збереженних прав доступу, то відкривається вікно нагадування зберегти
            */

            // localStorage.removeItem('tempPerms')
            if(localStorage.tempPerms){
                if(status == true) {
                    localStorage.removeItem('tempPerms')
                    this.$emit('close')
                } else {
                    this.modalDontSave = true
                }
                
            } else {
                this.$emit('close')
            }
            
        },
        editUserPerms(){
            /*
                Функція для збереження прав доступу за користувачем
                Викликає API для редагування прав доступу
            */

            // Підготовка даних для збереження прав доступу
            var saveperms = {
                workerId: this.form?.workerId,
                perms: JSON.parse(localStorage.tempPerms),
                super: false
            };

            // Виклик API для редагування прав доступу
            apiServe.editPermsUser(saveperms).then(result => {
                if(result.status == "done"){
                    this.$toast.success(this.$t('changeswereSuccessful'));  // Вивід повідомлення про успішне збереження
                    this.eventBus.emit('editUser', true) // Відправка події редагування користувача, для оновлення картки співробітника
                    this.$emit('close') // Закриття модального вікна
                    localStorage.removeItem('tempPerms')  // Видалення тимчасових прав доступу з локального сховища
                } else {
                    this.$toast.error(this.$t('err'))   // Вивід повідомлення про помилку

                    var errorText = 'Помилка збереження прав доступу співробітника'; 
                    var statusRequest = result.status;
                    var api = 'editPermsUser';
                    var fileRequest = 'src/views/users/view/index.vue';
                    var params = saveperms;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        prevOpen(){
            // Функція для переходу до попереднього співробітника
            this.$emit('close');
            this.$emit('prevOpen', this.form.workerId)
        },
        nextOpen(){
            // Функція для переходу до наступного співробітника
            this.$emit('close');
            this.$emit('nextOpen', this.form.workerId)
        },
        jobDesc: function(){
            // Функція для відображення посадової інструкції, поки в розробці
            this.modaljobDesc = true
        },  
        toConf: function(){
            // Функція підтвердження ознайомлення з посадовою інструкцією, поки в розробці

            // this.form.jobdescCheck = true,
            // apiServe.editUser(this.form.id, this.form).then(result => {
            //     if(result.status === 'done'){
            //         this.edit = false;
            //         this.$toast.success(this.$t('changeswereSuccessful'));
            //         this.eventBus.emit('editUser', true)
            //     } else {
            //         this.$toast.error(this.$t('error'));
            //     }
            // })
        },
        toShift: function(form){
            // Функція для збереження налаштувань зміни

            if (!form.workerId) {
                //перевіряємо чи вказаний юзер
                this.$toast.error(this.$t('EnterConnectionId'));
            }
            if (!form.workerOpenShift) {
                //перевіряємо чи вказаний початок зміни
                this.$toast.error(this.$t('EnterOpenShift'));
            }
            if (!form.workerCloseShift) {
                //перевіряємо чи вказаний кінець зміни
                this.$toast.error(this.$t('EnterCloseShift'));
            }

            // Виклик API для збереження налаштувань зміни
            if(form.workerId && form.workerOpenShift && form.workerCloseShift){
                apiServe.saveShiftSettings(form.workerId, form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('changeswereSuccessful'));
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var tempObj = {
                            "worker": form.workerId,
                            "params": form
                        }

                        var errorText = 'Помилка збереження налаштувань зміни співробітника'; 
                        var statusRequest = result.status;
                        var api = 'saveShiftSettings';
                        var fileRequest = 'src/views/users/view/index.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                })
            }
        },
        openprojectbox: function(){
            // Функція для відкриття вікна проекту
            this.modalProjectBox = true
        },
        openlabelbox: function(){
            // Функція для відкриття вікна мітки
            this.modalLabelBox = true
        },
        editUser: function(e){
            console.log("editUser");
            
            // Функція для редагування користувача
            
            if (!e.workerId) {
                //перевіряємо чи вказаний id
                this.$toast.error(this.$t('EnterConnectionId'));
            }
            if (!e.workerName) {
                //перевіряємо чи вказане ім'я
                this.$toast.error(this.$t('enterName'));
            }
            if (!e.workerPhone) {
                //перевіряємо чи вказаний номер телефону
                this.$toast.error(this.$t('EnterNumberWorker'));
            }
            if (!e.workerJob) {
                //перевіряємо чи вказана посада
                this.$toast.error(this.$t('EnterWorkerJob'));
            }
            // if (!e.workerEmail) {
            //     //перевіряємо чи вказана пошта
            //     this.$toast.error(this.$t('EnterMail'));
            // }
            // if(e.workerId
            //     && e.workerName
            //     && e.workerPhone
            //     && e.workerJob
            //     && e.workerEmail
            //     ){

            // Обробка Telegram Username
            let username = e.telegramUsername.trim(); // Видаляємо зайві пробіли

            // Перевіряємо, чи це посилання на Telegram
            if (username.startsWith('https://t.me/')) {
                username = username.replace('https://t.me/', '');
            }

            // Якщо починається з "@", видаляємо його
            if (username.startsWith('@')) {
                username = username.slice(1); // Видаляємо перший символ "@"
            }

            // Призначаємо оброблений нік назад у форму
            e.telegramUsername = username;

            // Виклик API для редагування користувача
                apiServe.editUser(e.workerId, e).then(result => {
                    if(result.status==='done'){
                        // Закриття режиму редагування
                        this.edit = false;
                        this.statusEdit = false;
                        // Вивід повідомлення про успішне збереження
                        this.$toast.success(this.$t('changeswereSuccessful'));
                        this.eventBus.emit('editUser', true) // Відправка події редагування користувача, для оновлення картки співробітника
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var tempObj = {
                            "worker": e.workerId,
                            // "params": e
                        }

                        var errorText = 'Помилка редагування картки співробітника'; 
                        var statusRequest = result.status;
                        var api = 'editUser';
                        var fileRequest = 'src/views/users/view/index.vue';
                        var params = tempObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                })
            // }
        },
        remove: function(e){
            // Функція для видалення співробітника

            apiServe.deleteWorker(e.workerId).then(res => {
                if(res.status == 'done'){
                    this.showModal = false;
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення картки співробітника'; 
                    var statusRequest = result.status;
                    var api = 'deleteWorker';
                    var fileRequest = 'src/views/users/view/index.vue';
                    var params = e.workerId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        blockuser: function(e){
            // Функція для блокування співробітника

            this.form.workerStatus = 0;
            apiServe.editUser(e.workerId, this.form).then(result => {
                if(result.status == 'done'){
                    this.componentKey += 1
                    this.$toast.success(this.$t('changeswereSuccessful'));
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "worker": e.workerId,
                        // "params": this.form
                    }

                    var errorText = 'Помилка блокування співробітника'; 
                    var statusRequest = result.status;
                    var api = 'editUser';
                    var fileRequest = 'src/views/users/view/index.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        unlockuser: function(e){
            // Функція для розблокування співробітника

            this.form.workerStatus = 1;
            apiServe.editUser(e.workerId, this.form).then(result => {
                if(result.status == 'done'){
                    this.componentKey += 1
                    this.$toast.success(this.$t('changeswereSuccessful'));
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "worker": e.workerId,
                        // "params": this.form
                    }

                    var errorText = 'Помилка розблокування співробітника'; 
                    var statusRequest = result.status;
                    var api = 'editUser';
                    var fileRequest = 'src/views/users/view/index.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        openfinebox: function(){
            // Функція для відкриття вікна штрафу
            this.modalFineBox = true;
        },
        openvacationbox: function(){
            console.log('here')
            // Функція для відкриття вікна вихідного/відпустки
            this.modalVacationBox = true;
        },
        sendInv(){
            // Функція для надсилання запрошення в кабінет

            var inv = {
                workerId: this.form.workerId,
                login: this.form.workerPhone
            }
            apiServe.sendInvitation(inv).then(result => {
                if(result.status == "done"){
                    this.$toast.success(this.$t('InvitationSent'));
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка надсилання запрошення в кабінет'; 
                    var statusRequest = result.status;
                    var api = 'sendInvitation';
                    var fileRequest = 'src/views/users/view/index.vue';
                    var params = inv;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    },
    computed: {
        user(){
            /*
                Властивість, яка повертає дані за користувача зі збережених даних у глобальному стору.
            */
            return storeS.userbase
        },
        perms(){
            /*
                Властивість, яка повертає права доступу користувача зі збережених даних у глобальному стору.
            */
            return storeS.perms
        },
        workers() {
            /*
                Властивість, яка повертає список співробітників зі збережених даних у глобальному стору.
            */
            return storeS.workers
        }
    }
}
</script>