<!-- Створення колонки -->
<template>
    
    <dialogModal>

        <!-- Модалка додавання нотатки -->
        <template v-slot:title>
            {{ this.$t('Add') }}
        </template>
        <template v-slot:body>  
            <b-row>
                <b-col lg="12" class="mb-3">
                    <label for="boardName" class="form-label">{{ $t('EnterNameTicket') }}</label>
                    <input type="text" v-model="form.columnName" class="form-control" id="boardName" :placeholder="$t('EnterNameTicket')">
                </b-col>
                <b-col lg="12">
                    <label for="boardName" class="form-label">{{ $t('selectColorBadge') }}</label>
                    <!-- <b-card> -->
                        <div class="d-flex mb-3">
                            <div class="flex-grow-1">
                                <h6 class="fs-14 text-uppercase fw-semibold mb-0">
                                    {{ form.columnName == "" ? $t('example') : form.columnName }} 
                                    <b-badge tag="small" variant="success" class="align-bottom ms-1" :style="`background-color:${form.color}!important`">2</b-badge>
                                </h6>
                            </div>
                        </div>

                        <b-row>
                            <template v-for="item in colors" :key="item">
                                <b-col lg="2" class="mb-2 copy" @click="form.color = item.color">
                                    <div class="avatar-sm" >
                                        <div class="avatar-title rounded text-white" :style="`background-color: ${item.color};`"></div>
                                    </div>
                                </b-col>
                            </template>
                        </b-row>
                    <!-- </b-card> -->

                </b-col>
            </b-row>
        </template>

        <template v-slot:footer-bottom>
            
            <div class="hstack gap-2 justify-content-end mt-2">
                <!-- Закрити вікно -->
                <b-button type="button" variant="light" @click="$emit('close')">{{ $t('close') }}</b-button>

                <!-- Додати колонку -->
                <b-button v-if="!editPerm" type="button" variant="success" @click="addNewBoard()">{{ $t('Add') }}</b-button>

                <!-- Редагувати колонку -->
                <b-button v-if="editPerm" type="button" variant="success" @click="editBoard()">{{ $t('Edit') }}</b-button>
            </div>

        </template>

    </dialogModal>
    
</template>

<script>
import dialogModal from "@/components/modal/dialog.vue"
import { developmentProjects } from '@/API.js';

let apiServe = new developmentProjects();

export default {
    props: ["objCard", "editPerm", "objItemEditColumn"],
    components: {
        dialogModal
    },
    data() {
        return {
            form: {
                columnName: '',
                color: ''
            },
            colors: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#0369a1', title: '0369a1' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#34495E', title: '34495E' },
                { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
                { color: '#9B59B6', title: '9B59B6' },
            ],
        }
    },
    created(){
        if(this.objItemEditColumn){ 
            this.form.columnName = this.objItemEditColumn.columnName
            this.form.color = this.objItemEditColumn.color
        }
    },
    methods: {
        addNewBoard(){
            // створення дошки
            // this.$emit('addNewBoard', this.form)
            apiServe.addColumn(this.objCard.projectId, this.form).then(result => {
                if(result.status == 'done') {
                    this.$emit('close')
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        editBoard(){
            // Редагування колонки

            apiServe.editColumn(this.objItemEditColumn.columnId, this.form).then(result => {
                if(result.status == 'done') {
                    this.$emit('close')
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        }
    }
}
</script>