<template>
    <!-- 
        Модальне вікно з відображення картки події 
    -->

    <modal @close="this.$emit('close')">
        <!-- Заголовок модалки -->
        <template v-slot:title>{{ form.title }}</template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <!-- 
                Кнопки для редагування та додавання події, в залежності від типу події
                - йде перевірка на права 511 (додати послугу), 422 (редагування події) та 2000 (режим богу)
            -->   
            <div class="text-end" v-if="perms[511] || perms[422] || perms[2000]">

                <!-- 
                    Кнопка "Додати послугу" 
                    - надає змогу додати послугу
                    - відкривається модальне вікно створення послуги
                    - йде перевірка на права доступу під номером 511 (додати послугу) та 2000 (режим бога), і щоб тип події був "підключення"
                -->
                <a 
                    v-if="(perms[2000] || perms[511]) && form.extendedProps.type == 'connection'" 
                    class="btn btn-sm btn-soft-secondary fw-semibold" 
                    id="edit-event-btn" 
                    data-id="edit-event" 
                    style="margin-right: 5px;" 
                    @click="addService()" 
                    role="button"
                >
                    {{ $t('add_service') }}
                </a>

                <!-- 
                    Кнопка "Змінити" 
                    - надає змогу відредагувати подію
                    - йде перевірка на права доступу під номером 422 (редагування події) та 2000 (режим бога)
                -->
                <a 
                    v-if="perms[422] || perms[2000]"
                    class="btn btn-sm btn-soft-primary fw-semibold" 
                    id="edit-event-btn" 
                    data-id="edit-event" 
                    @click="this.$emit('edit', this.form)" 
                    role="button"
                >
                    {{ $t('Edit') }}
                </a>
            </div>

            <!-- Відображення деталей події -->
            <div class="event-details" >

                <!-- Тип події -->
                <div class="d-flex mb-2" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-flag-2-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <p class="d-block fw-semibold mb-0" id="event-type-tag">{{ mutateType(form.extendedProps.type, form.extendedProps).name }}</p>
                    </div>
                </div>

                <!-- Дата та час події -->
                <div class="d-flex mb-2" >
                    <div class="flex-grow-1 d-flex align-items-center" >
                        <div class="flex-shrink-0 me-3" >
                            <i class="ri-calendar-event-line text-muted fs-16"></i>
                        </div>
                        <div class="flex-grow-1" >
                            <h6 class="d-block fw-semibold mb-0" id="event-start-date-tag">{{ formatDate(form.startStr).rDate }} <span v-if="form.endStr">- {{ formatDate(form.endStr).rDate }}</span></h6>
                        </div>
                    </div>
                </div>

                <!-- Час події -->
                <div class="d-flex align-items-center mb-2" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-time-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <h6 class="d-block fw-semibold mb-0"><span id="event-timepicker1-tag">{{ formatDate(form.startStr).timeStr }}</span></h6>
                    </div>
                </div>

                <!-- Автор події -->
                <div class="d-flex align-items-center mb-2" v-if="form.extendedProps.nameWorker" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="mdi mdi-account-circle text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <h6 class="d-block fw-semibold mb-0"><span id="event-user-tag">{{ form.extendedProps.nameWorker }}</span></h6>
                    </div>
                </div>

                <!-- Статус події -->
                <div class="d-flex align-items-center mb-2" >
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-git-repository-private-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <h6 class="d-block fw-semibold mb-0"><span id="event-status-tag">{{ this.mutateStatus(form.extendedProps.status) }}</span></h6>
                    </div>
                </div>

                <!-- Опис події -->
                <div class="d-flex mb-3" v-if="form.extendedProps.description != ''">
                    <div class="flex-shrink-0 me-3" >
                        <i class="ri-discuss-line text-muted fs-16"></i>
                    </div>
                    <div class="flex-grow-1" >
                        <p class="d-block text-muted mb-0" id="event-description-tag">{{ form.extendedProps.description }}</p>
                    </div>
                </div>

            </div>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>

            <!-- 
                Кнопка "Підтвердити" 
                - надає змогу підвтердиди подію
                - відкривається модальне вікно, для підтвердження видалення події
                - йде перевірка на права доступу під номером 423 (видалення події) та автора події або 2000 (режим бога)
            -->
            <!-- <button 
                v-if="(perms[521] || perms[2000]) && this.form.extendedProps.holidayStatus == 'new'" 
                type="button" 
                class="btn btn-success" 
                @click="showModalConfirm = true"
            >
                {{ $t('Confirm') }}
            </button> -->

            <!-- 
                Кнопка "Видалити" 
                - надає змогу видалити подію
                - відкривається модальне вікно, для підтвердження видалення події
                - йде перевірка на права доступу під номером 423 (видалення події) та автора події або 2000 (режим бога)
            -->
            <button 
                v-if="((perms[423] || perms[2000]) || this.user.userid === form.extendedProps.idWorker)" 
                type="button" 
                class="btn btn-danger" 
                @click="dialogShow = true"
            >
                {{ $t('Delete') }}
            </button>

            <!-- 
                Кнопка "Вікдрити" 
                - надає змогу вікдрити документ по якому створена подія
            -->
            <button 
                v-if="form.extendedProps.idOper" 
                type="button" 
                class="btn btn-success" 
                @click="open(form.extendedProps)"
            >
                {{ $t('Open') }}
            </button>
        </template>
    </modal>

    <!-- 
        Модальне вікно підтвердження видалення події
    -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove()" 
    />

    <!-- 
        Модальне вікно картки завдання
    -->
    <viewTask 
        v-if="showTask"
        @close="showTask = false"
        :shortapplic="cardApplic" 
    />

    <!-- 
        Модальне вікно картки вихідного/відпустки
    -->
    <viewHoliday 
        v-if="showHoliday" 
        @close="showHoliday = false" 
        :obj="objCard"
    />

    <!-- 
        Модальне вікно створення послуги
    -->
    <createdService 
        v-if="showModalAdd" 
        @close="showModalAdd = false"
    ></createdService>

    <!-- 
        Модальне вікно підтвердження заявки на вихідний/відпустку
        - для відкриття застосовується перевірка значення showModalConfirm
        - @close - подія, яка спрацьовує при закритті картки
        - :id - параметр-ідентифікатор заявки
        - @toConfirm - подія, яка спрацьовує коли підтверджуєш заявку
    -->
    <modalConfirm 
        v-if="showModalConfirm == true" 
        @close="showModalConfirm = false"
        :id="this.form._def.publicId"
        @toConfirm = "toConfirm"
    />

</template>

<script>
import modal from '@/components/modal/dialog'  // компонент модального вікна
import modalremove from '@/components/modal/modalremove' // компонент діалогового вікна видалення
import viewTask from '@/views/tasks/view/viewtask' // картка завдання
import viewHoliday from '@/views/statistics/holidays/view1' // картка вихідного
import createdService from '@/views/services/createservice' // вікно створення послуги
import { DataCalendar, Tasks, Holidays } from '@/API' // класи для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { mutateTypeCalendar, mutateStatusCalendar } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import modalConfirm from '@/views/statistics/holidays/actions/confirm' // вікно підтвердження заявки

let apiServe = new DataCalendar();
let apiTasks = new Tasks();
let apiHolidays = new Holidays();

export default {
    props: ['obj'],
    components: {
        modal,
        modalremove,
        viewTask,
        viewHoliday,
        createdService,
        modalConfirm
    },
    data(){
        return{
            showModalConfirm: false,
            form: '',
            dialogShow: false, // прапорець відображення видалення події
            showTask: false, // прапорець відображення вікна завдання
            showHoliday: false, // прапорець відображення вікна відпустки
            objCard: '', //параметри документа
            showModalAdd: false // прапорець відображення вікна створення штрафу
        }
    },
    created(){
        // Ініціалізація даних форми події
        this.form = this.obj
        console.log(this.form);
        

        /**
            * Встановлення типу модалки та її id
            * Потрібно для сокету
        */
        storeS.checkModal.type = 'event';
        storeS.checkModal.id = this.form.id;
    },
    methods: {
        toConfirm(id, e){
            /**
                Функція для підтвердження заявки на вихідний/відпустку
                * @param {Object|String} id - Ідентифікатор заявки.
                * @param {Object} e - об'єкт-параметр щодо заявки.

                Викликає API для підтвердження заявки.
                - якщо у юзера відкрита можливість створення івену одразу, буде відображатись вікно з додаванням інформації в календар
            */

            apiHolidays.confirmRequest(id, e).then(result => {
                if(result.status == 'done'){  
                    this.$toast.success(this.$t('Confirmed'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + ' #202');

                    var tempObj = {
                        "id": id,
                        "params": e
                    }

                    var errorText = 'Помилка видалення картки заявки на вихідний'; 
                    var statusRequest = result.status;
                    var api = 'deleteRequest';
                    var fileRequest = 'src/views/statistics/holidays/view.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },  
        remove(){
            /**
                * Видаляє поточну подію за допомогою API-запиту та виводить сповіщення про успішне видалення.
                * @param {number} this.form.id - ідентифікатор події.
            */

            apiServe.deleteEvent(this.form.id).then(result => {
                if(result.status == 'done'){
                    this.dialogShow = false;
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення події'; 
                    var statusRequest = result.status;
                    var api = 'deleteEvent';
                    var fileRequest = 'src/components/calendar/viewEvent.vue';
                    var params = this.form.id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        formatDate(date) {
            //  Метод для форматування дати та часу

            var date3 = new Date(date);  
            var dateStr = date3.getFullYear() + "-" + ("00" + (date3.getMonth() + 1)).slice(-2) + "-" + ("00" + date3.getDate()).slice(-2);
            var timeStr = ("00" + date3.getHours()).slice(-2) +":"+("00" + date3.getMinutes()).slice(-2)
            var monthNames = [
                this.$t('January'),
                this.$t('February'),
                this.$t('March'),
                this.$t('April'),
                this.$t('May'),
                this.$t('June'),
                this.$t('July'),
                this.$t('August'),
                this.$t('September'),
                this.$t('October'),
                this.$t('November'),
                this.$t('December'),
            ];
            var d = new Date(dateStr),
                month = "" + monthNames[d.getMonth()],
                day = "" + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;
            var rDate = [day + " " + month, year].join("  ")
            return { rDate, timeStr};
        },
        open(item){
            /**
                Функція для відкриття модального вікна з інформацією про відповідний документ за його ідентифікатором.
                * @param {Object} item - об'єкт картки події

                Викликає API для отримання інформації про документ.
                Результат присвоюється властивості objCard, та встановлюється прапорці showTask або showHoliday в true для відображення модального вікна.
            */

            switch (item.typeDoc) {
                case "task":
                    apiTasks.getTask(item.idOper).then(result => {
                        if(result.status == 'done'){
                            this.objCard = result.data;
                            this.showTask = true;
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case "graphs":
                    apiHolidays.getRequest(item.idOper).then(result => {
                        if(result.status == 'done'){
                            this.objCard = result.data;
                            this.showHoliday = true;
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
                break;
                case 'vacation':
                    apiHolidays.getRequest(item.idOper).then(result => {
                        if(result.status == 'done'){
                            this.objCard = result.data;
                            this.showHoliday = true;
                        } else {
                            this.$toast.error(this.$t('err'));
                        }
                    })
            
                default:
                    break;
            }
        },
        addService(){
            // Метод для відображення модального вікна для додавання послуги

            this.showModalAdd = true
        },
        mutateType(e, obj) {
            /*
                Функція для застосування перекладу до типу події
            */
            return mutateTypeCalendar(e, obj)
        },
        mutateStatus(e) {
            /*
                Функція для застосування перекладу до статусу події
            */
            return mutateStatusCalendar(e);
        }
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>