<!-- список -->

<template>

     <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога)
        - @create - подія, яка спрацьовує при створенні нового завдання на тестування
        - @open - подія, яка спрацьовує при відкритті картки завдання на тестування
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms[2000]" 
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
    />

    <!-- Перегляд картки завдання -->
    <viewTask 
        v-if="showTask"
        @close="showTask = false;getdata()"
        :objCard="this.form"
        :columnArr="columnArr"
        :projectId="objCard.projectId"
    />
    
</template>

<script>
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewTask from '../board/viewTask.vue' // Перегляд картки завдання
import { 
        mutateStatusTicket,
        mutatePriorityTicket,
        mutateKanbanColumn
      } from '@/usabilityScripts/globalMutate.js'
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new developmentProjects();

export default {
    props: ["objCard"],
    components: {
        tablecustom,
        viewTask
    },
    data() {
        return {
            form: "",
            showTask: false, // блок перегляду таски
            dataParams: {
                status: true,
                page: "devTasks"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                priority: '',
            },
            objPages: {},
            columns: [
                {
                    name: "ID",
                    text: "taskId",
                    align: "left",
                    status: true,
                    mutate: (item) => "#"+item
                },
                {
                    name: this.$t('Point_name'),
                    text: "taskName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('Status'),
                    text: "columnId",
                    align: "left",
                    status: true,
                    mutate: (item) => this.getColumnKanban(item).name,
                    mutateStyleColor: (item) => this.getColumnKanban(item).color
                },
                {
                    name: this.$t('priority'),
                    text: "priority",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color
                },
                {
                    name: this.$t('Performer'),
                    text: "performerWorkerName",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => {
                        // Перевіряємо наявність необхідних даних в item
                        const performerWorkerId = obj.performerWorkerId || '';
                        const performerWorkerName = obj.performerWorkerName || this.$t('NotDefined');
                        const imgSrc = this.getImgWorker(performerWorkerId);

                        // Генеруємо HTML
                        return `
                            <div class="d-flex align-items-center gap-2"">
                                <div class="avatar-xs">
                                    <div class="avatar-title fs-12 rounded-circle " style="background-color: ${this.getColorByWorkerId(performerWorkerId)}">
                                        ${imgSrc ? 
                                            `<img src="${imgSrc}" alt="${performerWorkerName}" class="avatar-xs rounded-circle">` : 
                                            `<span>${this.getInitials(performerWorkerName)}</span>`}
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <h5 class="fs-13 mb-0">
                                        <a class="text-body d-block">${performerWorkerName}</a>
                                    </h5>
                                </div>
                            </div>`;
                    }
                },
                {
                    name: this.$t('ExecutionDate'),
                    text: "deadlineDatetime",
                    align: "left",
                    status: true,
                    mutate: (item) => item ? this.formatDateTime(item) : '-'
                },
                {
                    name: this.$t('Author'),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                    mutate: (item, obj) => {
                        // Перевіряємо наявність необхідних даних в item
                        const createWorkerId = obj.createWorkerId || '';
                        const createWorkerName = obj.createWorkerName || this.$t('NotDefined');
                        const imgSrc = this.getImgWorker(createWorkerId);

                        // Генеруємо HTML
                        return `
                            <div class="d-flex align-items-center gap-2"">
                                <div class="avatar-xs">
                                    <div class="avatar-title fs-12 rounded-circle" style="background-color: ${this.getColorByWorkerId(createWorkerId)}">
                                        ${imgSrc ? 
                                            `<img src="${imgSrc}" alt="${createWorkerName}" class="avatar-xs rounded-circle">` : 
                                            `<span>${this.getInitials(createWorkerName)}</span>`}
                                    </div>
                                </div>
                                <div class="flex-grow-1">
                                    <h5 class="fs-13 mb-0">
                                        <a class="text-body d-block">${createWorkerName}</a>
                                    </h5>
                                </div>
                            </div>`;
                    }
                },
            ],
            rows: [],
            columnKanban: [],
            colorsNew: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#2ECC71', title: '2ECC71' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#9B59B6', title: '9B59B6' },
                { color: '#34495E', title: '34495E' },
                { color: '#F1C40F', title: 'F1C40F' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#C2185B', title: 'C2185B' },
                { color: '#BDC3C7', title: 'BDC3C7' },
                // { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
            ],
        }
    },
    created(){
        // this.rows = this.objCard.rows
        this.getdata()
        this.columnKanban = this.objCard.columns
        
    },
    methods: {
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку завдань з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список завдань) та objPages (інформація про сторінки).
            */

            this.objParams.page = page != undefined ? page : '1';

            apiServe.getTasks(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                    this.objPages = result.data
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання списку завдань'; 
                    var statusRequest = result.status;
                    var api = 'getTasks';
                    var fileRequest = 'src/views/development/projects/project/tabs/list/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        open(item){
            // відкриття таски

            apiServe.getTask(item.taskId).then(result => {
                if(result.status == 'done'){
                    console.log("result", result);
                    this.form = result.data
                    this.columnArr = this.transformColumns(this.columns);
                    this.showTask = true
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        transformColumns(columns){
            return columns.map(column => ({
                label: column.columnName,  // Назва колонки
                value: column.columnId,    // ID колонки
                color: column.color        // Колір
            }));
        },
        getColumnKanban(columnId){
            // отримання назви та кольору статуса таски
            var name = "";
            var color = "";

            this.columnKanban.forEach(element => {
                if(element.columnId == columnId){
                    name = element.columnName;
                    color = element.color
                } 
            });

            // Якщо колонка не знайдена
            if (!name && !color) {
                name = this.$t('NotDefined');
                color = "#878a99"
            }

            return { name, color }
        },
        formatDateTime(dateTimeString){
            const months = [
                "січня", "лютого", "березня", "квітня", "травня", "червня",
                "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"
            ];
            const date = new Date(dateTimeString);
            const day = date.getDate();
            const month = months[date.getMonth()];
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");

            return `${day} ${month}, ${year}`;
        },
        getImgWorker(workerId){
            // отримання зображення співробітника
            var avatar = ""
            this.workers.forEach(element => {
                if(element.workerId == workerId){
                    avatar = element.avatar;
                }
            });
            return avatar
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        getColorByWorkerId(workerId) {

            if(workerId) {
                const numericValue = workerId;
                const index = numericValue % this.colorsNew.length;
                return this.colorsNew[index].color;
            } else {
                return "#95A5A6"
            }
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за ..
            */

            this.objParams.search = e
            this.getdata();
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutatestatus(e){
            // мутейт статусу
            return mutateStatusTicket(e)
        },
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        workers(){
            // отримуємо список співробітників
            return storeS.workers
        },
    },
}
</script>