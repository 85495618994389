<template>
    <!-- модальное окно создания счета -->
    <modal>
        <template v-slot:lefthead>{{ $t('сreatingAccount') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="6" v-if="this.editform == undefined">
                    <h4 class="fs-15"><b>{{ $t('ChooseSupplier') }}</b></h4>
                    <!-- {{ settingInv }} -->
                    <b-form-select v-model="form.providerId" :options="internalSuppliers" @change="getDataProvider" ></b-form-select>

                    <!-- <div class="form-check mb-2">
                        <input class="form-check-input" type="radio" name="provider" id="fop" value="FOP" v-model="this.form.provider">
                        <label class="form-check-label" for="fop">
                            {{ $t('FOp') }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="provider" id="tov" value="TOV" v-model="this.form.provider">
                        <label class="form-check-label" for="tov">
                            {{ $t('TOv') }}
                        </label>
                    </div> -->
                    
                    <!--new invoices-->

                    <!-- <Multiselect
                        class="w-md"
                        v-model="form.provider"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :options="this.providers"
                    /> -->

                    <!--new invoices-->
                </b-col>
                <b-col lg="6" v-if="this.editform == undefined">
                    <h4 class="fs-15"><b>{{ $t('Provider') }}</b></h4>
                    <label v-if="changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == true">
                        <b>{{ changeProv.providerTitle }}</b> 
                        <br />IBAN: {{ changeProv.iban }} в {{ changeProv.bank }}, {{ changeProv.address }} 
                        <br />Тел. {{ changeProv.phone }} 
                        <br />Код ЄДРПОУ {{ changeProv.erdpu }}, ІПН {{ changeProv.ipn }} 
                    </label>
                    <label v-if="changeProv.businessEntity == 'FOP' || (changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == false)">
                        <b>{{ changeProv.providerTitle }}</b>
                        <br />Р/р {{ changeProv.iban }}, {{ changeProv.bank }}, МФО {{ changeProv.mfo }} код по ЄРДПОУ {{ changeProv.erdpu }}
                    </label>
                    <!-- <label>
                        <b>{{ this.providersStore[form.provider] }}</b> 
                        <br />IBAN: {{ settingInv.TOV.iban }} в {{ settingInv.TOV.bank }}, {{ settingInv.TOV.address }} 
                        <br />Тел. {{ settingInv.TOV.phone }} 
                        <br />Код ЄДРПОУ {{ settingInv.TOV.erdpu }}, ІПН {{ settingInv.TOV.ipn }} 
                    </label>
                    <label v-if="this.form.provider == 'FOP'">
                        <b>{{ settingInv.FOP.provider }}</b>
                        <br />Р/р {{ settingInv.FOP.calculatedWallet }}, {{ settingInv.FOP.bank }}, МФО {{ settingInv.FOP.mfi }} код по ЄРДПОУ {{ settingInv.FOP.erdpu }}
                    </label> -->
                </b-col>
                <hr class="mt-3" v-if="this.editform == undefined" />
                <b-col >
                    <h4 class="fs-15"><b>{{ $t('Buyer') }}</b></h4>
                    <div class="row g-3">
                            <div class="col-lg-3 col-sm-6">
                                <label for="invoicenoInput">{{ $t('Buyer') }}</label>
                                    <div class="input-light">
                                        <Multiselect
                                            class="w-md"
                                            v-model="form.businessEntity"
                                            :close-on-select="true"
                                            :searchable="true"
                                            :create-option="false"
                                            :options="this.businessEntityList"
                                        />
                                    </div>
                                </div>
                            <div class="col-lg-3 col-sm-6">
                                <div>
                                    <label for="date-field">{{ $t('name') }} / {{ $t('Point_name') }}</label>
                                    <input
                                        type="text"
                                        class="form-control bg-light border-0"
                                        id="companyaddpostalcode"
                                        required
                                        v-model="form.sellerName"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div>
                                    <label for="date-field">{{ $t('phone_number') }} </label>
                                    <input
                                        type="text"
                                        :class="`form-control bg-light border-0 ${statusnum == true ? 'text-success' : 'text-danger'}`"
                                        id="companyaddpostalcode"
                                        required
                                        v-model="form.sellerPhone"
                                        @change="change(form.sellerPhone)"
                                        @keyup.enter="change(form.sellerPhone)"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div>
                                    <label for="date-field">{{ $t('Payment') }}</label>
                                    <Multiselect
                                        class="w-md"
                                        v-model="form.payment.status"
                                        :close-on-select="true"
                                        :searchable="true"
                                        :create-option="false"
                                        :options="this.payList"
                                    />
                                </div>
                            </div> 
                         </div>
                        <div class="row g-3 mt-1" v-if="form.businessEntity == 'FOP' || form.businessEntity == 'TOV'">
                            <div class="col-lg-12 col-sm-12">
                                <label for="invoicenoInput"><b>{{ form.businessEntity == 'FOP' ? $t('FOp') : $t('TOv') }}</b></label>
                                <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.economicalSociety"></textarea>
                            </div>
                        </div>
                </b-col>
            </b-row>
            <hr />
            <div class="mt-2 mb-2" v-if="сompanies?.length > 0">
                <h4 class="fs-15"><b>{{ $t('company') }}</b></h4>
                <select class="form-select mb-0" v-model="selectCompany" @change="getCompany(selectCompany)">
                    <option v-for="item in сompanies" :key="item" :value="item.companyId" >{{item.companyName}}</option>
                </select>
            </div>
            <b-row v-if="paylicense.tariffName != ''">
                <div class="form-check" style="margin-left: calc(var(--vz-gutter-x) * .5); max-width: 95%;">
                    <label for="checkAll">{{ this.$t('round') }}</label>
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        v-model="this.fixPrice"
                    />
                </div>
                <!-- <div class="col-sm-auto">
                    <button type="button" class="btn btn-info" @click="payThree">{{ $t('paymentforThreemonths') }}</button>
                </div>
                <div class="col-sm-auto">
                    <button type="button" class="btn btn-primary" @click="paySix">{{ $t('paymentforSixmonths') }}</button>
                </div> -->
                <div class="col-sm-auto">
                    <button type="button" class="btn btn-success" @click="payYearly">{{ $t('paymentforYearlymonths') }}</button>
                </div>
            </b-row>
            <br />
            <b-row>
                <b-col>
                    <div class="card-body p-1">
                        <div class="table-responsive">
                            <table
                            class="invoice-table table table-borderless table-nowrap mb-0"
                            >
                            <thead class="align-middle">
                                
                                <tr class="table-active">
                                    <th scope="col" style="width: 50px">#</th>
                                    <th scope="col">{{ $t('Goods') }}</th>
                                    <th scope="col" style="width: 105px">{{ $t('Quantity') }}</th>
                                    <th scope="col" style="width: 105px">{{ changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true' ? $t('PriceWithoutVAT') : $t('priceprod')  }}</th>
                                    <th scope="col" style="width: 105px">{{ changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true' ? $t('AmountWithoutVAT') : $t('Sum') }}</th>
                                    <th scope="col" v-if="changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true'">{{ $t('VATamount') }}</th>
                                    <th
                                        scope="col"
                                        class="text-end"
                                        style="width: 105px"
                                    ></th>
                                </tr>
                            </thead>
                            <tbody id="newlink">
                                <tr class="product">
                                    <th scope="row" class="product-id"></th>
                                    <td class="text-start">
                                            <div class="mb-2">
                                                <Multiselect 
                                                    :close-on-select="true"
                                                    :searchable="true"
                                                    :create-option="false"
                                                    :options="this.goodsList"
                                                    :placeholder='$t("Goods")'
                                                    v-model="this.good"
                                                    :object="true"
                                                    @select="inputPrice(this.good)"
                                                />
                                                <!-- <select class="form-select mb-3" v-model="this.good" @change="inputPrice(this.good)">
                                                    <option v-for="item in goodsList" :key="item" :value="item" >{{item.label}}</option>
                                                </select>
                                                <div class="invalid-feedback">
                                                    {{ $t('PleaseenterName') }}
                                                </div> -->
                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                class="form-control bg-light border-0"
                                                id="quantity"
                                                placeholder="Кол-во"
                                                v-model="this.quantityAlt"
                                            />
                                        </td>
                                        <td class="text-end">
                                            <div>
                                            <input
                                                type="text"
                                                class="form-control bg-light border-0 product-line-price"
                                                placeholder="₴0.00"
                                                v-model="this.priceAlt"
                                            />
                                            </div>
                                        </td>
                                        <td class="text-end">
                                            <div>
                                            <input
                                                type="number"
                                                class="form-control bg-light border-0 product-line-price"
                                                placeholder="₴0.00"
                                                :value="totalItemSum()"
                                                readonly
                                            />
                                            </div>
                                        </td>
                                        <td class="text-end" v-if="changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true'" >
                                            <div>
                                            <input
                                                type="number"
                                                class="form-control bg-light border-0 product-line-price"
                                                placeholder="₴0.00"
                                                :value="totalItemVat(item)"
                                                readonly
                                            />
                                            </div>
                                        </td>
                                        <td class="product-removal">
                                            
                                        </td>
                                </tr>
                                <tr v-for="(item, index) in form.products" :key="index" :id="index" class="product">
                                    <th scope="row" class="product-id">{{index+1}}</th>
                                    <td class="text-start">
                                        <div class="mb-2">
                                            <span>{{ item.nomenclatureName }}</span>
                                            <div class="invalid-feedback">
                                                {{ $t('PleaseenterName') }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span
                                            class="form-control bg-light border-0"
                                            id="quantity"
                                        > 
                                            {{ item.quantity }}
                                        </span>
                                    </td>
                                    <td class="text-end" style="text-align: left;">
                                        <div>
                                            <span
                                                class="form-control bg-light border-0 product-line-price"
                                            >
                                                {{ item.priceWithoutVAT + "₴" }} 
                                            </span>
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <div>
                                        <span
                                            class="form-control bg-light border-0 product-line-price"
                                        >
                                            {{ item.sumWithoutVAT + "₴" }} 
                                        </span>
                                        </div>
                                    </td>
                                    <td class="text-end" v-if="changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true'" >
                                        <div>
                                        <span
                                            class="form-control bg-light border-0 product-line-price"
                                        >
                                            {{ item.VATsum + "₴" }} 
                                        </span> 
                                        </div>
                                    </td>
                                    <td class="product-removal">
                                        <a @click="remove(index)" class="btn btn-success"
                                        >
                                        {{ $t('Delete') }}</a
                                        >
                                    </td>
                                </tr>
                                <tr id="newForm" style="display: none"></tr>
                                <tr>
                                    <td colspan="9">
                                    <a
                                        @click="add(e)"
                                        class="btn btn-soft-secondary fw-medium"
                                        ><i class="ri-add-fill me-1 align-bottom"></i> {{ $t('Add') }}</a
                                    >
                                    </td>
                                </tr>
                            </tbody>
                            
                            <tr class="border-top border-top-dashed mt-2">
                                <td colspan="5"></td>
                                <td colspan="2" class="p-0">
                                <table
                                    class="table table-borderless table-sm table-nowrap align-middle mb-0"
                                    style="width: 200px"
                                >
                                    <tbody >
                                    <tr style="width: 200px">
                                        <th scope="row">{{ changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true' ? $t('TotalwithoutVAT') : $t('Total') }}</th>
                                        <td style="width: 200px">
                                        <input
                                            style="width: 100px"
                                            type="number"
                                            class="form-control bg-light border-0"
                                            id="cart-subtotal"
                                            placeholder="₴0.00"
                                            :value="totalnoVat()"
                                            readonly
                                        />
                                        </td>
                                    </tr>
                                    <tr v-if="changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true'">
                                        <th scope="row">{{ $t('TotalVat') }}</th>
                                        <td>
                                        <input
                                            style="width: 100px"
                                            type="number"
                                            class="form-control bg-light border-0"
                                            id="cart-tax"
                                            placeholder="₴0.00"
                                            :value="totalVat()"
                                            readonly
                                        />
                                        </td>
                                    </tr>
                                    <tr v-if="changeProv.businessEntity == 'TOV' && changeProv.workWithPDV == 'true'">
                                        <th scope="row">{{ $t('TotalIncludingVAT') }}</th>
                                        <td>
                                        <input
                                            style="width: 100px"
                                            type="number"
                                            class="form-control bg-light border-0"
                                            id="cart-discount"
                                            placeholder="₴0.00"
                                            :value="total()"
                                            readonly
                                        />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!--end table-->
                                </td>
                            </tr>
                            </table>
                            <!--end table-->
                        </div>
                            <div>
                                <label for="invoicenoInput"><b>{{ $t("Comments") }}</b></label>
                                <textarea class="form-control" id="VertimeassageInput" rows="3" v-model="form.comment" placeholder="Введите информацию "></textarea>
                            </div>
                        </div>
                </b-col>
            </b-row>
       </template>
       <template v-slot:footer-bottom>
           <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
           <button type="button" class="btn btn-success" v-on:click="this.perm === true ? editInv() : addInv()">{{ this.perm === true ? $t('Edit') : $t('Add') }}</button>
       </template>
   </modal>
   <dialogOpenVue v-if="showDialogOpen == true " :type="this.type" :obj="this.form" :edit="this.edit" @openFromEdit="openFromEdit" @close="this.showDialogOpen = false; this.dontSave = true; this.$emit('close')" @open="open"></dialogOpenVue>

    <addEvent
        v-if="showAddEvent"
        :obj = "this.form"
        @doBoth = 'open'
        @close="showAddEvent = false; this.dontSave = true; this.$emit('close'); "
        :crItem="crItem"
        :doBoth="this.doBothVar"
        @updateCard="updateCard"
    />

    <dialogChooseVue v-if="showDialogChoose == true" :obj="this.form" @calendar = "calendar" @open="open" @doBoth="doBoth" @close="this.showDialogChoose = false;this.dontSave = true; this.$emit('close')"></dialogChooseVue>
</template>

<script>
import modal from '@/components/modal' 
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Invoices, SuperAdmin, Products, ProvidersProducts } from '@/API.js';
import { storeS } from '@/store.js'
import dialogOpenVue from '@/components/globaltable/actions/dialogOpen.vue';
import addEvent from '@/components/calendar/addEvent'
import dialogChooseVue from '../projects/dialogChoose.vue';
import dbPromise from '../../indexedDB';

let apiServe = new Invoices();
let apiClient = new SuperAdmin();
let apiProducts = new Products()
let apiProviders = new ProvidersProducts()

export default ({
    data(){
        return {
            doBothVar: false,
            showDialogOpen: false,
            showAddEvent: false,
            showDialogChoose: false,
            fixPrice: false,
            payList: [
                {
                    value: 'pendingpay',
                    label: this.$t('pendingpay')
                },
                {
                    value: 'paid',
                    label: this.$t('paidpay')
                },
                {
                    value: 'annul',
                    label: this.$t('annul')
                }
            ],
            businessEntityList: [
                { value: 'physicalperson', label: this.$t('physicalperson') },
                { value: 'FOP', label: this.$t('FOp') },
                { value: 'TOV', label: this.$t('TOv') },
            ],           
            selectedStatus: "",
            selectedPriority: "",
            statusnum: true,
            form: {
                providerId: "",
                sellerName: "",
                sellerPhone: "",
                businessEntity: "",
                economicalSociety: "",
                comment: "",
                payment: {
                    status: ""
                },
                products: []
            },
            paylicense: {
                tariffName: '',
                userSumm: '',
                sumThree: '',
                sumSix: '',
                sumYear: ''
            },
            // for products
            good: '',
            quantityAlt: '',
            priceAlt: '',
            sumProsuct: "", // сума без ПДВ
            sumVat: "", //сума ПДВ
            goodsList: [],
            currency: {
                isoenter: '',
                iso: '',
                course: ''
            },
            crItem: {
                id: '',
                type: 'invoice',
                name: '',
                description: '',
                date: '',
                time: ''
            },
            selectCompany: '',
            objCompany: {},
            сompanies: [],
            dontSave: false,
            providers: [],
            internalSuppliers: [],
            changeProv: {}
        }
    },
    props: ["phone", "editform", "license", 'number', 'obj', 'perm'],
    components: { modal, Multiselect, dialogOpenVue, addEvent, dialogChooseVue },
    created(){
        // внутрішні постачальники
        this.providersStore.forEach(element => {
            if(element.providerType == "internal"){
                var obj = {
                    value: element.providerId, 
                    text: element.name,
                    address: element.address,
                    bank: element.bank,
                    businessEntity: element.businessEntity,
                    email: element.email,
                    erdpu: element.erdpu,
                    iban: element.iban,
                    ipn: element.ipn,
                    mfo: element.mfo,
                    phone: element.phone,
                    providerTitle: element.providerTitle,
                    providerType: element.providerType,
                    workWithPDV: element.workWithPDV
                }
                this.internalSuppliers.push(obj)
            }
        });

        if(this.perm === true) {
            this.form = this.obj
        }
        if(this.number){
            this.form.sellerPhone = this.number
        }
        if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'UAH'){
            this.currency.isoenter = '$';
            this.currency.iso = '₴';
            this.currency.course = this.currencyShop.course.USD
        } else if(this.currencyShop.enteringTheCostOfGoods == 'USD' && this.currencyShop.conclusionOfGoods == 'USD'){
            this.currency.isoenter = '$';
            this.currency.iso = '$';
            this.currency.course = 1;
        }
        var objParams = {
            pagelimit: '1000'
        }
        apiProducts.getAllProducts(objParams).then(result => {
            if(result.status == 'done') {
                for(var item in result.data.items) {
                    this.goodsList.push({
                        label: result.data.items[item].nomenclatureName,
                        value: result.data.items[item].nomenclatureName,
                        price: (result.data.items[item].price*this.currency.course).toFixed(0),     
                        id: result.data.items[item].nomenclatureId
                    })
                }
            }
        })

        // new invoices
        
        // apiProviders.getAllProviders({page: '1', pagelimit: '100', search: '', providerType: 'internal'}).then(result => {
        //     if(result.status == 'done') {
        //         result.data.items.forEach(el => {
        //             this.providers.push({label: el.name, value: el.providerId})
        //         })
        //     } else {
        //         this.$toast.error(this.$t('error') + ` #1400`);
        //     }
        // })

        // new invoices
        
        //перевірка на наявність не закінченних заявок
        dbPromise.getAll('invoice')
        //перевірка на наявність не закінченних заявок

        //вибір дії було зроблено, обробка
        
        this.eventBus.off('openOld')
        this.eventBus.on('openOld', status => {
            if(status.status == true) {
                dbPromise.get(status.form.id, status.form.category) // ставимо галочку, що взяли форму, далі вона видалиться

                this.form = JSON.parse(status.form.form)
                if(status.form.text !== '') {
                    this.perm = status.form.text
                }
            }
        })
        
        this.eventBus.off('createNew')
        this.eventBus.on('createNew', status => {
            if(status.status == true) {
                return
            }
        })
        
        //вибір дії було зроблено, обробка
        
    },
    beforeUnmount() {
        if(this.dontSave == false) {
            if(this.perm) {
                sessionStorage.setItem('perm', this.perm)
            }
            sessionStorage.setItem('actual', JSON.stringify(this.form))
            if(this.form.provider !== '' || this.form.sellerName !== '' || this.form.products.length > 0 || this.form.sellerPhone !== ''  || this.form.businessEntity !== ''  || this.form.economicalSociety !== '' || this.form.comment !== '' || this.form.payment.status !== '') {
                let detail = {
                    dateTime: new Date(),
                    text: sessionStorage.getItem('perm') ? sessionStorage.getItem('perm') : '',
                    category: 'invoice',
                    form: JSON.stringify(this.form)
                }; 

                dbPromise.add(detail)
                //додавання в indexedDB
            }
        }
    },
    methods: {
        addInv() {
            // this.form.sellerName = this.user.name; // добавление имени продавца
            this.form.products.forEach((el) => delete el.name);
            // console.log(this.form)
            if(this.form.providerId == '') {
                this.$toast.error(this.$t('EnterProvider'))
                return
            }
            if(this.form.sellerName == '') {
                this.$toast.error(this.$t('EnterSellerName'))
                return
            }
            if(this.form.sellerPhone == '') {
                this.$toast.error(this.$t('EnterSellerPhone'))
                return
            }
            if(this.form.businessEntity == '') {
                this.$toast.error(this.$t('enterBusinessEntity'))
                return
            }
            if(this.form.comment == '') {
                this.$toast.error(this.$t('enterComment'))
                return
            }
            if(this.form.payment.status == '') {
                this.$toast.error(this.$t('EnterPaymentStatus'))
                return                
            }
            if(this.form.products.length == 0) {
                this.$toast.error(this.$t('enterProducts'))
                return                   
            }
            apiServe.addSale(this.form).then(result => {
                if(result.status === "done"){
                    this.dontSave = true
                    this.$toast.success(this.$t('сreatedT'));
                    if(this.checks['025'] == true && this.checks['026'] == true) {
                        this.form = result.data
                        this.showDialogChoose = true
                    } else if(this.checks['025'] == true) {
                        this.form = result.data
                        this.showDialogOpen = true;
                    } else if(this.checks['026'] == true && this.form.payment.status == 'pendingpay') {
                        this.form = result.data
                        this.crItem.id = this.form.documentId;
                        this.crItem.name = this.$t('invoiceWithSmall');
                        this.crItem.description = this.form.comment;
                        this.showAddEvent = true;
                    }  else {
                        this.$emit('close')
                    }
                    this.$emit('refresh')
                    this.eventBus.emit('saveINV', true)
                } else {
                   switch(result.error[0]){
                    case("provider not found"):
                    this.$toast.error(this.$t("provider_not_found"));
                    break;
                    case('seller phone not found'): 
                    this.$toast.error(this.$t("seller phone not found"));
                    break;
                    case("products not found"): 
                    this.$toast.error(this.$t("products not found"));
                    break;
                   } 
                } // перебор ответов
            })
        },
        calendar(e) {
            this.form = e
            
            this.crItem.id = this.form.documentId;
            this.crItem.name = this.$t('invoiceWithSmall');
            this.crItem.description = this.form.comment;
            this.showAddEvent = true;
            this.showDialogChoose = false

        },
        openFromEdit(e) {
            this.showDialogOpen = false
            this.$emit('closeLast')
            this.dontSave = true
            this.$emit('close')
            this.$emit('open', e)
        },
        editInv() {
            let id = this.form.documentId
            delete this.form.documentId
            if(this.form.providerId == '') {
                this.$toast.error(this.$t('EnterProvider'))
                return
            }
            if(this.form.sellerName == '') {
                this.$toast.error(this.$t('EnterSellerName'))
                return
            }
            if(this.form.sellerPhone == '') {
                this.$toast.error(this.$t('EnterSellerPhone'))
                return
            }
            if(this.form.businessEntity == '') {
                this.$toast.error(this.$t('enterBusinessEntity'))
                return
            }
            if(this.form.comment == '') {
                this.$toast.error(this.$t('enterComment'))
                return
            }
            if(this.form.payment.status == '') {
                this.$toast.error(this.$t('EnterPaymentStatus'))
                return                
            }
            if(this.form.products.length == 0) {
                this.$toast.error(this.$t('enterProducts'))
                return                   
            }
                apiServe.editSale(id, this.form).then(result => {
                    if(result.status === 'done'){
                        this.dontSave = true
                        this.$toast.success(this.$t('alertChanged'));
                        if(this.checks['025'] == true && this.checks['026'] == true) {
                            this.form = result.data
                            this.showDialogChoose = true
                        } else if(this.checks['025'] == true) {
                            this.form = result.data
                            this.showDialogOpen = true;
                        } else if(this.checks['026'] == true && this.form.payment.status == 'pendingpay') {
                            this.form = result.data
                            this.crItem.id = this.form.documentId;
                            this.crItem.name = this.$t('invoiceWithSmall');
                            this.crItem.description = this.form.comment;
                            this.showAddEvent = true;
                        }  else {
                            this.dontSave = true
                            this.$emit('close')
                        }
                    } else {
                        this.$toast.error(this.$t("error"))
                    }//изменнение счёта
                })
            }, 
        
        inputPrice(good){
            this.priceAlt = this.good.price
            this.sumProsuct = (this.priceAlt * this.quantityAlt).toFixed(2)
        },
        add() {
            this.fixPrice = false
            if(this.good.label.length > 0) {
                this.form.products.push(
                    {
                        nomenclatureId: "",
                        quantity: "",
                        priceWithoutVAT: '',
                        sumWithoutVAT: '',
                        VATsum: '',
                        nomenclatureName: this.good.label,
                    })
                this.goodsList.forEach(el => {
                    if (el.label == this.good.label) {
                            this.form.products[this.form.products.length-1].nomenclatureId = el.id;     // доставание id для товара
                            this.form.products[this.form.products.length-1].quantity = this.quantityAlt; 
                            this.form.products[this.form.products.length-1].priceWithoutVAT = this.priceAlt; 
                            this.form.products[this.form.products.length-1].sumWithoutVAT = this.sumProsuct;    //переназначение значений
                        if(this.changeProv.businessEntity == 'TOV' && this.changeProv.workWithPDV == 'true') {
                            console.log("sumVat - >",  this.sumVat);
                            this.form.products[this.form.products.length-1].VATsum = this.sumVat;
                        }
                        this.good = "";
                        this.priceAlt = '';
                        this.sumProsuct = '';
                        this.quantityAlt = ''; // очистка
                    }
                })
            } else {
                this.$toast.error(this.$t("addProduct"))
            }
        },
        remove (index) {
            this.form.products.splice(index, 1)
        },
        payThree(){
            var textitem = 'Поповнення балансу за номером акаунту - ' + this.form.sellerPhone + " для користування програмою обліку SkyService POS ( Оренда ПЗ )" 
            this.goodsList.push(
                {
                    label: textitem,
                    price: (this.paylicense.sumThree).toFixed(2),
                    value: textitem
                }
            )
            this.good = this.goodsList[this.goodsList.length - 1];
            this.inputPrice(this.good);
        },
        paySix(){
            var textitem = 'Поповнення балансу за номером акаунту - ' + this.form.sellerPhone + " для користування програмою обліку SkyService POS ( Оренда ПЗ )" 
            this.goodsList.push(
                {
                    label: textitem,
                    price: (this.paylicense.sumSix).toFixed(2),
                    value: textitem
                }
            )
            this.good = this.goodsList[this.goodsList.length - 1];
            this.inputPrice(this.good);
        },
        payYearly(){
            var textitem = 'Поповнення балансу за номером акаунту - ' + this.form.sellerPhone + " для користування програмою обліку SkyService POS ( Оренда ПЗ )" 
            console.log('gii', this.goodsList);
            for(var item in this.goodsList){
                if(this.goodsList[item].id == 211){
                    this.goodsList[item].label = textitem;
                    console.log(this.paylicense.sumYear)
                    if(this.fixPrice === true) {
                        this.goodsList[item].price = String(Number(this.paylicense.sumYear).toFixed(0)) + ',00';
                    } else {
                        console.log(this.paylicense.sumYear)
                        this.goodsList[item].price = Number(this.paylicense.sumYear).toFixed(2);
                    }
                    this.goodsList[item].value = textitem;

                    this.good = this.goodsList[item]
                    this.inputPrice(this.good)
                }
            }

            // this.goodsList.push(
            //     {
            //         label: textitem,
            //         price: (this.paylicense.sumYear).toFixed(2),
            //         value: textitem
            //     }
            // )
            // this.good = this.goodsList[this.goodsList.length - 1];
            // this.inputPrice(this.good);
        },
        // format(item) {
        //     if (this.form.products[item].pricenovat != '') {
        //         var num = this.form.products[item].pricenovat;
        //         num = Number(num);
        //         var countDecimals = function (value) {
        //         if(Math.floor(value) === value) return 0;
        //             return value.toString().split(".")[1].length || 0; 
        //         }
        //         var decimal = countDecimals(num);
        //         if (decimal < 2) {
        //             num = num.toFixed(2)
        //         }
        //         if (decimal > 2) {
        //             num = num.toFixed(decimal)
        //         }
        //         if (parseInt(num) < 1) {
        //             num = "." + String(num).split(".")[1];
        //         }

        //         this.form.products[item].pricenovat = num;
        //     }
        // },
        change(num){
            var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"]
            numcode.forEach(el => {
                if(num.substr(0, 3) == el ) {
                    num = "38" + num
                }
            })
            apiClient.getCard(num).then(result => {
                if(result.status == "done"){
                    console.log(result.data.сompanies)
                    if(result.data.сompanies) {
                        this.сompanies = result.data.сompanies;
                        this.statusnum = true;
                        this.$toast.success(this.$t('ClientCardReceived'))
                    } else {
                        this.companies = []
                        this.$toast.error(this.$t('Null'))
                        this.statusnum = false
                    };
                    // this.paylicense = res.data.license
                } else {
                    this.statusnum = false
                }
            })    
        },
        getCompany(e){
            apiClient.getCompClient(e).then(result => {
                if(result.status == "done"){
                    this.objCompany = result.data;
                    this.paylicense.tariffName = this.objCompany.companyDataObj.license.tariffName;
                    this.paylicense.userSumm = this.objCompany.companyDataObj.license.userSumm;
                    this.paylicense.sumThree = this.objCompany.companyDataObj.license.discont[3].discont;
                    this.paylicense.sumSix = this.objCompany.companyDataObj.license.discont[6].discont;
                    this.paylicense.sumYear = this.objCompany.companyDataObj.license.discont[12].discont;
                    this.$toast.success(this.$t('CustomerCompanyReceived'));
                } else {
                    // this.statusnum = false
                }
            })
        },
        totalItemSum(){
            // let result = e.quantity * e.pricenovat;
            // e.sumnovat = result.toFixed(2);
            if(this.fixPrice === true) {
                this.priceAlt = Number(String(this.priceAlt).split(',')[0])
                console.log(this.priceAlt)
            }
            this.sumProsuct = (this.priceAlt * this.quantityAlt).toFixed(2);
			return this.sumProsuct
        },
        totalItemVat(item) {
			let result = this.sumProsuct * 0.2;
            this.sumVat = result.toFixed(2);
			return this.sumVat
		},
        totalnoVat(){
            var sum = 0;
            for(var item in this.form.products){
                sum += Number(this.form.products[item].sumWithoutVAT)
            }
            this.form.withoutVAT = Number(sum).toFixed(2)
            return this.form.withoutVAT
        },
        totalVat(){
            var sum = 0;
            for(var item in this.form.products){
                sum += Number(this.form.products[item].VATsum)
            }
            this.form.totalvat = Number(sum).toFixed(2)
            return this.form.totalvat
        },
        total(){
            var sum = 0;
            sum = Number(this.form.withoutVAT) + Number(this.form.totalvat);
            this.form.totalinv = Number(sum).toFixed(2)
            return this.form.totalinv
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + date.getMonth()).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2) + " " + 
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2) + ":" +
            ("00" + date.getSeconds()).slice(-2);
            return dateStr
        },
        doBoth(e) {
            this.form = e
            this.crItem.id = this.form.documentId;
            this.crItem.name = this.$t('invoiceWithSmall');
            this.crItem.description = this.form.comment;
            this.doBothVar = true
            this.showAddEvent = true;
            this.showDialogChoose = false
        },
        open(e) {
            this.showDialogChoose = false
            if(this.doBothVar == true) {
                this.doBothVar = false;
            }
            this.showDialogOpen = false
            this.dontSave = true
            this.$emit('close')
            let type = 'invoice'
            this.$emit('open', e, type)
        },
        getDataProvider(){
            this.$nextTick(() => {
                this.internalSuppliers.forEach(element => {
                    console.log(element, this.form.providerId)
                    if (element.value == this.form.providerId) {
                        this.changeProv = element
                    }
                });
            });
        }
    },

    computed: {
        user() {
            return storeS.userbase
        },
        settingInv(){
            return storeS.invoice
        },
        currencyShop(){
            return storeS.currencyShop
        },
        checks(){
            return storeS.checks
        },
        providersStore() {
            return storeS.providers
        }
    },
})
</script>
