<template>
    <!-- 
        Компонент з додавання об'єктів (заявка, завдання, звернення, тощо) та інші дії 
    -->

    <!-- Секція для користувачів із відповідними дозволами -->
    <div 
        style="top:20px;position: relative;" 
        v-if="perms[101] == true || perms[201] == true || perms[301] == true || perms[351] == true || perms[1701] == true"
    >
        <div class="" id="stepAdd1">
            <!-- Кнопка "Додати" для великих екранів -->
            <button 
                type="button" 
                class="d-none d-lg-flex d-xl-flex btn btn-success dropdown-toggle"
                data-bs-toggle="dropdown" 
                aria-expanded="false"
            >
                {{ $t('Add') }}
            </button>
            
            <!-- Кнопка "Додати" для малих екранів -->
            <button 
                type="button" 
                class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none btn btn-success btn-icon waves-effect " 
                data-bs-toggle="dropdown" 
                aria-expanded="false"
            >
                <i class="bx bx-plus"></i>
            </button>
            
            <!-- Випадаюче меню для додавання об'єктів -->
            <div :class="`dropdown-menu dropdownmenu-success ${ tours[101] ? 'show' : ''}`" id="stepAddDoc">

                <!-- Пункт меню для додавання заявки -->
                <a class="dropdown-item copy" v-if="perms[101] == true" @click="openrequestbox()">{{ $t('Application') }}</a>

                 <!-- Пункт меню для додавання завдання -->
                <a class="dropdown-item copy" v-if="perms[201] == true" @click="opentaskbox()">{{ $t('Task') }}</a>

                <!-- Пункт меню для додавання звернення -->
                <a class="dropdown-item copy" v-if="perms[301] == true" @click="openticketbox()">{{ $t('ticket') }}</a> 
                
                <!-- Розділювач у випадаючому меню -->
                <div class="dropdown-divider" v-if="perms[1701] == true || perms[351] == true"></div>

                <!-- Пункт меню для додавання рахунку -->
                <a class="dropdown-item copy" v-if="perms[1701] == true" @click="openinvoicesbox()">{{ $t('invoic') }}</a> 

                <!-- Пункт меню для додавання проекту -->
                <a class="dropdown-item copy" v-if="perms[351] == true" @click="openprojectbox()">{{ $t('project') }}</a> 
            </div>
        </div>
    </div>

    <!-- Секція для користувачів із дозволом на використання калькулятора -->
    <div v-if="perms[900] == true" style="margin-left:15px;top:20px;position:relative;">
        <b-button variant="success" class="btn-icon ri-calculator-line " @click="opencalculator()"></b-button>
    </div>

    <!-- Секція переходу в проект -->
    <div v-if="perms[666] == true" style="margin-left:15px;top:20px;position:relative;">
        
        <div :class="`${developmentProjects && developmentProjects.length > 0 ? 'btn-group' : ''}`">

            <button type="button" class="btn btn-secondary" >
                <router-link to="/development/projects" >
                    <span class="text-white">{{ $t('navProjects') }}</span>
                </router-link>
            </button>

            <button v-if="developmentProjects && developmentProjects.length > 0" type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" id="dropdownMenuReference"
                data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
                <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuReference">
                <template v-for="item in developmentProjects" :key="item">
                    <li>
                        <router-link :to="`/development/projects/${item.projectId}`" >
                            <a class="dropdown-item fs-14">{{ item.projectName }}</a>
                        </router-link>
                    </li>
                </template>
            </ul>
        </div>

    </div>

    <!-- Секція для користувачів з можливістю виклику телефонії -->
    <div v-if="(checks['006'] && (this.break.statusBreak !== true) && (this.user.statusshift !== 'close'))" class="d-none d-sm-flex" style="margin-left:15px;top:20px;position:relative;" >
        <b-button variant="success" class="btn-icon bx bx-phone " @click="this.$emit('openphone')"></b-button>
    </div>

</template>

<script>
import createapplicBox from '@/views/application/createapplic.vue'
import createsmsBox from '@/views/application/createsms.vue'
import createTaskBox from '@/views/tasks/createtask.vue'
import createTicketBox from '@/views/newtickets/createticket.vue'
import createProjectBox from '@/views/projects/new.vue'
import createInvoicesBox from "@/views/invoices/createinvoices.vue"
import calculator from "@/components/calc_tariff.vue"
import { storeS } from '@/store' // глобальне сховище даних

export default {
    data(){
        return {
            modalRequestBox: false,
            modalSmsbox: false,
            modalTaskBox: false,
            modalTicketBox: false,
            modalProjectBox: false,
            modalInvoicesBox: false,
            modalcalculator: false,
            devProjects: [] // список обраних проєктів
        }
    },
    components: { 
        createapplicBox, 
        createsmsBox, 
        createTaskBox, 
        createTicketBox,
        createProjectBox,
        createInvoicesBox,
        calculator
    },
    created(){
    
    },
    methods:{
        openrequestbox(){
            // Відкриття форми для створення заявки
            this.$emit('openrequestbox')
        },
        opentaskbox(){
            // Відкриття форми для створення завдання
            this.$emit('opentaskbox')
        },
        openticketbox(){
            // Відкриття форми для створення тікету
            this.$emit('openticketbox')
        },
        openinvoicesbox(){
            // Відкриття форми для створення рахунку
            this.$emit('openinvoicesbox')
        },
        openprojectbox(){
            // Відкриття форми для створення проекту
            this.$emit('openprojectbox')
        },
        opencalculator(){
            // Відкриття калькулятора
            this.$emit('opencalculator')
        }
    },
    computed: {
        perms() {
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        tours() {
            // Отримання інформації про тури користувача з глобального стору
            return storeS.tours
        },
        break() {
            // Отримання інформації про перерву користувача з глобального стору
            return storeS.break
        },
        developmentProjects() {
            // Отримання проектів
            return storeS.developmentProjects
        }
    },
}
</script>
