<template>

  <!-- шапка сторінки -->
    <PageHeader :title="title" />

    <!-- 
      Блок з кнопкою для включення інфо-блоків. 
      Є перевірка:
        - Налаштування користувача 001 є true
    -->
    <div class="col-sm-auto" style="margin-bottom:15px;" >
      <button 
        :title="this.$t('onInfoBLocks')"
        id="info" 
        v-if="this.checks['001'] == true"
        @click="infoblockShow = !infoblockShow" 
        type="button" 
        :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
        ><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
      </button>

      <button 
        :title="this.$t('unclosedTicketsShow')"
        id="info" 
        @click="toggleButton('unclosedTicketsShow')" 
        type="button" 
        style="margin-left: 10px;"
        :class="`btn btn-${unclosedTicketsShow == true ? 'primary' : 'success'} waves-effect waves-light`"
        ><i :class="`${unclosedTicketsShow == true ? 'ri-ticket-2-line' : 'ri-team-line'}`"></i>
      </button>

      <button 
        :title="this.$t('unclosedTicketsByWorkersShow')"
        id="info" 
        @click="toggleButton('unclosedTicketsByWorkersShow')" 
        type="button" 
        style="margin-left: 10px;"
        :class="`btn btn-${unclosedTicketsByWorkersShow == true ? 'primary' : 'secondary'} waves-effect waves-light`"
        ><i :class="`${unclosedTicketsByWorkersShow == true ? 'ri-ticket-2-line' : 'bx bxl-dev-to'}`"></i>
      </button>

      <button 
        :title="this.$t('unclosedTicketsByEnvironmentsShow')"
        id="info" 
        @click="toggleButton('unclosedTicketsByEnvironmentsShow')" 
        type="button" 
        style="margin-left: 10px;"
        :class="`btn btn-${unclosedTicketsByEnvironmentsShow == true ? 'primary' : 'warning'} waves-effect waves-light`"
        ><i :class="`${unclosedTicketsByEnvironmentsShow == true ? 'ri-ticket-2-line' : 'ri-device-line'}`"></i>
      </button>

    </div>

    <b-row>
      <!-- Зведення по статусам -->
      <b-col lg="6" v-if="infoblockShow">
          <b-card>
              <div class="card-body" >
                  <h5 class="card-title mb-1 fw-semibold">{{ $t('StatusSummary') }}</h5>
                  <p class="card-text">{{ $t('StatusSummaryTickets') }}</p>
                  <div class="cdc-container_flex mt-4">
                      <vc-donut
                          :size="200"
                          unit="px"
                          :thickness="35"
                          :background="`${statusTheme == 'dark' ? '#212529' : 'white'}`"
                          foreground="#eeeeee"
                          :sections="highlightedSections"
                          @section-mouseenter="setHovered"
                          @section-mouseleave="resetHovered"
                      >
                          <template v-if="hoveredValue != null">
                              <h2>{{ hoveredValue }}%</h2>
                              {{ hoveredLabel }}
                          </template>
                          <template v-else>
                              <h2>{{ sections[sections.length-1].value }}%</h2>
                              {{ sections[sections.length-1].label }}
                          </template>
                      </vc-donut>
                      <div class="cdc-legend w-100" style="flex-direction: column; margin: 0px 0px 0px 1em;">
                          <span 
                              v-for="section in sections" 
                              :key="section.label" 
                              class="cdc-legend-item-section pe-2" 
                              @mouseover="setHovered(section)"
                              @mouseleave="resetHovered"
                          >
                              <div class="d-flex w-100" style="align-items: center;">
                                  <div class="flex-grow-1 ms-2">
                                      <span class="cdc-legend-item">
                                          <span class="cdc-legend-item-color" :style="`background-color: ${section.color};`"></span>
                                          <span class="fw-semibold fs-13">{{ section.label }}</span>
                                      </span>
                                  </div>
                                  <div class="flex-shrink-0 text-end">
                                      <h6 style="margin: 0;">{{ section.count }} <span class="text-muted fs-11">{{ section.value }}%</span></h6> 
                                  </div>
                              </div>
                          </span>
                          <span class="cdc-legend-item-section pe-2" >
                              <div class="d-flex w-100"  style="align-items: center;">
                                  <div class="flex-grow-1 ms-2" >
                                      <span class="cdc-legend-item" ><span class="fw-semibold fs-13" >{{ $t('totalTickets') }}</span></span>
                                  </div>
                                  <div class="flex-shrink-0 text-end" ><h6  style="margin: 0px;">{{ this.information[0].value }}</h6></div>
                              </div>
                          </span>

                      </div>
                  </div>
              </div>
          </b-card>
      </b-col>
    </b-row>
    
    <!-- інфо-блоки -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- <input type="checkbox" :value="true" @click="hideDecided($event)"/> -->

    <!-- 
      Компонент таблиці 
      @search - дія пошуку
      @addCategory - дія додавання табу
      @removeCategory - дія видалення табу
      @create - дія створення тікету
      @open - дія відкриття тікету
      @changeFilter - дія зміни фільтру (табу)
      @clearFilter - дія очистки фільтру
      @remove - дія видалення тікету
      @getdata - дія отримання даних по тікетам
      @changelimit - зміна кількості відображення тікетів
      @changeTab - зміна табу
      :dataParams - пропс базових параметрів
      :objParams - пропс передачі парметрів (фільтрів, що будуть доступні)
      :columns - прпос передачі колонок
      :rows - пропс передачі самих тікетів
      :pages - пропс передачі номеру сторінки
      :showpag - пропс показування пагінації
      :tabs - пропс, що передає інформацію про таби
      :addtabs - дозвіл на додавання своїх табів
      :createdform - пропс на додавання тікетів
    -->
    <tablecustom 
      v-if="!unclosedTicketsShow && !unclosedTicketsByWorkersShow && !unclosedTicketsByEnvironmentsShow"
      @search="searchB"
      @addCategory="showFilterBox = true"
      @removeCategory="removeCategory" 
      @create="createmodal = true"
      @open="open" 
      @changeFilter="changeFilter" 
      @clearFilter="clearFilter" 
      @remove="remove" 
      @getdata="getdata"
      @changelimit="changelimit"
      @changeTab="changeTab"
      @changeWithClearFilter="changeWithClearFilter"
      :dataParams="dataParams"
      :objParams="objParams"
      :columns="columns" 
      :rows="rows" 
      :pages="objPages"
      :showpag="showpag"
      :tabs="othertabs"
      :addtabs="true"
      :createdform="this.perms[301] || this.perms[2000]"
      @sortColumn="sortColumn" 
    />

    <unclodesTickets 
      v-if="unclosedTicketsShow"
      :title="this.$t('unclosedTicketsShow')"
      :objData="unclodesTickets"
    />

    <unclodesTicketsByWorkers 
      v-if="unclosedTicketsByWorkersShow"
      :title="this.$t('unclosedTicketsByWorkersShow')"
      :objData="unclodesTickets"
    />

    <unclodesTicketsByEnvironments 
      v-if="unclosedTicketsByEnvironmentsShow"
      :title="this.$t('unclosedTicketsByEnvironmentsShow')"
      :objData="unclodesTickets"
    />


    <!-- 
      Модалка перегляду тікету
        @close - подія закриття перегляду тікету
        @prevOpen - дія на відкриття минулого тікету
        @nextOpen - дія на відкриття наступного тікету
        @getdata - дія отримання даних по тікетам
        @edit - дія на редагування тікету
        :obj - пропс передачі даних по тікету 
        :ifPrevopen - пропс на стрілочку вліво
        :ifNextopen - пропс на стрілочку вправо
    -->
    <viewbox 
      v-if="showModal" 
      @close="closeModal" 
      @prevOpen="prevOpen" 
      @nextOpen="nextOpen"
      @getdata="getdata"
      @edit="editTicket"
      :obj="obj" 
      :ifPrevopen="ifPrevopen" 
      :ifNextopen="ifNextopen" 
    ></viewbox>

    <!--
      Модалка створення тікету
        @close - закрити створення тікету
        :perm - пропс перму на редагування
        :obj - пропс для зміни тікету, що передає інфу по ньому
    -->
    <createBox 
      v-if="createmodal == true" 
      @close="closeCreate"
      :perm="this.permToEdit" 
      :obj="this.form"
    ></createBox>

    <!--
      Модалка створення табів
        @close - дія закриття табів
        @changeTabs - дія зміни табу
        :othertabs - пропс табів
    -->
    <filterTabs 
      v-if="showFilterBox" 
      @close="showFilterBox = false"
      @changeTabs="changeTabs"
      :othertabs="othertabs"
    >
    </filterTabs>


</template>

<script>
import PageHeader from "@/components/page-header";
import filterTabs from './filterTabs/index.vue'
import tablecustom from '@/components/globaltable/index';
import infoblocks from '@/components/info/blocks'
import { Tickets } from '@/API.js';
import { storeS } from '@/store'
import createBox from '../newtickets/createticket'
import viewbox from '../newtickets/view/index.vue'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateTypeTicket, 
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
      } from '@/usabilityScripts/globalMutate.js'
import unclodesTickets from './unclodesTickets';
import unclodesTicketsByWorkers from './unclodesTicketsByWorkers.vue';
import unclodesTicketsByEnvironments from './unclodesTicketsByEnvironments.vue';
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
    
let apiServe = new Tickets();

export default {
  components: {
    PageHeader,
    tablecustom,
    viewbox,
    createBox,
    Multiselect,
    infoblocks,
    filterTabs,
    unclodesTickets,
    unclodesTicketsByWorkers,
    unclodesTicketsByEnvironments
  },
  data() {
    return {
      showpag: true,
      tour: null,
      checkDec: false,
      title: this.$t('tickets'),
      show_in_preloader: false,
      permToEdit: false,
      createmodal: false,
      infoblockShow: false,
      unclosedTicketsShow: false, // відображення тікетів по клієнтам групами
      unclosedTicketsByWorkersShow: false, // відображення тікетів по розробникам групами
      unclosedTicketsByEnvironmentsShow: false, // відображення тікетів по проблемам групами
      unclodesTickets: {
        items: {}
      },
      showFilterBox: false,
      info: null,
      showModal: false,
      obj: {},
      objPages: {},
      ifNextopen: "",
      ifPrevopen: "",
      dataParams: {
          status: true,
          page: "tickets"
      },
      objParams:{
        page: '1',
        pagelimit: '25',
        search: '',
        priority: '',
        status: '',
        decidedStatus: '',
        type: '',
        workerId: '',
        inJobWorkerId: '',
        problemEnvironment: '',
        pr1: '',
        pr2: '',
        tags: '',
        of: 'create_datetime',
        ot: 'desc'
      },
      columns: [
        {
          name: "ID",
          text: "ticketId",
          align: "left",
          status: true,
          width: '10'
        },
        {
          name: this.$t("ticket"),
          text: "type",
          align: "left",
          mutate: (item) => this.mutateticket(item),
          status: true,
          width: '10'
        },
        {
          name: this.$t("problem_area"),
          text: "problemEnvironment",
          align: "left",
          mutate: (item) => item != null ? this.mutateProblemEnv(item) : '-',
          status: true,
          width: '10'
        },
        {
          name: this.$t("ticket_name"),
          text: "ticketName",
          align: "left",
          status: true,
          mutate: (item) => this.mutateTicketName(item),
          width: '10'
        },
        // {
        //   name: this.$t("description"),
        //   text: "createComment",
        //   align: "left",
        //   status: false
        // },
        {
          name: this.$t("priority"),
          text: "priority",
          align: "right",
          mutate: (item) => this.mutatepriority(item).name,
          mutateClass: (item) => this.mutatepriority(item).color,
          status: true,
          width: '10'
        },
        {
          name: this.$t("added"),
          text: "createWorkerId",
          align: "right",
          mutate: (item) => this.nameWorker(item),
          status: true,
          width: '10'
        },
        {
          name: this.$t("date"),
          text: "createDatetime",
          align: "right",
          status: true,
          sort: 'create_datetime',
          sortType: 'asc',
          width: '10'
        },
        {
          name: this.$t("Status"),
          text: "status",
          align: "right",
          mutate: (item) => this.mutatestatus(item).name,
          mutateClass: (item) => this.mutatestatus(item).color,
          status: true,
          width: '10'
        },
        {
          name: this.$t("decidedStatus"),
          text: "decidedStatus",
          align: "right",
          mutate: (item) => this.mutateDecidedStatus(item).name,
          mutateClass: (item) => this.mutateDecidedStatus(item).color,
          status: true,
          width: '10'
        },
        {
          name: this.$t("clients"),
          text: "userPhones",
          align: "right",
          mutate: (item) => item ? this.mutateNumber(item) : '-',
          status: false,
          width: '10'
        },
        {
          name: this.$t("labels"),
          text: "tags",
          align: "right",
          status: false,
          mutate: (item) => item ? this.nameTag(item) : '-',
          width: '10'
        },
      ],
      othertabs: [],
      colors: [],
      checked: false,
      rows: [],
      information: [
        {
            title: this.$t('totalTickets'),
            value: "0",
            icon: "ri-ticket-2-line",
            color: "info"
        },
        {
            title: this.$t('new'),
            value: "0",
            icon: "ri-ticket-2-line",
            color: "info"
        },
        {
            title: this.$t('statusInWork'),
            value: "0",
            icon: "mdi mdi-timer-sand",
            color: "warning"
        },
        {
            title: this.$t('completedTickets'),
            value: "0",
            icon: "ri-checkbox-circle-line",
            color: "success"
        }
      ],
      hoveredLabel: 'Hover over a section',
      hoveredValue: null,
      activeSection: null,
      sections: [],
    };
  },
  created() {
    if(this.checks['004']){
      // приховування тікетів, що не пов'язані зі мною
      this.getdataForCheck();
    } else {
      this.objParams.pagelimit = storeS.pagelimit ? storeS.pagelimit : this.objParams.pagelimit
      this.getdata();
    }
  },
  methods: {
    generateSections(data) {
      const total = data.total;

      const sections = [
        {
          label: this.$t('new'),
          value: Math.round((data.countNew / total) * 100),
          count: data.countNew,
          color: '#eeeeee',
        },
        {
          label: this.$t('statusInWork'),
          value: Math.round((data.countInJob / total) * 100),
          count: data.countInJob,
          color: '#2684FF',
        },
        {
          label: this.$t('registrationCompleted'),
          value: Math.round((data.countDecided / total) * 100),
          count: data.countDecided,
          color: '#57D9A3',
        },
      ];

      // Розрахунок залишкових значень для секції "Інше"
      const usedCount = data.countNew + data.countDecided + data.countInJob;
      const usedValue = sections.reduce((sum, section) => sum + section.value, 0);

      if (usedCount < total) {
        sections.push({
          label: this.$t('Other'),
          value: 100 - usedValue,
          count: total - usedCount,
          color: '#8777D9',
        });
      }

      return sections;

    },
    setHovered(section) {
        this.hoveredLabel = section.label;
        this.hoveredValue = section.value;
        this.activeSection = section;
    },
    resetHovered() {
        this.hoveredLabel = 'Hover over a section';
        this.hoveredValue = null;
        this.activeSection = null;
    },
    darkenColor(color, amount) {
        const colorValue = color.replace('#', '');
        const num = parseInt(colorValue, 16);
        let r = (num >> 16) - amount * 255;
        let g = ((num >> 8) & 0x00ff) - amount * 255;
        let b = (num & 0x0000ff) - amount * 255;
        r = r < 0 ? 0 : r > 255 ? 255 : r;
        g = g < 0 ? 0 : g > 255 ? 255 : g;
        b = b < 0 ? 0 : b > 255 ? 255 : b;
        return `rgb(${r}, ${g}, ${b})`;
    },
    changeWithClearFilter(name, value) {
      /** 
       * Зміна фільтру з очищенням попередніх
       *
       * @param {string} name - ім'я фільтру
       * @param {string} value - нове значення фільтру
      */

      
      this.objParams = {
        page: '1',
        pagelimit: '25',
        search: '',
        priority: '',
        status: '',
        decidedStatus: '',
        type: '',
        workerId: '',
        inJobWorkerId: '',
        problemEnvironment: '',
        pr1: '',
        pr2: '',
        tags: '',
        of: 'create_datetime',
        ot: 'desc'
      }

      this.objParams[name] = value

      this.getdata()
    },
    // скорочує назву тікету
    mutateTicketName(e) {
      let name = ''
      if(e.length > 50) {
        name = e.slice(0, 46) + '...'
      } else {
        name = e
      }
      return name
    },
    // функція-тур 
    createTour(){
      this.tour = this.$shepherd({
        useModalOverlay: true
      });
      const con = document.getElementById('info');
      this.tour.addStep({
        attachTo: { element: con, on: 'top' },
        text: 'Для перегляду інформаційних блокуів, натисніть сюди',
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Back'
          },
          {
            action: this.tour.next,
            text: 'Next'
          }
        ],
      });
      const ddd = document.getElementById('ddd');
      
      this.tour.addStep({
        id: ddd,
        text: 'Income is up by 40%!! I guess the users who left were dead weight.',
        attachTo: {
            element: ddd,
            on: 'bottom'
        },
        buttons: [{
            text: 'Next, We Scroll!',
            // action: tour.next
        }]
    });

    },
    // очистити фільтр і оновити дані
    clearFilter() {
      this.objParams.priority = ''
      this.objParams.status = ''
      this.objParams.decidedStatus = ''
      this.objParams.type = ''
      this.objParams.workerId =  ''
      this.objParams.inJobWorkerId = ''
      this.objParams.problemEnvironments = []
      this.objParams.pr1 = ''
      this.objParams.pr2 = ''
      this.getdata()
    },
    // змінити ліміт і оновити дані
    changelimit: function(limit){
      this.objParams.pagelimit = limit;
      this.getdata();
        
    },
    // отримання інформації, якщо у людини було налаштування 004
    getdataForCheck(){
      this.objParams.pagelimit = '100'
      this.objParams.inJobWorkerId = this.user.userid
      apiServe.getTickets(this.objParams).then(result => {
        if(result.status === 'done') {
          let objPages1 = result.data
          this.rows = result.data.items;  
          this.objParams.inJobWorkerId = ''
          this.objParams.workerId = this.user.userid
          apiServe.getTickets(this.objParams).then(res => {
            for(var item in objPages1) {
              if(res.data[item]) {
                if(typeof objPages1[item] == 'object') {
                  objPages1[item] = objPages1[item].concat(res.data[item])
                } else {
                  objPages1[item] += res.data[item]
                }
              }
            }
            this.objPages = objPages1
            this.showpag = false // вирубаємо пагінацію
            this.objParams.workerId = ""
            this.objParams.inJobWorkerId = this.user.userid
            // this.dataParams.status = false // вирубаємо фільтри
            this.rows = this.rows.concat(res.data.items)
          })
        } else {
          this.$toast.error(this.$t('error') + ` #300`);
        }
      })
    },
    getUnclosedTickets(){
      // отримання незакритих тікетів по номерам
      this.unclosedTicketsShow = true;
      // var objParamsTickets = {
      //   page: this.objParams.page,
      //   pagelimit: this.objParams.pagelimit,
      //   more: 3
      // }
      
      // apiServe.getUnclosedTickets(objParamsTickets).then(result => {
      //   if(result.status == 'done'){
      //     this.objPages = result.data;
      //     this.unclodesTickets.items = result.data.items;

      //     this.unclosedTicketsShow = true;
      //   } else {

      //   }
      // })
    },
    // отримання інформації
    getdata(page){
      this.showpag = true
      this.dataParams.status = true
      if(this.objParams['tags[]']) {
        delete this.objParams['tags[]']
        this.objParams.tags = ''
      }
      this.objParams.page = page != undefined ? page : '1';
      apiServe.getTickets(this.objParams).then(result => {
        if(result.status === 'done') {
          this.objPages = result.data;
          this.rows = result.data.items;  

          this.information[0].value = result.data.total
          this.information[1].value = result.data.countNew
          this.information[2].value = result.data.countInJob
          this.information[3].value = result.data.countDecided

          this.sections = this.generateSections({
            countDecided: result.data.countDecided,
            countInJob: result.data.countInJob,
            countNew: result.data.countNew,
            total: result.data.total,
          });

          // var checkColumn = this.rows.filter(item => item.problemEnvironment != null).length;
          // if(checkColumn === 0){
          //   this.columns[2].status = false
          // } else {
          //   this.columns[2].status = true
          // }

        } else {
          this.$toast.error(this.$t('error') + ` #300`);
        }
      }).catch(err => {
        this.$toast.error(this.$t('error') + ` # 300`);
      })
    },
    // приховати вирішені тікети
    // hideDecided(event){
    //   if(event.target.value == 'on'){
    //     this.rows = this.rows.filter(item => item.status != 'decided');
    //   } else {
    //     this.getdata();
    //   }
    // },
    // відкрити тікет
    open: function(e){
      var id = e.ticketId ? e.ticketId : e;
      this.ifPrevopen = id == this.rows[0].ticketId ? false : true;
      this.ifNextopen = id == this.rows[this.rows.length-1].ticketId ? false : true;
      
      apiServe.getTicket(id).then(result => {
        if(result.status == 'done'){
          if(!Array.isArray(result.data.userPhones)) {
            this.$toast.error(this.$t('error'));

            var errorText = 'Помилка відкриття тікету. Номери string'; 
            var statusRequest = "error";
            var api = 'getTicket';
            var fileRequest = 'src/views/newtickets/all.vue';
            var params = id;
            var response = result.data.userPhones

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }

          this.obj = result.data;
          this.showModal = true;
        } else {
          this.$toast.error(this.$t('error'));

          var errorText = 'Помилка відкриття тікету'; 
          var statusRequest = result.status;
          var api = 'getTicket';
          var fileRequest = 'src/views/newtickets/all.vue';
          var params = id;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    // закриття модалки з оновленням даних
    closeModal(){
      this.showModal = false
      // this.getdata(this.objParams.page)
    },
    // відкрити минули
    prevOpen(e){
      var current_id = e;
      var prev_id = ""; 

      for(var value in this.rows){
        if(this.rows[value].ticketId == current_id){
          prev_id = this.rows[Number(value)-Number('1')].ticketId;
        }
      }
      this.open(prev_id);
    },
    // відкрити наступне
    nextOpen(e){
      var current_id = e;
      var next_id = "";
      for(var value in this.rows){
        if(this.rows[value].ticketId == current_id){
          next_id = this.rows[Number(value)+Number('1')].ticketId;
        }
      }
      this.open(next_id);
    },
    // закрити модалку створення
    closeCreate(){
      this.permToEdit = false;
      this.createmodal = false
    },
    // запит на редагування тікету
    editTicket(e){
      this.permToEdit = true;
      this.form = e;
      this.createmodal = true;
    },
    // пошук
    searchB(e){
      this.objParams.search = e
      this.getdata();
    },
    sortColumn(e, o){
      // сортування
      this.objParams.of = e.sort
      this.objParams.ot = o == 'desc' ? 'asc' : 'desc'
      this.getdata()
    },
    // змінити таб
    changeTab(name, value){
      console.log('name', name, 'value', value);
      var arrFilter = ['search','status', 'priority', 'type', 'problemEnvironment','workerId', 'inJobWorkerId', 'decidedStatus'];
      if (Array.isArray( value ) == false) {
        for(var item in arrFilter){
          if(arrFilter[item] == name){
            if(value === this.user.userid) { // якщо "мої" тікети, то запускаємо не getData, а getdataForCheck для отримання і створенних, і в роботі
              this.getdataForCheck()
              return
            } else {
              this.objParams[name] = value
            }
          } else {
            if(name == 'pr1') {
              this.objParams.search = '',
              this.objParams.priority = '',
              this.objParams.status = '',
              this.objParams.type = '',
              this.objParams.problemEnvironment = '',
              this.objParams.workerId = '',
              this.objParams.inJobWorkerId = '',
              this.objParams.decidedStatus = ''
              let today = String(new Date()).split(' ');
              if(value == 'today'){
                this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
                this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])+1)
                this.getdata()
              } else if(value == 'yesterday'){
                this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])-1)
                this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2]))
                this.getdata()
              }
              return
            }
            this.objParams[arrFilter[item]] = '';
          }
        }
        this.getdata();
      } else {
        value.forEach(element => {
          for(var item in arrFilter){
            if(element.obj == arrFilter[item]){
              this.objParams[element.obj] = element.value
            } 
          }
        })
        
        let val = value.map(elem => elem.obj);
        let valSet = new Set(val);
        let notVals = arrFilter.filter(elem => !valSet.has(elem));
        for(var item in notVals){
          this.objParams[notVals[item]] = ''
        }
        this.getdata();
      }
    },
    // мутейт місяця
    mounthMutate(e) {
      var c = ''
      switch (e) {
        case 'Feb':
          c = '2'
        break;
        case 'Mar':
          c = '3'
        break;
        case 'Apr':
          c = '4'
        break;
        case 'May':
          c = '5'
        break;
        case 'Jun':
          c = '6'
        break;
        case 'Jul':
          c = '7'
        break;
        case 'Aug':
          c = '8'
        break;
        case 'Sep':
          c = '9'
        break;
        case 'Oct':
          c = '10'
        break;
        case 'Nov':
          c = '11'
        break;
        case 'Dec':
          c = '12'
        break;
        case 'Jan':
          c = '1'
        break;
      }
      return c
    },
    // змінити фільтр
    changeFilter(name, value){
      console.log(...arguments)
      if(name == 'all'){
        this.objParams.search = '',
        this.objParams.priority = '',
        this.objParams.status = '',
        this.objParams.decidedStatus = '',
        this.objParams.type = '',
        this.objParams.problemEnvironment = '',
        this.objParams.workerId = '',
        this.objParams.inJobWorkerId = '',
        this.objParams.pr1 = '',
        this.objParams.pr2 = '';
        this.objParams.tags = '';
        this.getdata();
      } else if(name == 'problemEnvironments'){
        this.objParams.problemEnvironments = JSON.stringify(value)
        this.getdata();
      } else if(name == 'problemEnvironment') {
        this.objParams.problemEnvironments = ''
        this.getdata();
      }else if(!name.type && value != undefined){
        if(name == 'pr'){
          this.objParams['pr1'] = value[0];
          this.objParams['pr2'] = value[1];
        } else if(name == 'tags'){
          this.objParams[name] = value[0] == "" ? '' : JSON.stringify(value); 
        } else {
          this.objParams[name] = value;
        }
        this.getdata();
      } else {
        this.objParams[name] = value;
      }
    },
    // змінити таб (його значення)
    changeTabs(items){
      // for(var item in items){
      //   console.log('item', item);
      //   console.log('items[item', items[item]);
      //   // this.othertabs.push(items[item])
      // }
      // this.othertabs.push(item);
      localStorage.setItem("Ticketscheckbox", JSON.stringify(this.othertabs));
      localStorage.setItem("resultTickets", this.othertabs.length)
      // this.getdata()
    },
    // видалити таб
    removeCategory(e, i){
      this.othertabs.splice(i, 1)
      localStorage.setItem("Ticketscheckbox", JSON.stringify(this.othertabs));
    },
    // мутейт співробітника
    nameWorker(item){
      return nameWorker(item)
    },
    // мутейт телефонів клієнта
    mutateNumber(value){
      var r = []
      if(value != ''){
        for(var item in value){
          r.push('<span class="fs-11 badge badge-soft-info">'+value[item]+'</span>')
        }
      }
      return r
    },  
    mutateticket(e){
      // мутейт типу тікета
      return mutateTypeTicket(e)
    },
    mutatestatus(e){
      // мутейт статусу
      return mutateStatusTicket(e)
    },
    mutateDecidedStatus(e){
      // мутейт типу статусу, коли тікет "Виконано"
      return mutateDecidedStatusTicket(e)
    },
    mutatepriority(e){
      // мутейт пріорітету
      return mutatePriorityTicket(e)
    },
    mutateProblemEnv(e){
      // мутейт середовища проблеми
      return mutateProblemEnv(e)
    },
    nameTag(value){
      var r = []
      if(value != ''){
        for(var item in value){
          r.push('<span class="fs-11 fw-semibold" style="background:' + nameTag(value[item]).color + ';border-radius:5px;padding:5px;color:white;">'+nameTag(value[item]).name+'</span>')
        }
      }
      return r
    },
    toggleButton(button) {
      // Якщо кнопку натискають вдруге — вимикаємо її
      if (this[button]) {
        this[button] = false;
      } else {
        // Скидаємо всі інші кнопки
        this.unclosedTicketsShow = false;
        this.unclosedTicketsByWorkersShow = false;
        this.unclosedTicketsByEnvironmentsShow = false;

        // Вмикаємо лише ту кнопку, яку натиснули
        this[button] = true;
      }
    }
  },
  computed: {
    perms(){
      return storeS.perms
    },
    workers() {
      return storeS.workers
    },
    checks() {
      return storeS.checks
    },
    user(){
      return storeS.userbase
    },
    statusTheme(){
      // Отримання статусу теми
      return storeS.nightTheme
    },
    highlightedSections() {
      return this.sections.map((section) => ({
          ...section,
          color:
          section === this.activeSection
              ? this.darkenColor(section.color, 0.2)
              : section.color,
      }));
    },
  },
  mounted() {
    // гайд
    // this.createTour();
    // this.tour.start();
    // гайд

    // якщо існують тікети в сторі, то дістаємо їх
    this.othertabs = localStorage.getItem("Ticketscheckbox") ? JSON.parse(localStorage.getItem("Ticketscheckbox")) : [];

    // якщо юзер девелопер і не має айді 34, то видаємо йому таб "Мої" на взяття в роботу
    if(this.user.workerJob == 'developer' && this.user.userid != 34) {
      if(this.othertabs[0] == undefined || this.othertabs[0]?.value != this.user.userid){
        this.othertabs.unshift({
          checked: true,
          color: "#1ABC9C",
          obj: "inJobWorkerId",
          tab: "inJobWorkerId"+this.user.userid,
          title: this.$t("My"),
          value: this.user.userid
        })
      }
    } else {
      // видаємо таб "Мої", котрі створені 
      if(this.othertabs[0] == undefined || this.othertabs[0]?.value != this.user.userid){
        this.othertabs.unshift({
          checked: true,
          color: "#1ABC9C",
          obj: "workerId",
          tab: "workerId"+this.user.userid,
          title: this.$t("My"),
          value: this.user.userid
        })
      }
    }

    this.eventBus.off('saveTickets');
    this.eventBus.on('saveTickets', (status) => {
      if(status == true) {
        this.getdata();
        // сокет на збереження тікету
      }
    }),
    
    this.eventBus.off('closeTicket');
    this.eventBus.on('closeTicket', (status) => {
        if(status == true){
            this.eventBus.emit('saveTickets', true)
            this.$emit('close')
        // сокет на закриття тікету
        }
    }),
    this.eventBus.off('openTickets');
    this.eventBus.on('openTickets', (id) => {
      console.log(id)
        if(id != ''){
          this.createmodal = false
          this.open(id)
          // Відкриття тікету
        }
    })
  }
};
</script>

<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.cdc-container_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.legend {
  list-style-type: none;
  padding: 0;
}
.legend li {
  margin: 5px 0;
  font-size: 14px;
}
</style>
