<template>
    <!-- 
        Модальне вікно для доступу працівників
    -->

    <teleport to="body">

        <div class="modal fade show" style="display: block;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content border-0" >

                    <!-- Заголовок модального вікна -->
                    <div class="modal-header p-3 ps-4 bg-soft-success" >
                        <h5 class="modal-title" id="inviteMembersModalLabel">
                            {{$t('Employees')}}
                        </h5>

                        <!-- Кнопка закриття модального вікна -->
                        <button type="button" class="btn-close" @click="$emit('close')"></button>
                    </div>

                    <!-- Тіло модального вікна -->
                    <div class="modal-body p-4" >
                        
                        <!-- Пошукове поле -->
                        <div class="search-box mb-3">
                            <input type="text" class="form-control bg-light border-light" v-model="search" :placeholder="$t('searc_inp')">
                            <i class="ri-search-line search-icon"></i>
                        </div>
                        
                        <!-- Список працівників з можливістю вибору -->
                        <div style="max-height: 257px; overflow: auto;" >
                            <!-- Перевірка, чи є працівники, які відповідають критеріям пошуку -->
                            <ul class="list-group list-group-flush border-dashed" v-if="filterWorkers.length > 0">
                                <!-- Виведення працівників -->
                                <li class="list-group-item ps-0" v-for="(item, index) in filterWorkers" :key="index">
                                    <div class="d-flex align-items-start" >
                                        <div class="flex-grow-1" >
                                            <!-- Ім'я працівника -->
                                            <h5 class="fs-13 mb-0 copy" @click="this.$emit('open', item.workerId)">{{item.workerName}}</h5>
                                        </div>
                                        <div class="flex-shrink-0 ms-2">
                                            <!-- Кнопка додавання або видалення працівника -->
                                            <button 
                                                type="button" 
                                                :class="`btn btn-${item.checked ? 'danger' : 'success' } btn-sm`" 
                                                @click="item.checked ? remove(index) : add(item)"
                                            >
                                                {{ item.checked ? $t('Delete') : (
                                                    this.hideNotForYuraButtons != true ? $t('Add') : $t('Appoint')
                                                ) }}
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            
                            <!-- Виведення повідомлення про відсутність працівників, які відповідають критеріям пошуку -->
                            <b v-else>{{ $t('noWorkersWithName') }}</b>
                        </div>

                    </div>

                    <div class="modal-footer" >
                        <!-- Кнопка для збереження вибраних працівників -->
                        <button 
                            type="button" 
                            class="btn btn-success w-xs"
                            @click="save"
                        >
                            {{ $t('save') }}
                        </button>
                    </div>

                </div>
            </div>
        </div>

    </teleport>

</template>

<script>
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new developmentProjects();

export default {
    props: ["objCard","projectWorkers"],
    data() {
        return {
            assignWorkers: [],
            form: {
                workers: []
            },
            search: '' // Пошук
        }
    },
    created(){
        for(var item in this.workers){
            this.form.workers.push({
                workerId: this.workers[item].workerId,
                workerName: this.workers[item].workerName,
                checked: this.checkWorkers(this.workers[item])
            })
        }
    },
    methods: {
        add(e){
            // Додати працівника до вибраних
            e.checked = true
            this.assignWorkers.push(e)
        },
        remove(index){
            // Видалити працівника з масиву assignWorkers
            const workerToRemove = this.form.workers[index];
            this.assignWorkers = this.assignWorkers.filter(worker => worker.workerId !== workerToRemove.workerId);
            
            // Скинути позначку 'checked' для цього працівника в form.workers
            this.form.workers[index].checked = false;
        },
        save(){
            // Надати доступ вибраним працівникам

            var workersList = {
                workers: []
            }
            
            // Підготовка списку ідентифікаторів працівників з вибраних workersList
            for(var item in this.assignWorkers) {
                var workerItem = this.assignWorkers[item]
                console.log("workerItem >> ", workerItem);
                
                workersList.workers.push(
                    workerItem.workerId
                )
            }

            console.log("workersList > ", workersList)

            apiServe.saveWorkers(this.objCard.projectId, workersList).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Saved'));
                    this.eventBus.emit('UpdateProject', true)
                    this.$emit('close');
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "list": workersList
                    }

                    var errorText = 'Помилка збереження доступу до проєкту'; 
                    var statusRequest = result.status;
                    var api = 'saveWorkers';
                    var fileRequest = 'src/views/development/projects/project/accessWorkers.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        checkWorkers(e){
            // Перевірити, чи вибраний працівник належить до проекту

            var check = "";
            this.projectWorkers.forEach((el) => {
                if(el.workerId === e.workerId) {
                    check = true
                    var json = {
                        workerId: el.workerId,
                        workerName: el.workerName,
                        checked: true
                    }
                    this.assignWorkers.push(json)
                }
            })
            return check
        },
    },
    computed: {
        filterWorkers: function() {
            // Відфільтровані працівники
            return Object.values(this.form.workers)
                // По полю пошуку
                .filter(item => {
                    return this.search == '' || item.workerName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        workers(){
            /*
                Беремо список співробітників з глобального стору.
            */
            return storeS.workers
        }
    }
}
</script>

<style scoped>
.modalshow {
    display: block;
    opacity: 1;
}

.modal {
    background: #2e2e2eb8;
    transition: opacity 0.15s linear;
    backdrop-filter: blur(5px);
}

</style>
