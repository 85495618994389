<!-- оновлення -->

<template>
    
    <!-- 
        Компонент таблиці 
        @search - дія пошуку
        @create - дія створення оновлення
        @open - дія відкриття оновлення
        @clearFilter - дія очистки фільтру
        @getdata - дія отримання даних
        @changelimit - зміна кількості відображення в таблиці
        :dataParams - пропс базових параметрів
        :objParams - пропс передачі парметрів (фільтрів, що будуть доступні)
        :columns - прпос передачі колонок
        :rows - пропс передачі самих оновлень
        :pages - пропс передачі номеру сторінки
        :showpag - пропс показування пагінації
        :createdform - пропс на додавання оновлення
    -->
    <tablecustom 
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="true" 
        :tabs="othertabs"
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        @create="showModalAddNew = true" 
    />

    <!-- 
        Перегляд картки оновлення 
    -->
    <viewbox 
        v-if="showModalview == true" 
        @close="closeModal" 
        :objCard="this.form" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
        :rows="rows" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen" 
        @getdata="this.getdata()"
    ></viewbox>

    <!-- 
        Модальне вікно створення оновлення
    -->
    <newbox 
        v-if="showModalAddNew == true" 
        @close="showModalAddNew = false"
    ></newbox>

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import viewbox from '@/views/changelog/view/index'
import newbox from '@/views/changelog/new.vue'
import { Updates } from '@/API.js';
import { storeS } from '@/store.js'
import { mutateStatusUpdates } from '@/usabilityScripts/globalMutate'

let apiServe = new Updates();

export default {
    props: ["objCard"],
    components: {
        tablecustom,
        viewbox,
        newbox
    },
    data() {
        return {
            form: '',
            showModalview: false,
            showModalAddNew: false,
            ifNextopen: "",
            ifPrevopen: "",
            dataParams: {
                status: false,
                page: "changelog"
            },
            columns: [
                {
                    name: 'ID',
                    text: 'deadlineId',
                    align: 'text',
                    status: false
                },
                {
                    name: this.$t('project'),
                    text: "update",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("version"),
                    text: "version",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Deadline"),
                    text: "deadlineDate",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "left",
                    mutate: (item) => this.mutateStatus(item).name,
                    mutateClass: (item) => this.mutateStatus(item).color,
                    status: true,
                },
                {
                    name: this.$t("created"),
                    text: "createWorkerName",
                    align: "left",
                    status: true,
                }
            ],
            objPages: '',
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                createWorkerId: '',
                update: 'dashboard',
                pr1: '',
                pr2: '',
                of: 'id', //deadline_date
                ot: 'desc' //asc
            },
            rows: [],
            othertabs: [],
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(page){
            // Отримання списку оновлень

            this.objParams.page = page != undefined ? page : '1';
            this.objParams.update = this.objCard.projectKey;

            apiServe.getAllUpdates(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error') + ` #370`);
                }
            })
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        open(e){
            /**
                Функція для відкриття модального вікна з інформацією про оновлення за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор оновлення або об'єкт з інформацією про оновлення.

                Викликає API для отримання інформації про оновлення.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModalview в true для відображення модального вікна.

                Для відображення стрілочок перемикання вперед та назад - 
                * ifPrevopen - перевіряється, чи є ще картка оновлення, до поточної відкритої картки
                * ifNextopen - перевіряється, чи є ще картка оновлення, після поточної відкритої картки
            */

            // Визначення ідентифікатора оновлення
            var id = e.deadlineId ? e.deadlineId : e;

            // Перевірка, чи оновлення є першим у списку для відкриття вікна "Попереднє оновлення".
            this.ifPrevopen = id == this.rows[0].deadlineId ? false : true;

            // Перевірка, чи оновлення є останнім у списку для відкриття вікна "Наступне оновлення".
            this.ifNextopen = id == this.rows[this.rows.length-1].deadlineId ? false : true;

            apiServe.getUpdate(id).then(result =>{
                if(result.status == 'done'){
                    this.form = result.data
                    this.showModalview = true
                } else {
                    this.$toast.error(this.$t("error"))
                }
            })
        },
        prevOpen(e){
            /*
                Функція для відкриття модального вікна попереднього оновлення у списку
                Отримує ідентифікатор поточного оновлення, знаходить ідентифікатор попереднього оновлення в списку та викликає функцію open для відображення інформації про оновлення.
            */

            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].deadlineId == current_id){
                    prev_id = this.rows[Number(value)-Number('1')].deadlineId;
                }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            /*
                Функція для відкриття модального вікна наступного оновлення у списку
                Отримує ідентифікатор поточного оновлення, знаходить ідентифікатор наступного оновлення в списку та викликає функцію open для відображення інформації про оновлення.
            */

            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
                if(this.rows[value].deadlineId == current_id){
                    next_id = this.rows[Number(value)+Number('1')].deadlineId;
                }
            }
            this.open(next_id);
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showModalview в false та викликає функцію getdata для оновлення даних таблиці.
            */

            this.showModalview = false;
            this.getdata(this.objParams.page);
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за версією
            */

            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.update = '',
                this.objParams.createWorkerId = '',
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else if(name == 'workerId') {
                    this.objParams['createWorkerId'] = value;
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        mutateStatus(item) {
            // Мутейт статусу
            return mutateStatusUpdates(item)
        },
    },
    mounted() {

        // Додаємо таб Нові todo
        
        // Додаємо таб Предреліз todo

        // Додаємо таб В релізі todo

        this.eventBus.off('saveUpdate');
        this.eventBus.on('saveUpdate', (status) => {
            if(status == true) {
                this.getdata();
            }
        })
    },
    computed: {
        perms() {
            return storeS.perms
        }
    },
}
</script>