<!-- тікети -->

<template>
    
    <!-- 
      Компонент таблиці 
      @search - дія пошуку
      @addCategory - дія додавання табу
      @removeCategory - дія видалення табу
      @create - дія створення тікету
      @open - дія відкриття тікету
      @changeFilter - дія зміни фільтру (табу)
      @clearFilter - дія очистки фільтру
      @remove - дія видалення тікету
      @getdata - дія отримання даних по тікетам
      @changelimit - зміна кількості відображення тікетів
      @changeTab - зміна табу
      :dataParams - пропс базових параметрів
      :objParams - пропс передачі парметрів (фільтрів, що будуть доступні)
      :columns - прпос передачі колонок
      :rows - пропс передачі самих тікетів
      :pages - пропс передачі номеру сторінки
      :showpag - пропс показування пагінації
      :tabs - пропс, що передає інформацію про таби
      :addtabs - дозвіл на додавання своїх табів
      :createdform - пропс на додавання тікетів
    -->
    <tablecustom 
        @search="searchB"
        @open="open" 
        @changeFilter="changeFilter" 
        @clearFilter="clearFilter" 
        @getdata="getdata"
        @changelimit="changelimit"
        @changeTabNew="changeTabNew"
        @changeWithClearFilter="changeWithClearFilter"
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="showpag"
        @sortColumn="sortColumn" 
        :addtabs="true"
        @addCategory="showFilterBox = true"
        :tabs="tableTabs"
        :verTabs="'new'"
        @editTab="editTab"
        @removeTab="removeTab"
    />

    <!--
      Модалка створення табів
        @close - дія закриття табів
        @changeTabs - дія зміни табу
        :othertabs - пропс табів
    -->
    <filterTabs 
        v-if="showFilterBox" 
        @close="showFilterBox = false"
        @saveTab="saveTab"
        :editPermFilter="editPermFilter"
        :objEditTab="objEditTab"
    />

    <!-- 
      Модальне вікно підтвердження видалення повідомлення
      - йде перевірка showDialogRemove
      - @close - подія закриття модалки
      - @close - подія видалення повідомлення
    -->
    <removeModal
      v-if="showDialogRemove" 
      @close="showDialogRemove = false" 
      @remove="remove"
    >
    </removeModal>

    <!-- 
        Модалка перегляду тікету
        @close - подія закриття перегляду тікету
        @prevOpen - дія на відкриття минулого тікету
        @nextOpen - дія на відкриття наступного тікету
        @getdata - дія отримання даних по тікетам
        @edit - дія на редагування тікету
        :obj - пропс передачі даних по тікету 
        :ifPrevopen - пропс на стрілочку вліво
        :ifNextopen - пропс на стрілочку вправо
    -->
    <viewbox 
        v-if="showModal" 
        @close="closeModal" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen"
        @getdata="getdata"
        @edit="editTicket"
        :obj="form" 
        :ifPrevopen="ifPrevopen" 
        :ifNextopen="ifNextopen" 
    ></viewbox>

</template>

<script>
import tablecustom from '@/components/globaltable/index';
import removeModal from '@/components/modal/modalremove.vue' // Діалогове вікно підтвердження видалення
// import createBox from '../newtickets/createticket'
import viewbox from '@/views/newtickets/view/index.vue'
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { mutateTypeTicket, 
        mutateStatusTicket,
        mutateDecidedStatusTicket,
        mutatePriorityTicket,
        mutateProblemEnv,
        nameTag
      } from '@/usabilityScripts/globalMutate.js'
import { Tickets } from '@/API.js';
import { storeS } from '@/store'
import filterTabs from './filterTabs.vue';

let apiServe = new Tickets();

export default {
    props: ["objCard", "type"],
    components: {
        tablecustom,
        viewbox,
        filterTabs,
        removeModal
    },
    data() {
        return {
            form: '',
            showpag: false,
            showModal: false, // відображення вікна тікету
            dataParams: {
                status: false,
                page: "tickets"
            },
            objParams: {
                page: '1',
                pagelimit: '25',
                search: '',
                priority: '',
                status: '',
                decidedStatus: '',
                workerId: '',
                inJobWorkerId: '',
                pr1: '',
                pr2: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateTicketName(item),
                    width: '10'
                },
                // {
                //   name: this.$t("description"),
                //   text: "createComment",
                //   align: "left",
                //   status: false
                // },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerId",
                    align: "right",
                    mutate: (item) => this.nameWorker(item),
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("date"),
                    text: "createDatetime",
                    align: "right",
                    status: true,
                    sort: 'create_datetime',
                    sortType: 'asc',
                    width: '10'
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("decidedStatus"),
                    text: "decidedStatus",
                    align: "right",
                    mutate: (item) => this.mutateDecidedStatus(item).name,
                    mutateClass: (item) => this.mutateDecidedStatus(item).color,
                    status: true,
                    width: '10'
                },
            ],
            showFilterBox: false,
            objEditTab: {},
            editPermFilter: false,
            tableTabs: [],
            showDialogRemove: false, // прапорець відображення вікна видалення табу
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку тікетів з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список тікетів) та objPages (інформація про сторінки).
            */

            this.showpag = true

            this.objParams.page = page != undefined ? page : '1';

            this.objParams.type = this.type;
            this.objParams.problemEnvironment = this.objCard.projectKey;

            apiServe.getTickets(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.objPages = result.data;
                    this.rows = result.data.items;  
                } else {
                    this.$toast.error(this.$t('error') + ` #300`);
                }
            }).catch(err => {
                this.$toast.error(this.$t('error') + ` # 300`);
            })
        },
        open: function(e){
             /**
                Функція для відкриття модального вікна з інформацією про тікет за його ідентифікатором.
                * @param {Object|String} e - Ідентифікатор тікету або об'єкт з інформацією про тікет.

                Викликає API для отримання інформації про тікет.
                Результат присвоюється властивості objcard, та встановлюється прапорці showModal в true для відображення модального вікна.

                Для відображення стрілочок перемикання вперед та назад - 
                * ifPrevopen - перевіряється, чи є ще картка тікету, до поточної відкритої картки
                * ifNextopen - перевіряється, чи є ще картка тікету, після поточної відкритої картки
            */

            // Визначення ідентифікатора тікету.
            var id = e.ticketId ? e.ticketId : e;

            // Перевірка, чи тікет є першим у списку для відкриття вікна "Попередній тікет".
            this.ifPrevopen = id == this.rows[0].ticketId ? false : true;

            // Перевірка, чи тікет є останнім у списку для відкриття вікна "Наступний тікет".
            this.ifNextopen = id == this.rows[this.rows.length-1].ticketId ? false : true;
            
            apiServe.getTicket(id).then(result => {
                if(result.status == 'done'){

                    if(!Array.isArray(result.data.userPhones)) {
                        this.$toast.error(this.$t('error'));

                        var errorText = 'Помилка відкриття тікету. Номери string'; 
                        var statusRequest = "error";
                        var api = 'getTicket';
                        var fileRequest = 'src/views/newtickets/all.vue';
                        var params = id;
                        var response = result.data.userPhones

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }

                    this.form = result.data;
                    this.showModal = true;
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка відкриття тікету'; 
                    var statusRequest = result.status;
                    var api = 'getTicket';
                    var fileRequest = 'src/views/newtickets/all.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        closeModal(){
            /*
                Функція для закриття модального вікна.
                Встановлює прапорці showModal в false, та викликає функцію getdata для оновлення даних таблиці.
            */
            this.showModal = false
            this.getdata(this.objParams.page);
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
                - пошук працює за ...
            */

            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */

            console.log(...arguments)
            if(name == 'all'){
                this.objParams.search = '',
                this.objParams.priority = '',
                this.objParams.status = '',
                this.objParams.decidedStatus = '',
                this.objParams.workerId = '',
                this.objParams.inJobWorkerId = '',
                this.objParams.pr1 = '',
                this.objParams.pr2 = '';
                this.objParams.tags = '';
                this.getdata();
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else if(name == 'tags'){
                    this.objParams[name] = value[0] == "" ? '' : JSON.stringify(value); 
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            } else {
                this.objParams[name] = value;
            }
        },
        // змінити ліміт і оновити дані
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        changeTabNew(item){
            console.log("changeTabNew", item);

            // Зберігаємо значення ключів, які не потрібно очищати
            const retainedKeys = ['of', 'ot', 'page', 'pagelimit', 'problemEnvironment'];
            const retainedValues = {};

            // Зберігаємо значення ключів, які залишаємо
            for (const key of retainedKeys) {
                if (this.objParams.hasOwnProperty(key)) {
                    retainedValues[key] = this.objParams[key];
                }
            }

            // Очищення всіх фільтрів
            for (const key in this.objParams) {
                if (this.objParams.hasOwnProperty(key)) {
                    this.objParams[key] = '';
                }
            }

            // Відновлення значень для ключів, які залишаємо
            for (const key in retainedValues) {
                if (retainedValues.hasOwnProperty(key)) {
                    this.objParams[key] = retainedValues[key];
                }
            }

            // Оновлення objParams відповідно до нових фільтрів
            const newFilters = item.filters;
            for (const key in newFilters) {
                if (newFilters.hasOwnProperty(key) && this.objParams.hasOwnProperty(key)) {
                    this.objParams[key] = newFilters[key];
                }
            }

            // Отримуємо дані з новими параметрами фільтру
            this.getdata()            
        },
        saveTab(item){
            // Додаємо або змінюємо таб

            if(this.editPermFilter == false) {
                this.tableTabs.push(item)
                this.$toast.success(this.$t('Added'))
            } else {
                // Знаходимо індекс табу для редагування
                const tabIndex = this.tableTabs.findIndex(
                    (tab) => tab.settingTab.nameTab === this.objEditTab.settingTab.nameTab
                );

                if (tabIndex !== -1) {
                    // Оновлюємо таб
                    this.tableTabs[tabIndex] = item;
                }

                this.$toast.success(this.$t('alertChanged'))
            }
        
            localStorage.setItem("devProject" + this.objCard.projectName + "Problem", JSON.stringify(this.tableTabs));
            localStorage.setItem("resultDevProject" + this.objCard.projectName + "Problem", this.tableTabs.length)

            this.closeModalFilterTab()
        },
        editTab(item) {
            // Редагування табу

            this.objEditTab = item;
            this.editPermFilter = true;
            this.showFilterBox = true
        },
        removeTab(item){
            // Видалення табу (виклик вікна підтвердження)

            this.removeTabObj = item;
            this.showDialogRemove = true
        },
        remove(){
            // Видаляємо таб

            // Знаходимо індекс табу для редагування
            const tabIndex = this.tableTabs.findIndex(
                (tab) => tab.settingTab.nameTab === this.removeTabObj.settingTab.nameTab
            );
            this.tableTabs.splice(tabIndex, 1);

            localStorage.setItem("devProject" + this.objCard.projectName + "Problem", JSON.stringify(this.tableTabs));
            localStorage.setItem("resultDevProject" + this.objCard.projectName + "Problem", this.tableTabs.length)
            this.$toast.success(this.$t('Removed'))
            this.closeModalFilterTab()
        },
        closeModalFilterTab(){
            // Закрити вікно табу
            this.editPermFilter = false;
            this.objEditTab = false;
            this.showFilterBox = false;
            this.removeTabObj = {};
            this.showDialogRemove = false;
        },
        // скорочує назву тікету
        mutateTicketName(e) {
            let name = ''
            if(e.length > 50) {
                name = e.slice(0, 46) + '...'
            } else {
                name = e
            }
            return name
        },
        // мутейт співробітника
        nameWorker(item){
            return nameWorker(item)
        },
        mutateticket(e){
        // мутейт типу тікета
            return mutateTypeTicket(e)
        },
        mutatestatus(e){
        // мутейт статусу
            return mutateStatusTicket(e)
        },
        mutateDecidedStatus(e){
        // мутейт типу статусу, коли тікет "Виконано"
            return mutateDecidedStatusTicket(e)
        },
        mutatepriority(e){
        // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutateProblemEnv(e){
        // мутейт середовища проблеми
            return mutateProblemEnv(e)
        },
    },
    mounted() {
        
        // якщо існують таби в сторі, то дістаємо їх 
        this.tableTabs = localStorage.getItem("devProject" + this.objCard.projectName + "Problem") ? JSON.parse(localStorage.getItem("devProject" + this.objCard.projectName + "Problem")) : [];
    },
    computed: {
        perms(){
            return storeS.perms
        },
        workers() {
            return storeS.workers
        },
        checks() {
            return storeS.checks
        },
        user(){
            return storeS.userbase
        }
    },
}
</script>