<!-- Календар -->

<template>

    <div class="card">
        <div class="card-body bg-soft-info">
            <div class="d-flex">
                <div class="flex-shrink-0">
                    <CalendarIcon
                        class="text-info icon-dual-info"
                    ></CalendarIcon>
                </div>
                <div class="flex-grow-1 ms-3">
                    <h6 class="fs-15">{{ $t('WelcometoYourCalendar') }}</h6>

                    <!-- <p class="text-muted mb-0">
                        {{ $t('EventThatApplicationsBook') }}
                    </p> -->
                </div>
                <div class="flex-grow-4 ms-3">
                    <!-- Фільтр -->
                    <!-- <Filter 
                        v-if="objParams && (dataParams && dataParams.status == true)" 
                        :dataParams="dataParams" 
                        :objParams="objParams" 
                        @changeFilter="changeFilter" 
                        @clearFilter="clearFilter"  
                    /> -->
                </div>
            </div>
        </div>
    </div>

    <b-row>
        <b-col lg="12">
            <b-card>
                <FullCalendar :options="calendarOptions" ref="fullCalendar" :key="componentKey"/>
            </b-card>
        </b-col>
    </b-row>

    <!-- Перегляд картки завдання -->
    <viewTask 
        v-if="showTask"
        @close="showTask = false;getdata()"
        :objCard="this.form"
        :columnArr="columnArr"
        :projectId="objCard.projectId"
        :added="addedTask"
    />

</template>

<script>
import { ref, onMounted } from "vue";
// імпорт бібліотек та компонентів для використання FullCalendar
import "@fullcalendar/core/vdom";
import { CalendarIcon } from "@zhuowenli/vue-feather-icons";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import FullCalendar from "@fullcalendar/vue3";
import ruLocale from '@fullcalendar/core/locales/ru';
import ukLocale from '@fullcalendar/core/locales/uk';

import viewTask from '../board/viewTask' // Перегляд картки завдання
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new developmentProjects();

export default {
    props: ["objCard"],
    components: {
        FullCalendar,
        CalendarIcon,
        viewTask
    },
    data() {
        return {
            form: {
                columnId: "",
                createWorkerId: "", // id автора
                createWorkerName: "", // автор
                deadlineDatetime: null,
                description: "", // опис
                priority: 0, // пріоритет
                taskName: "", // назва завдання
                ticketId: null, // тікет,
                parentCategoryId: null,
                sort: 0,
                projectId: ''
            },
            addedTask: false, // створення чи перегляд таски
            componentKey: 0,
            showTask: false, // блок перегляду таски
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                priority: '',
            },
            calendarOptions: { // параметри для відображення календрарю
                locale: ukLocale, // локалізація
                events: [], // масив подій
                start: null,
                end: null,
                activeDate: null,
                plugins: [
                    dayGridPlugin, // плагін перегляду подій по дням
                    timeGridPlugin, // плагін перегляду події по годинам
                    interactionPlugin, // плагін для відслідковування кліків, розмірів та інше
                    bootstrapPlugin, // тема календаря
                    listPlugin, // плагін перегляд списку відображає події у простому вертикальному списк
                ],
                themeSystem: "bootstrap", // тема календаря
                headerToolbar: { // // кнопки для перемикання між переглядами
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                },
                windowResize: () => { // автоматична адаптація під потрібний розмір
                    "dayGridMonth";
                },
                initialView: "dayGridMonth", // початковий вигляд календаря
                initialEvents: [],
                editable: true, // прапорець чи можна змінювати події в календарі
                selectable: true, // прапорець щоб дозволити користувачу робити вибір, клацаючи та перетягуючи
                selectMirror: true,
                dayMaxEvents: true, 
                weekends: true, // вихідні дні
                dateClick: this.dateClicked, // запускається коли користувач натискає на дату або час
                eventClick: this.openEvent, // запускається коли користувач натискає на подію
                eventChange: this.moveEvent, // викликається після певної зміни події
                aspectRatio: 2.6,
            },
            newEventData: '', // Дата створення (при кліку на день)
            columnArr: [] // масив колонок
        }
    },
    created(){
        // ... ініціалізація компонента при створенні
        if(storeS.lang == 'uk'){
            this.calendarOptions.locale = ukLocale
        } else if(storeS.lang == 'ru'){
            this.calendarOptions.locale = ruLocale
        }

        // отримання даних для календаря
        this.getdata()
        console.log('ts', this);
        this.columnArr = this.transformColumns();
        
    },
    methods: {
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку завдань з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows (список завдань) та objPages (інформація про сторінки).
            */

            this.calendarOptions.events = [];

            this.objParams.page = page != undefined ? page : '1';

            apiServe.getTasks(this.objParams).then(result => {
                if(result.status == 'done') {
                    
                    for(var item in result.data.items){
                        if(result.data.items[item].deadlineDatetime != null){
                            this.calendarOptions.events.push(
                                {
                                    id: result.data.items[item].taskId,
                                    title: result.data.items[item].taskName,
                                    start: result.data.items[item].deadlineDatetime,
                                    end: result.data.items[item].deadlineDatetime,
                                    description: result.data.items[item].taskName,
                                    idOper: result.data.items[item].taskId,
                                    status: result.data.items[item].status,
                                    className: this.mutateTaskStyle(result.data.items[item].deadlineDatetime),
                                }
                            )
                        }
                    }

                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання завдань в календар'; 
                    var statusRequest = result.status;
                    var api = 'getTasks';
                    var fileRequest = 'src/views/development/projects/project/tabs/calendar/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        moveEvent(info){
            /**
                * Метод для переміщення події
                * @returns {Object} info - параметри події
            */

            var taskId = info.event.extendedProps.idOper

            var form = {
                deadlineDatetime: this.moveDateTime(info.event.startStr).dateStr
            }

            apiServe.changeDeadlineTime(taskId, form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.getdata();
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "id": taskId,
                        "params": form
                    }

                    var errorText = 'Помилка редагування дедлайну завдання'; 
                    var statusRequest = result.status;
                    var api = 'changeDeadlineTime';
                    var fileRequest = 'src/views/development/projects/project/tabs/calendar/index.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        moveDateTime(date){
            /**
                * Метод для перетворення об'єкта дати/часу в рядок дати та рядок часу.
                * @param {string} date - Рядок дати/часу для перетворення.
                * @returns {Object} - Об'єкт із рядком дати та рядком часу.
            */

            var date3 = new Date(date);  
            var dateStr = date3.getFullYear() + "-" + ("00" + (date3.getMonth() + 1)).slice(-2) + "-" + ("00" + date3.getDate()).slice(-2);
            var timeStr = ("00" + date3.getHours()).slice(-2) +":"+("00" + date3.getMinutes()).slice(-2)
            return { dateStr, timeStr};
        },
        dateClicked(info) {
            /**
                * Метод для обробки кліку на дату у календарі.
                * @param {Object} info - Об'єкт із інформацією про клікнуту дату.
            */

            const closestNegativeColumn = this.columnArr
                .filter(col => col.value < 0) // Вибираємо тільки колонки з від'ємними значеннями
                .reduce((closest, current) => {
                    return Math.abs(current.value) < Math.abs(closest.value) ? current : closest;
                });

            console.log("closestNegativeColumn.", closestNegativeColumn);

            this.form.columnId = closestNegativeColumn.value
            this.form.createWorkerId = this.user.userid
            this.form.createWorkerName = this.user.name
            this.form.deadlineDatetime = info.dateStr
            this.form.projectId = this.objCard.projectId

            this.addedTask = true
            this.showTask = true
        },
        mutateTaskStyle(deadline){
            // Застосовуємо стилі

            const now = new Date();
            const deadlineDate = new Date(deadline);
            const diffTime = deadlineDate - now;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Різниця в днях

            if (diffDays <= 7) {
                return "bg-danger text-white"; // якщо до дедлайну менше тижня
            } else {
                return "bg-info text-white"; // якщо до дедлайну більше тижня
            }
        },
        transformColumns(columns){
            return this.objCard.columns.map(column => ({
                label: column.columnName,  // Назва колонки
                value: column.columnId,    // ID колонки
                color: column.color        // Колір
            }));
        },
        openEvent(info){
            /**
                Функція для відкриття модального вікна з інформацією про подію.
                * @param {Object} info - об'єкт картки події

                Результат присвоюється властивості objEvent, та встановлюється прапорці showOpenModal в true для відображення модального вікна.
            */
            // відкриття таски

            apiServe.getTask(info.event.id).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    this.addedTask = false
                    this.showTask = true
                } else {
                    this.$toast.error(result.error[0])
                }
            })
            // this.objEvent = this.obj
            // this.showOpenModal = true
        },
        getInitialView() {
            /**
                * Метод для отримання початкового вигляду календаря.
                * @returns {string} - Початковий вигляд календаря залежно від ширини вікна.
            */

            if (window.innerWidth >= 768 && window.innerWidth < 1200) {
                return "timeGridWeek";
            } else if (window.innerWidth <= 768) {
                return "listMonth";
            } else {
                return "dayGridMonth";
            }
        },
        
    },
    mounted(){
        setTimeout(() => {
            this.componentKey += 1;
        }, 500);
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    },
}
</script>
