<!-- Модальне вікно створення проекту -->

<template>

    <modal @close="this.$emit('close')" :key="this.componentKey">
    
        <!-- Слот шапки модального вікна  -->
        <template v-slot:title>{{ $t('Створити проект') }}</template>
    
        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <!-- Кнопка для закриття модального вікна та збереження інформації, щоб продовжити її потім -->
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"><i class="ri-close-fill align-bottom"></i></button>
        </template>
    
        <template v-slot:body>
            <div class="row g-3" >
                
                <!-- Назва проєкту -->
                <div class="col-lg-12" >
                    <label for="projectName-field" class="form-label">{{ $t('projectTitle') }}</label>
                    <input 
                        type="text" 
                        id="projectName-field" 
                        class="form-control" 
                        v-model="form.projectName" 
                        @input="transliterateToLatin(form.projectName)" 
                        placeholder="Вкажіть назву проекту, або назву команди" required=""
                    >
                </div>
                
                <!-- Ключ проєкту -->
                <div class="col-lg-12" >
                    <div >
                        <label for="tasksTitle-field" class="form-label">{{ $t('key') }}</label>
                        <input type="text" id="tasksTitle-field" v-model="form.projectKey" class="form-control " >
                    </div>
                </div>

                <!-- Керівник проєкту -->
                <div class="col-lg-12" >
                    <div >
                        <label for="tasksTitle-field" class="form-label">{{ $t('projectLead') }}</label>
                        <!-- projectManagerId -->
                        <Multiselect
                            v-model="form.projectManagerId"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.listWorkers"
                            :placeholder="$t('projectLead')"
                        ></Multiselect>
                    </div>
                </div>

                <!-- Тип доступу -->
                <div class="col-lg-12" >
                    <div >
                        <label for="tasksTitle-field" class="form-label">{{ $t('Access') }}</label>
                        <b-form-select
                            v-model="form.access"
                            :options="accessTypes"
                            class="form-control"
                        >
                        </b-form-select>
                    </div>
                </div>

                <!-- Кому надати доступ -->
                <div class="col-lg-12" v-if="form.access == 'private'">
                    <label class="form-label">{{ $t('GrantAccess') }}</label>
                    <input type="text" class="form-control mb-3" v-model="search" placeholder="Пошук">
                    <div style="height: 150px;overflow:auto;">
                        <template v-if="Object.keys(filteredGroupedWorkers).length > 0">
                            <div v-for="(group, job) in filteredGroupedWorkers" :key="job">

                                <!-- Посада -->
                                <span class="badge bg-info fs-12">{{ mutateWorkerJob(job) }}</span>

                                <!-- Список співробітників -->
                                <ul class="list-unstyled vstack gap-2 mb-2 mt-2">
                                    <li v-for="worker in group" :key="worker.workerId">
                                        <div class="form-check d-flex align-items-center" >
                                            <input 
                                                class="form-check-input me-3" 
                                                type="checkbox" 
                                                :id="'worker-' + worker.workerId"
                                                :value="worker.workerId"
                                                v-model="form.workers"
                                                @change="updateWorkers(worker.workerId, $event.target.checked)"
                                            >
                                            <label class="form-check-label d-flex align-items-center" :for="'worker-' + worker.workerId">
                                                <span class="flex-grow-1 ms-2">{{ worker.workerName }}</span>
                                            </label>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </template>
                        <template v-else>
                            <p class="text-center text-muted">{{ $t('workersNotFound') }}</p>
                        </template>
                        
                    </div>
                </div>
            </div>

        </template>

        <template v-slot:footer-bottom>

            <div class="mt-2">

                <!-- Редагування проєкту -->
                <button 
                    v-if="editPerm"
                    type="button" 
                    class="btn btn-success" 
                    @click="add()"
                >
                    {{ $t('Edit') }}
                </button>

                <!-- Створення проєкту -->
                <button 
                    v-if="!editPerm"
                    type="button" 
                    class="btn btn-success" 
                    @click="add()"
                >
                    {{ $t('Add') }}
                </button>

            </div>

        </template>
        
    </modal>
    
</template>

<script>
import modal from '@/components/modal/dialog' // модальне вікно для створення завдання
import Multiselect from "@vueform/multiselect";
import { storeS } from '@/store.js' // Глобальне сховище даних
import { developmentProjects } from '@/API.js';
import { mutateWorkerJob } from '@/usabilityScripts/globalMutate.js'

let apiServe = new developmentProjects();

export default {
    props: ["objCard", "editPerm"],
    components: {
        modal,
        Multiselect
    },
    data() {
        return {
            search: '',
            form: {
                projectName: "",
                projectKey: "",
                access: "public",
                projectManagerId: "",
                workers: []
            },
            accessTypes: [
                { value: 'private', text: this.$t('Приватний') },
                { value: 'public', text: this.$t('Відкритий') },
            ],
            listWorkers: []
        }
    },
    created(){

        if(this.editPerm){
            this.form = this.objCard
        }

        // if(this.objCard)

        storeS.workers.forEach( (el)=> {
            if(el.workerJob === 'PM' || el.workerJob == 'owner' || el.workerJob == 'ceo' || el.workerId == 34 || el.workerId == 57 || el.workerId == 54) {
                if(this.form.inJobWorkerId !== el.workerJob) {
                    this.listWorkers.push({
                        value: el.workerId,
                        label: el.workerName + " | "+ this.mutateWorkerJob(el.workerJob)
                    })
                }
            } //список работников
        })
    },
    methods: {
        add(){
            // Створення проекту

            if(!this.form.projectName){
                this.$toast.error(this.$t('projectNameNotFound'))
                return
            }

            apiServe.addProject(this.form).then(result => {
                if(result.status == 'done') {
                    console.log('result', result);
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                } else {
                    this.switchError(result.error[0])
                }
            })
        },
        switchError(error){
            // відображення помилки

            switch (error) {

                case "projectName not found":
                    this.$toast.error(this.$t('projectNameNotFound'))
                    break;
            
                default:
                    this.$toast.error(error);
                    break;
            }
        },
        updateWorkers(workerId, isChecked) {
            if (isChecked) {
                if (!this.form.workers.includes(workerId)) {
                    this.form.workers.push(workerId);
                }
            } else {
                this.form.workers = this.form.workers.filter(id => id !== workerId);
            }
        },
        transliterateToLatin(text) {
            // Словник для транслітерації
            
            const transliterationMap = {
                'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'H', 'Ґ': 'G', 'Д': 'D',
                'Е': 'E', 'Є': 'Ye', 'Ж': 'Zh', 'З': 'Z', 'И': 'Y', 'І': 'I',
                'Ї': 'Yi', 'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
                'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'У': 'U',
                'Ф': 'F', 'Х': 'Kh', 'Ц': 'Ts', 'Ч': 'Ch', 'Ш': 'Sh', 'Щ': 'Shch',
                'Ь': '', 'Ю': 'Yu', 'Я': 'Ya', 'а': 'a', 'б': 'b', 'в': 'v',
                'г': 'h', 'ґ': 'g', 'д': 'd', 'е': 'e', 'є': 'ye', 'ж': 'zh',
                'з': 'z', 'и': 'y', 'і': 'i', 'ї': 'yi', 'й': 'y', 'к': 'k',
                'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r',
                'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts',
                'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ь': '', 'ю': 'yu', 'я': 'ya'
            };

            // Транслітерація тексту
            let transliteratedText = text.split('').map(char => transliterationMap[char] || char).join('');

            // Замінюємо пробіли на нижні підкреслення, видаляємо небажані символи
            transliteratedText = transliteratedText
                .replace(/\s+/g, '_')    // Заміна пробілів на підкреслення
                .replace(/[^a-zA-Z0-9_]/g, '') // Видалення всіх символів, окрім латиниці, цифр і підкреслення
                .toLowerCase(); // Приведення до нижнього регістру

            this.form.projectKey = transliteratedText;
        },
        groupByJob(workers) {
            return workers.reduce((acc, worker) => {
                if (!acc[worker.workerJob]) {
                acc[worker.workerJob] = [];
                }
                acc[worker.workerJob].push(worker);
                return acc;
            }, {});
        },
        mutateWorkerJob(e){
            /*
                Функція для застосування перекладу до посади картки співробітника
            */

            return mutateWorkerJob(e)
        },
    },
    computed: {
        user() {
            // налаштування акаунту
            return storeS.userbase
        },
        checks(){
            // масив налаштувань акаунту
            return storeS.checks
        },
        perms(){
            // масив прав доступу
            return storeS.perms
        },
        workers(){
            // масив співробітників
            return storeS.workers
        },
        filteredGroupedWorkers() {
            // Фільтруємо працівників за введеним текстом
            const filteredWorkers = this.workers.filter(worker =>
                worker.workerName.toLowerCase().includes(this.search.toLowerCase())
            );

            // Групуємо фільтрованих працівників
            const grouped = this.groupByJob(filteredWorkers);

            // Сортуємо групи (розробники першими)
            const sortedKeys = Object.keys(grouped).sort((a, b) => {
                if (a === 'developer') return -1;
                if (b === 'developer') return 1;
                return 0;
            });

            const sortedGrouped = {};
            sortedKeys.forEach(key => {
                sortedGrouped[key] = grouped[key];
            });

            console.log("sort", sortedGrouped);
            

            return sortedGrouped;
        }
    }
}
</script>