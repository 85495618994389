<template>
    <!-- 
        Глобальний компонент фільтру
    -->

    <!-- Кнопка для відображення фільтру -->        
    <div class="d-flex flex-wrap gap-2">
        <button 
            class="btn btn-info" 
            @click="showFilter = true" 
            type="button" 
            data-bs-toggle="offcanvas" 
            :data-bs-target="`#offcanvasRight${dataParams.page + this.numberFilter}`" 
            :aria-controls="`offcanvasRight${dataParams.page + this.numberFilter}`"
        >
            <i class="ri-filter-3-line align-bottom me-1"></i>
        </button>
    </div>

    <!-- Фільтр висувається зправа -->
    <div :class="`offcanvas offcanvas-end ${showFilter ? 'show' : '' }`" :style="`visibility: ${showFilter ? 'visible' : 'hidden' };`" tabindex="-1" :id="`offcanvasRight${dataParams.page + this.numberFilter}`" :aria-labelledby="`offcanvasRightLabel${dataParams.page + this.numberFilter}`">
        <div class="offcanvas-header border-bottom">
            <h5 id="offcanvasRightLabel">{{ $t('filter') }}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" @close="showFilter = false" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">

            <!-- Вибрані фільтри -->
            <b>{{ $t('SelectedFilters') }} - </b>
            <div class="mb-3 mt-3">
                <template v-for="item in selectedFilter" :key="item">
                    <span class="badge bg-secondary fs-14 mb-1" style="margin-right: 5px;">{{ item.nameFilter }} | {{ item.value }} </span>
                </template>
            </div>

            <!-- Кнопка для відображення календаря -->
            <button 
                class="mainCalendarButton" 
                @click="this.showCalendar = !this.showCalendar"
                style="height: 40px;"
            >
                {{ this.date.title }}
            </button>

            <!-- Відображення компонента для вибору дати та часу -->
            <transition name="fade">
                <filterDateTime 
                    v-if="this.objParams && this.objParams.hasOwnProperty('pr1') && this.objParams.hasOwnProperty('pr2') && this.showCalendar == true" 
                    @setDate="changeFilter"
                    :type="'filter'" 
                    key="showCalendar"
                    :styleForSidebar="true"
                    @close="this.showCalendar = false"
                    :data="this.date"
                />
            </transition>
            
            <!-- Компонент вибору дати та часу, відображається, якщо в формі є поля 'date' і 'time' -->
            <filterDateTime2 v-if="this.form.hasOwnProperty('date') && this.form.hasOwnProperty('time')" @change="changeFilter" :key="this.componentKey"/> 

            <!-- Компонент вибору груп, відображається, якщо в формі є поле 'groupId' -->
            <filterGroupShortcuts v-if="this.form.hasOwnProperty('groupId')" @change="changeFilter" :key="this.componentKey"/>
            
            <!-- Компонент фільтрації за статусом, відображається для сторінки 'dumps' -->
            <template v-if="dataParams.page == 'dumps'">
                <filterStatus v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації архівних презентацій за статусом, відображається для сторінки 'presentationsArchive' -->
            <template v-if="dataParams.page == 'presentationsArchive'">
                <filterStatusArchivePresent v-if="this.form.hasOwnProperty('statusArchive')" @change="changeFilter" :key="this.componentKey"/>
            </template>
            
            <!-- Компонент фільтрації архівних проєктів за статусом, відображається для сторінки 'projectsArchive' -->
            <template v-if="dataParams.page == 'projectsArchive'">
                <FilterArchiveStatusProjects v-if="this.form.hasOwnProperty('statusArchive')" @change="changeFilter" :key="this.componentKey"/>
                <filterCateegory :user="this.user" v-if="this.form.hasOwnProperty('confStatus')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації історії облікового запису, відображається для сторінки 'history' -->
            <template v-if="dataParams.page == 'history'">
                <filterAccHistory v-if="this.form.hasOwnProperty('type')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації SMS відображається для сторінки 'sms' -->
            <template v-if="dataParams.page == 'sms'">
                <filterSMSStatus v-if="this.form.hasOwnProperty('sent')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації змін у системі, відображається для сторінки 'changelog' -->
            <template v-if="dataParams.page == 'changelog'">
                <filterUpdate v-if="this.form.hasOwnProperty('update')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації провайдерів за типом, відображається для сторінки 'providers' -->
            <template v-if="dataParams.page == 'providers'">
                <filterProvidersType v-if="this.form.hasOwnProperty('providerType')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації завдань за типом, статусом та архівним статусом, відображається для сторінки 'tasks' -->
            <template v-if="dataParams.page == 'tasks'">
                <filterTasksType v-if="this.form.hasOwnProperty('taskName')" @change="changeFilter" :key="this.componentKey"/>
                <filterTasksStatus v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
                <filterArchiveStatusTask v-if="this.form.hasOwnProperty('statusArchive')"  @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації проєктів за статусом, категорією, командою, керівником команди та архівним статусом, відображається для сторінки 'projects' -->
            <template v-if="dataParams.page == 'projects'">
                <filterStatusProjects v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
                <FilterArchiveStatusProjects v-if="this.form.hasOwnProperty('statusArchive')" @change="changeFilter" :key="this.componentKey"/>
                <filterTeam v-if="this.form.hasOwnProperty('confStatus')" @change="changeFilter" :key="this.componentKey"/>
                <filterCateegory :user="this.user" v-if="this.form.hasOwnProperty('confStatus')" @change="changeFilter" :key="this.componentKey"/>
                <!-- <filterMainTags v-if="this.form.hasOwnProperty('tags')" @change="changeFilter"/> -->
                <filterTeamLead v-if="this.form.hasOwnProperty('teamlead')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації дзвінків за статусом, архівним статусом та дією, відображається для сторінки 'calls' -->
            <template v-if="dataParams.page == 'calls'">
                <filterStatus v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
                <filterArchiveStatusApplic v-if="this.form.hasOwnProperty('statusArchive')" @change="changeFilter" :key="this.componentKey"/>
                <filtertAction v-if="this.form.hasOwnProperty('actionCall')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації архівних дзвінків за архівним статусом та дією, відображається для сторінки 'archiveCalls' -->
            <template v-if="dataParams.page == 'archiveCalls'">
                <filterArchiveStatusApplic v-if="this.form.hasOwnProperty('statusArchive')" @change="changeFilter" :key="this.componentKey"/>
                <filtertAction v-if="this.form.hasOwnProperty('actionCall')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації тікетів за типом, статусом, тегами, вирішеним статусом та середовищем проблеми, відображається для сторінки 'tickets' -->
            <template v-if="dataParams.page == 'tickets'">
                <filterTypeTickets v-if="this.form.hasOwnProperty('type')" @change="changeFilter" :key="this.componentKey"/>
                <filterStatusTickets v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
                <filterTimeGroups @change="changeFilter" :key="this.componentKey"/>
                <filterMainTags v-if="this.form.hasOwnProperty('tags')" :typeTags="this.dataParams.page" @change="changeFilter"/>
                <filterDecidedStatusTickets v-if="this.form.hasOwnProperty('decidedStatus')" @change="changeFilter" :key="this.componentKey"/>
                <filterProblemEnv v-if="this.form.hasOwnProperty('problemEnvironment')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації штрафів за статусом та працівником, відображається для сторінки 'fines' -->
            <template v-if="dataParams.page == 'fines'">
                <filterStatusFines v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
                <filterWorkerFines v-if="this.form.hasOwnProperty('finedWorkerId')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації рахунків за статусом, відображається для сторінки 'invoices' -->
            <template v-if="dataParams.page == 'invoices'">
                <filterStatusInvoice v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації рахунків за статусом, відображається для сторінки 'invoices' -->
            <template v-if="dataParams.page == 'holidays'">
                <filterHolidaysStatus v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації працівників за статусом та посадою, відображається для сторінки 'workers' -->
            <template v-if="dataParams.page == 'workers'">
                <filterWorkersStatus v-if="this.form.hasOwnProperty('workerStatus')" :worker="this.form.workerStatus" @change="changeFilter" :key="this.componentKey"/>
                <filterWorkersJob v-if="this.form.hasOwnProperty('workerJob')" :worker="this.form.workerJob" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації всіх облікових записів за країною, відображається для сторінки 'AllAccounts' -->
            <template v-if="dataParams.page == 'AllAccounts'">
                <filterCountryAcc v-if="this.form.hasOwnProperty('countryFilter')" @change="changeFilter" :key="this.componentKey"/>
            </template>
            
            <!-- Компонент фільтрації послуг за назвою та статусом, відображається для сторінки 'services' -->
            <template v-if="dataParams.page == 'services'">
                <filterTypeServices v-if="this.form.hasOwnProperty('nomenclatureName')" @change="changeFilter" :key="this.componentKey"/>
                <filterStatusServices v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації логів за користувачем, відображається для сторінки 'logs' -->
            <template v-if="dataParams.page == 'logs'">
                <filterWorkersAcc v-if="this.form.hasOwnProperty('userFilter')" @change="changeFilter" :key="this.componentKey"/>
            </template>
            
            <!-- Компонент фільтрації холодних дзвінків за типом, відображається для сторінки 'coldCalls' -->
            <template v-if="dataParams.page == 'coldCalls'">
                <filterColdStatus v-if="this.form.hasOwnProperty('status')" @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- Компонент фільтрації холодних дзвінків за типом, відображається для сторінки 'coldCalls' -->
            <template v-if="dataParams.page == 'monitoring'">
                <filterAssignUser @change="changeFilter" :key="this.componentKey"/>
            </template>

            <!-- <filterProviders /> -->

            <!-- Компонент фільтрації пріоритету -->
            <filterPriority v-if="this.form.hasOwnProperty('priority')" @change="changeFilter" :page="this.dataParams.page" :key="this.componentKey"/>

            <!-- Компонент фільтрації по тегам -->
            <filterMainTags v-if="this.form.hasOwnProperty('tags') && dataParams.page !== 'tickets'" :typeTags="this.dataParams.page" @change="changeFilter"/>

            <!-- Компонент фільтрації по співробітнику, відображається, якщо в формі є поля 'workerId' чи 'createWorkerId', крім сторінки 'shiftUser' -->
            <filterManager v-if="this.form.hasOwnProperty('workerId') && dataParams.page != 'shiftUser'" @change="changeFilter" :key="this.componentKey"/>
            <filterManager v-if="this.form.hasOwnProperty('createWorkerId')" @change="changeFilter" :key="this.componentKey"/>

            <!-- Компонент фільтрації за співробітником(хто взяв в роботу), відображається, якщо в формі є поле 'inJobWorkerId' -->
            <filterinJobWorkerTickets v-if="this.form.hasOwnProperty('inJobWorkerId')" @changeWithClearFilter="changeWithClearFilter" :workerId="this.objParams.inJobWorkerId" :key="this.componentKey"/>

            <!-- Компонент фільтрації категорії продуктів, відображається, якщо в формі є поле 'productsCategoryId' -->
            <filterCategoryProducts v-if="this.form.hasOwnProperty('productsCategoryId')" @change="changeFilter" :key="this.componentKey"/>

            <!-- Компонент фільтрації за постачальником, відображається, якщо в формі є поле 'provider' -->
            <filterProviderInvoice v-if="this.form.hasOwnProperty('provider')" @change="changeFilter" :key="this.componentKey"/>
        </div>
        <div class="offcanvas-foorter border p-3 text-center">
            <button type="button" class="btn btn-danger w-100" @click="clearFilter">
                {{ $t('clear_filter') }}
            </button>
        </div>
    </div>
</template>

<script>
import filterTimeGroups from './filters/tickets/timeGroups.vue'
import filterAssignUser from './filters/monitoring/assing.vue'
import filterSMSStatus from './filters/smsArchive/status.vue'
import {getMonth} from '@/usabilityScripts/globalMutate.js'
import filterStatusArchivePresent from './filters/presentArchive/status.vue'
import filterTeamLead from './filters/projects/teamlead'
import FilterArchiveStatusProjects from './filters/projects/statusArchiveProjects'
import filterArchiveStatusTask from './filters/tasks/statusArchiveTask'
import filterArchiveStatusApplic from './filters/statusArchiveApplic'
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import filterProviders from './filters/provider'
import filterProvidersType from './filters/providerType.vue'
import filterStatus from './filters/status'
import filterTasksStatus from './filters/tasks/status'
import filtertAction from './filters/action'
import filterTasksType from './filters/tasks/type'
import filterPriority from './filters/priority'
import filterManager from './filters/manager.vue'
import filterWorkersAcc from './filters/workers/workersAcc.vue'
import filterTypeTickets from './filters/tickets/type.vue'
import filterinJobWorkerTickets from './filters/tickets/inJobWorker'
import filterStatusTickets from './filters/tickets/status'
import filterStatusFines from './filters/fines/status'
import filterWorkerFines from './filters/fines/finedWorker'
// import filterDateTime from './filters/datetime.vue'
import filterDateTime from '../calendar.vue'
import filterDateTime2 from './filters/datetime2.vue'
import filterCategoryProducts from './filters/products/category.vue'
import filterProviderInvoice from './filters/invoice/provider'
import filterStatusInvoice from './filters/invoice/status'
import filterWorkersJob from './filters/workers/workerJob'
import filterWorkersStatus from './filters/workers/workerStatus'
import filterDecidedStatusTickets from './filters/tickets/decidedStatus'
import filterCountryAcc from './filters/accounts/country'
import { storeS } from '@/store.js'
import filterStatusProjects from './filters/projects/status'
import filterTeam from './filters/projects/team'
import filterCateegory from './filters/projects/category'
import filterUpdate from './filters/changelog/update'
import filterTypeServices from './filters/services/type'
import filterStatusServices from './filters/services/status'
import filterGroupShortcuts from './filters/shortcuts/group'
import filterAccHistory from './filters/history/type'
import filterProblemEnv from './filters/tickets/problemEnvironment'
import filterMainTags from './filters/tickets/tags'
import filterColdStatus from './filters/cold/type.vue'
import filterHolidaysStatus from "./filters/holidays/status.vue"

export default {
    props:['objParams', 'dataParams', 'user'],
    data() {
        return {
            componentKey: 0, // Унікальний ключ компонента для перезавантаження
            filter: 0, // Змінна для фільтрації
            form: "", // Змінна для зберігання форми
            showFilter: false, // Прапорець для відображення фільтрів
            numberFilter: '', // Номер фільтра
            selectedFilter: [], // Обрані фільтри
            showCalendar: false, // Прапорець для відображення календаря
            date: '', // Змінна для відображення дати
        }
    },
    created(){
        // Ініціалізація дати та інших змінних при створенні компонента

        // Отримуємо поточну дату
        this.date = {
        title: this.currentDate().day + ' ' + this.$t(`${this.currentDate().month}`) + ', ' +this.currentDate().year,
        value: this.currentDate().year + '-' + new Date().getMonth() + this.currentDate().day,
        month: this.currentDate().monthNum
        }

        this.form = this.objParams
        this.numberFilter = Math.floor(Math.random() * 10) + 1

    },
    components: {
        filterTimeGroups,
        filterAssignUser,
        filterSMSStatus,
        filterStatusArchivePresent,
        filterTeamLead,
        FilterArchiveStatusProjects,
        filterArchiveStatusTask,
        filterArchiveStatusApplic,
        Multiselect,
        filtertAction,
        filterProviders,
        filterProvidersType,
        filterStatus,
        filterPriority,
        filterManager,
        filterWorkersAcc,
        filterTypeTickets,
        filterDateTime, 
        filterDateTime2,
        filterCategoryProducts,
        filterProviderInvoice,
        filterTasksStatus,
        filterTasksType,
        filterinJobWorkerTickets,
        filterStatusTickets,
        filterStatusFines,
        filterStatusInvoice,
        filterWorkersJob,
        filterDecidedStatusTickets,
        filterCountryAcc,
        filterStatusProjects,
        filterTeam,
        filterCateegory,
        filterUpdate,
        filterWorkerFines,
        filterTypeServices,
        filterStatusServices,
        filterWorkersStatus,
        filterGroupShortcuts,
        filterAccHistory,
        filterProblemEnv,
        filterMainTags,
        filterColdStatus,
        filterHolidaysStatus
    },
    methods: {
        currentDate() {
            // Отримання поточної дати

            var date = new Date();
            var dateStr = {
                day: ("00" + (date.getDate())).slice(-2),
                month: (this.month(date.getMonth() + 1)),
                year: date.getFullYear(),
                monthNum: date.getMonth()
            }
            return dateStr
        },
        month(e) {
            // Перетворення номера місяця у його назву
            return getMonth(e)
        },
        changeWithClearFilter(nameFilter, filterChange) {
            /**
                * Зміна фільтру
                *
                * @param {string} nameFilter - Назва фільтру, що змінюється
                * @param {string|string[]} filterChange - Нове значення фільтру або діапазон дат (у вигляді масиву)
            */

            this.$emit('changeWithClearFilter', nameFilter, filterChange)
        },
        changeFilter(nameFilter, filterChange){
            /**
                * Зміна фільтру
                *
                * @param {string} nameFilter - Назва фільтру, що змінюється
                * @param {string|string[]} filterChange - Нове значення фільтру або діапазон дат (у вигляді масиву)
            */
            
            // Зміна дати в залежності від фільтру 'pr' (діапазон дат)
            if(nameFilter == 'pr') {
                if(filterChange[0] === '') {
                    return
                }
                else if(filterChange[0] == filterChange[1]) {
                    this.date = {
                        title: filterChange[0].split('-')[2] + ' ' + this.$t(this.month(filterChange[0].split('-')[1])) + ' ' + filterChange[0].split('-')[0],
                        value: filterChange[0].split('-')[2] + ' ' + filterChange[0].split('-')[1] + ' ' + filterChange[0].split('-')[0]
                    }
                } else {
                    this.date = {
                        title: filterChange[0].split('-')[2] + ' ' + this.$t(this.month(filterChange[0].split('-')[1])) + ' ' + filterChange[0].split('-')[0] + ' - ' + filterChange[1].split('-')[2] + ' ' + this.$t(this.month(filterChange[1].split('-')[1])) + ' ' + filterChange[1].split('-')[0],
                        value: filterChange[0].split('-')[2] + ' ' + filterChange[0].split('-')[1] + ' ' + filterChange[0].split('-')[0] + ' - ' + filterChange[1].split('-')[2] + ' ' + filterChange[1].split('-')[1] + ' ' + filterChange[1].split('-')[0]
                    }
                }

                // Еміт події для повідомлення зовнішніх компонентів про зміни в фільтрах
                this.$emit('changeFilter', nameFilter, filterChange)
                return
            }

            // Логіка зміни інших типів фільтрів
            if(filterChange == undefined && nameFilter !== 'all') {
                return
            }

            if(nameFilter && filterChange != undefined){
                // Оновлення або додавання нового фільтру до списку вибраних
                if(this.selectedFilter.find(el => el.nameFilter == nameFilter)) {
                    if(filterChange !== '') {
                        (this.selectedFilter.find(el => el.nameFilter == nameFilter)).value = filterChange
                    } else {
                        this.selectedFilter.splice(this.selectedFilter.indexOf(this.selectedFilter.find(el => el.nameFilter == nameFilter)), 1)
                    }
                } else {
                    this.selectedFilter.push(
                        {
                            nameFilter: nameFilter,
                            value: filterChange,
                        }
                    )
                }
                // Збереження вибраних фільтрів
                this.saveFilters();
            }

            // Еміт події для повідомлення зовнішніх компонентів про зміни в фільтрах
            this.$emit('changeFilter', nameFilter, filterChange)
        },
        saveFilters(){
            // збереження фільтрів в локалстор
            // let parsed = JSON.stringify(this.selectedFilter);
            // localStorage.setItem('filters '+this.dataParams.page, parsed);
        },
        clearFilter(){
            // Очищення фільтрів

            // Очищення вибраних фільтрів
            this.selectedFilter = []

            // Вибросити подію для сповіщення зовнішніх компонентів про зміни в фільтрах
            let nameFilter = 'all'
            this.$emit('changeFilter', nameFilter)

            // Збільшення ключа компонента для перезавантаження
            this.componentKey++
        },
    },
    mounted(){
        // Cлухання події refreshFilters для оновлення даних після змін .
        this.eventBus.off('refreshFilters')
        this.eventBus.on('refreshFilters', status => {
            this.componentKey++
        })
        // if(localStorage.getItem('filters '+this.dataParams.page)) {
        //     try {
        //         this.selectedFilter = JSON.parse(localStorage.getItem('filters '+this.dataParams.page));
        //         this.selectedFilter.forEach(el => {
        //             console.log(this.dataParams.page + ' filters')
        //             switch(this.dataParams.page) {
        //                 case 'calls':
        //                     this.eventBus.emit('calls filters', {
        //                         status: true,
        //                         form: el,
        //                     })
        //                 break;
        //                 // case 'tickets':
        //                 // case 'presentations'
        //                 // case 'tasks'
        //                 // case 'projects'
        //                 // case 'products'
        //                 // case 'invoices'
        //                 // case 'shortcuts'
        //                 case 'calendar':
        //                     this.eventBus.emit(this.dataParams.page + ' filters', {
        //                         status: true,
        //                         form: el,
        //                     })
        //                 break;
        //                 // case 'changelog'
        //             }
        //         })
        //     } catch(e) {
        //         // localStorage.removeItem('tickets');
        //     }
        // // }
        // }
    },
    computed: {
        user(){
            /*
                Повертає дані користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>

<style scoped>
.list_filtr {
    max-height: 300px;
    overflow: scroll;
}
[data-layout-mode="dark"] .mainCalendarButton {
    background-color: #1A1D1E !important;
    color: white
}
</style>