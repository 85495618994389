<!--
    Список всіх активних і завершених тестувань
    Фільтри та пошук: Фільтрація за статусом, датою, відповідальним, типом тестування тощо.
    Кнопка "Додати нове тестування": Для створення нових тестових сесій.
-->

<template>

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />
    

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
        <button @click="infoblockShow = !infoblockShow" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>

    <!-- 
        Інфо-блоки 
        Є перевірка:
        * Якщо існують і прописані інфоблоки
    -->
    <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога)
        - @create - подія, яка спрацьовує при створенні нового завдання на тестування
        - @open - подія, яка спрацьовує при відкритті картки завдання на тестування
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms[2000]" 
        @create="showCreatedBox = true"
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
        :addtabs="true"
        @addCategory="showFilterBox = true"
        :tabs="tableTabs"
        :verTabs="'new'"
        @editTab="editTab"
        @removeTab="removeTab"
    />

    <!-- 
        Вікно створення картки завдання
        - для відкриття застосовується перевірка значення showCreatedBox
        - @close - подія, яка спрацьовує при закритті картки
    -->
    <createBox 
        v-if="showCreatedBox"
        @close="showCreatedBox = false;this.getdata()"
    />

    <!-- 
        Перегляд картки завдання тестування
    -->
    <viewBox 
        v-if="showViewBox.show == true"
        @close="showViewBox.show = false;this.getdata()"
        :objCard="this.showViewBox.obj"
    />

    <!--
      Модалка створення табів
        @close - дія закриття табів
        @changeTabs - дія зміни табу
        :othertabs - пропс табів
    -->
    <filterTabs 
        v-if="showFilterBox" 
        @close="showFilterBox = false"
        @saveTab="saveTab"
        :editPermFilter="editPermFilter"
        :objEditTab="objEditTab"
    />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import infoblocks from '@/components/info/blocks'
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import createBox from './created_testing/index' // картка створення завдання qa
import viewBox from './view_testing/index'; // картка завдання qa
import filterTabs from './filterTabs.vue';
import { Testing } from '@/API.js';
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { 
        mutateStatusTicket,
        mutatePriorityTicket
      } from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Testing();

export default {
    components: {
        PageHeader,
        infoblocks,
        tablecustom,
        createBox,
        viewBox,
        filterTabs
    },
    data() {
        return {
            title: this.$t('Testing'), // Заголовок
            showCreatedBox: false, // прапорець відображення вікна створення
            infoblockShow: false, // прапорець відображення інфоблоків
            ifNextopen: "",
            ifPrevopen: "",
            showViewBox: {
                show: false, // прапорець відображення вікна картки
                obj: '' // дані
            },
            dataParams: {
                status: true,
                page: "testing"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                priority: '',
                status: '',
                type: '',
                workerId: '',
                inJobWorkerId: '',
                problemEnvironment: '',
                pr1: '',
                pr2: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            columns: [
                {
                    name: "ID",
                    text: "testingId",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('project'),
                    text: "problemEnvironment",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('Point_name'),
                    text: "name",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('priority'),
                    text: "priority",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color
                },
                {
                    name: this.$t('added'),
                    text: "createWorkerId",
                    align: "left",
                    status: true,
                    mutate: (item) => this.nameWorker(item)
                },
                {
                    name: this.$t('Status'),
                    text: "status",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color
                },
                {
                    name: this.$t("createDate"),
                    text: "createDatetime",
                    align: "left",
                    status: true
                }
            ],
            rows: [
                
            ],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('new'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('statusInWork'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "danger"
                },
                {
                    title: this.$t('registrationCompleted'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                }
            ],
            showFilterBox: false,
            objEditTab: {},
            editPermFilter: false,
            tableTabs: [],
            showDialogRemove: false, // прапорець відображення вікна видалення табу
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(page){
            // отримання карток тестування
            this.objParams.page = page != undefined ? page : '1';

            apiServe.getTestingList(this.objParams).then(result => {
                if(result.status == 'done') {

                    this.objPages = result.data;
                    this.rows = result.data.items;
                    
                    // заповнення інфоблоків
                    this.information[0].value = result.data.total;
                    this.information[1].value = result.data.countNew;
                    this.information[2].value = result.data.countInJob;
                    this.information[3].value = result.data.countDecided;
                    // заповнення інфоблоків

                } else {
                    this.$toast.error(this.$t('error') + result.error[0]);
                }
            })
        },
        open(e){
            // Відкриття картки

            var id = e.testingId ? e.testingId : e; // перевірка вхідного параметру
            this.ifPrevopen = id == this.rows[0].testingId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].testingId ? false : true;

            apiServe.getTesting(id).then(result => {
                if(result.status == "done") {
                    
                    this.showViewBox.obj = result.data;
                    this.showViewBox.show = true;

                } else {

                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка відкриття картки тестування'; 
                    var statusRequest = result.status;
                    var api = 'getTicket';
                    var fileRequest = 'src/views/software-testing/index.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })

        },
        searchB(e){
            // пошук
            this.objParams.search = e
            this.getdata()
        },
        nameWorker(item){
            // мутейт співробітника
            return nameWorker(item)
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        mutatestatus(e){
            // мутейт статусу
            return mutateStatusTicket(e)
        },
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    },
}

</script>