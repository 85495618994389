<!-- 
    Завдання по проєктам

-->

<template>

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <div class="p-3 bg-white rounded mb-4">
        <b-row class="g-2">
            <b-col lg="auto">
                <select 
                    class="form-control" 
                    data-choices data-choices-search-false
                    name="choices-select-status" 
                    id="choices-select-status" 
                    v-model="status"
                >
                    <option value="All Tasks">Всі завдання</option>
                    <option value="Completed">Завершені</option>
                    <option value="Inprogress">В роботі</option>
                    <option value="Pending">Тестуються</option>
                    <option value="New">До виконання</option>
                </select>
            </b-col>
            <b-col>
                <div class="search-box">
                    <input 
                        type="text" 
                        id="searchTaskList" 
                        class="form-control search"
                        placeholder="Пошук по назві завдання" 
                        v-model="searchQuery"
                    >
                    <i class="ri-search-line search-icon"></i>
                </div>
            </b-col>
            <b-col lg="auto">
                <b-button 
                    variant="secondary" 
                    class="createTask" 
                > 
                    <i class="ri-add-fill align-bottom"></i> Додати завдання
                </b-button>
            </b-col>
        </b-row>
    </div>

    <b-card>
        
        <div style="display: flex;">

            <!-- Таби по таблиці -->
            <ul 
                :class="`nav nav-tabs nav-tabs-custom`" 
                role="tablist" 
                style="width: 100%; overflow: auto!important; margin: 0px;padding: 2px"
            >
                <!-- Таб "Всі" -->
                <li class="nav-item" >
                    <a 
                        class="nav-link All py-2 fs-14 active" 
                        data-bs-toggle="tab" 
                        id="All" 
                        href="#home1" 
                        role="tab"
                        @click="changeFilter('all')" 
                    >
                        <b>{{$t('all')}}</b>
                    </a>
                </li>

                <!-- Таб "Мої" -->
                <li class="nav-item" >
                    <a 
                        class="nav-link my py-2 fs-14 text-white" 
                        data-bs-toggle="tab" 
                        id="my" 
                        href="#home2" 
                        role="tab"
                        style="background-color:rgb(10 179 156)"
                        @click="changeFilter('my')" 
                    >
                        <b>{{$t('My')}}</b>
                    </a>
                </li>

            </ul>

        </div>

        <div class="todo-task bg-white" id="todo-task">
            <div class="table-responsive">
                <table class="table align-middle position-relative table-nowrap">
                    <thead class="table-light text-muted">
                        <tr>
                            <th scope="col">Завдання</th>
                            <th scope="col">Виконує</th>
                            <th scope="col">Термін виконання</th>
                            <th scope="col">Проєкт</th>
                            <th scope="col">Статус</th>
                            <th scope="col">Пріоритет</th>
                            <th scope="col">Дія</th>
                        </tr>
                    </thead>

                    <!-- 
                        Draggable компонент для реалізації функціоналу перетягування рядків у таблиці 
                        - Прив'язує список завдань (rows) до draggable компонента
                    -->
                    <draggable
                        v-model="rows" 
                        group="columns" 
                        tag="tbody"
                        :class="''"
                        @change="onColumnChange" 
                    >

                        <tr v-for="(item, index) of this.rows" :key="index">

                            <!-- Перша колонка: Деталі завдання з чекбоксом для статусу -->
                            <td>
                                <div class="d-flex align-items-start">

                                    <!-- Елемент для перетягування -->
                                    <div class="flex-shrink-0 me-3">
                                        <div class="task-handle px-1 bg-light rounded">: :</div>
                                    </div>

                                    <div class="flex-grow-1">
                                        <div class="form-check">

                                            <!-- Чекбокс для позначення виконаних завдань -->
                                            <input 
                                                v-if="item.checkedElem"
                                                class="form-check-input" 
                                                type="checkbox"
                                                :value="item.taskId" 
                                                :id="item.taskId" 
                                                checked
                                                @click="(e) => todoListStatus(item.taskId)"
                                            >

                                            <!-- Чекбокс для невиконаних завдань -->
                                            <input 
                                                v-if="!item.checkedElem"
                                                class="form-check-input"
                                                type="checkbox"
                                                :value="item.taskId" 
                                                :id="item.taskId"
                                                @click="(e) => todoListStatus(item.taskId)"
                                            >

                                            <label 
                                                class="form-check-label ms-1" 
                                                :for="item.taskId"
                                            >
                                                {{ item.taskName }}
                                            </label>

                                        </div>
                                    </div>
                                </div>
                            </td>

                            <!-- Друга колонка: Хто виконує завдання -->
                            <td>
                                <div class="d-flex align-items-center gap-2">
                                    <div class="avatar-xs">
                                        <div class="avatar-title fs-12 rounded-circle " 
                                            :style="`background-color: ${this.getColorByWorkerId(item.performerWorkerId)}`"
                                        >
                                            <template v-if="this.getImgWorker(item.performerWorkerId)">
                                                <img
                                                    :src="this.getImgWorker(item.performerWorkerId)" 
                                                    alt="${performerWorkerName}" 
                                                    class="avatar-xs rounded-circle"
                                                >
                                            </template>
                                            <template v-else>
                                                <span>{{ item.performerWorkerName != null ? this.getInitials(item.performerWorkerName) : this.getInitials(this.$t('NotDefined'))}}</span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h5 class="fs-13 mb-0">
                                            <a class="text-body d-block">{{ item.performerWorkerName != null ? item.performerWorkerName : this.$t('NotDefined') }}</a>
                                        </h5>
                                    </div>
                                </div>
                            </td>
                            
                            <!-- Третя колонка: Дедлайн -->
                            <td> {{ item.deadLineDate }} </td>

                            <!-- Четверта колонка: проєкт -->
                            <td> {{ item.project }} </td>

                            <!-- П’ята колонка: Статус завдання -->
                            <td 
                            
                            >
                                <b-badge 
                                    variant="soft-warning"
                                    class="badge-soft-warning text-uppercase"
                                >
                                    {{ item.status }}
                                </b-badge> 
                            </td>

                            <!-- Шоста колонка: Пріоритет завдання -->
                            <td 
                            >
                                <b-badge 
                                    variant="danger"
                                    class="text-uppercase"
                                >
                                    {{ item.priority }}
                                </b-badge>
                            </td>
                            
                            <!-- Дії з завданням -->
                            <td>
                                <div class="hstack gap-2">

                                    <!-- <b-button 
                                        variant="primary" 
                                        size="sm" 
                                        class="edit-list"
                                        data-edit-id={{item.id}}
                                        @click="editdata(item)"
                                    >
                                        <i class="ri-pencil-fill align-bottom"></i>
                                    </b-button> -->

                                    <b-button 
                                        variant="soft-info" 
                                        size="sm" 
                                        class="view-list"
                                        data-remove-id={{item.id}} 
                                        :title="$t('open')"
                                        @click="open(item)"
                                    >
                                        <i class="ri-eye-line align-bottom"></i>
                                    </b-button>

                                </div>

                            </td>

                        </tr>
                    </draggable>
                </table>
            </div>
        </div>
    </b-card>

    <!-- Перегляд картки завдання -->
    <viewTask 
        v-if="showTask"
        @close="showTask = false;getdata()"
        :objCard="this.form"
        :columnArr="columnArr"
        :projectId="objCard.projectId"
    />
   
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewTask from '../projects/project/tabs/board/viewTask.vue' // Перегляд картки завдання
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new developmentProjects();

export default {
    components: {
        PageHeader,
        tablecustom,
        draggable: VueDraggableNext,
        viewTask
    },
    data() {
        return {
            title: this.$t('Todo'),
            showCreateBox: false,
            form: "",
            showTask: false, // блок перегляду таски
            dataParams: {
                status: true,
                page: "devProjects"
            },
            objPages: {
                page: 1,
                show: 5,
                total: 5,
                links: [
                    {
                        "page": 1,
                        "current": "true"
                    }
                ]
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                priority: '',
                status: '',
                type: '',
                workerId: '',
                inJobWorkerId: '',
                problemEnvironment: '',
                pr1: '',
                pr2: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            columns: [
                {
                    name: this.$t('Завдання'),
                    text: "taskName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('Виконує'),
                    text: "workers",
                    align: "left",
                    status: true,
                    mutate: (item) => item[0].workerName
                },
                {
                    name: this.$t('Термін виконання'),
                    text: "deadLineDate",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('Статус'),
                    text: "status",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('Пріоритет'),
                    text: "priority",
                    align: "left",
                    status: true
                }
            ],
            rows: [],
            colorsNew: [
                { color: '#1ABC9C', title: '1ABC9C' },
                { color: '#2ECC71', title: '2ECC71' },
                { color: '#3498DB', title: '3498DB' },
                { color: '#9B59B6', title: '9B59B6' },
                { color: '#34495E', title: '34495E' },
                { color: '#F1C40F', title: 'F1C40F' },
                { color: '#F39C12', title: 'F39C12' },
                { color: '#E74C3C', title: 'E74C3C' },
                { color: '#C2185B', title: 'C2185B' },
                { color: '#BDC3C7', title: 'BDC3C7' },
                // { color: '#95A5A6', title: '95A5A6' },
                { color: '#607D8B', title: '607D8B' },
            ],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.rows = [
                {
                    taskId: 12,
                    taskName: "Додати розділ Керування замовленнями",
                    performerWorkerId: null,
                    performerWorkerName: null,
                    deadLineDate: '1996-05-20',
                    status: 'До виконання',
                    priority: 'Високий',
                    project: 'Inside',
                    checkedElem: false
                },
                {
                    taskId: 15,
                    taskName: "Додати розділ методи оплат",
                    performerWorkerId: 33,
                    performerWorkerName: "Борис",
                    deadLineDate: '1996-05-20',
                    status: 'До виконання',
                    priority: 'Високий',
                    project: 'Dashboard',
                    checkedElem: true
                    
                }
            ]
        },
        open(item){
            // відкриття таски
            console.log('item', item);
            

            apiServe.getTask(item.taskId).then(result => {
                if(result.status == 'done'){
                    console.log("result", result);
                    this.form = result.data
                    // this.columnArr = this.transformColumns(this.columns);
                    this.showTask = true
                } else {
                    this.$toast.error(result.error[0])
                }
            })
        },
        transformColumns(columns){
            return columns.map(column => ({
                label: column.columnName,  // Назва колонки
                value: column.columnId,    // ID колонки
                color: column.color        // Колір
            }));
        },
        todoListStatus(e) {
            var todo = this.rows;
            console.log("e", e);
            
            todo.filter(function (todoTask) {
                if (todoTask.taskId == e) {
                    if (todoTask.status == 'Тестінг') {
                        todoTask.status = 'В роботі';
                    } else {
                        todoTask.status = 'Тестінг';
                    }
                }
            });
        },
        getImgWorker(workerId){
            // отримання зображення співробітника
            var avatar = ""
            this.workers.forEach(element => {
                if(element.workerId == workerId){
                    avatar = element.avatar;
                }
            });
            return avatar
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        getColorByWorkerId(workerId) {

            if(workerId) {
                const numericValue = workerId;
                const index = numericValue % this.colorsNew.length;
                return this.colorsNew[index].color;
            } else {
                return "#95A5A6"
            }
        },
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        workers(){
            // отримуємо список співробітників
            return storeS.workers
        },
    }
}
</script>

<style scoped>
.todo-task table .form-check .form-check-input:checked+.form-check-label {
    text-decoration: line-through;
}
</style>